.block {

    &--features {
        margin: 0;
        padding: 0;
        display: flex;
        flex: 1 1 100%;
        flex-wrap: wrap;
        height: auto !important;
        //justify-content: space-between;
        //flex-direction: column;
        -webkit-box-direction: normal;
        -webkit-box-orient: vertical;
        justify-content: space-around;


        @media screen and (max-width: 1400px) {
            align-items: flex-start;
            margin: 90px auto 30px;
            justify-content: space-evenly;
            flex-direction: row;
            height: auto !important;
        }


        @include media-breakpoint-down(lg) {
            flex-direction: row;
            justify-content: space-between;
            height: 100% !important;
            margin-top: rem(50px);
            padding-bottom: rem(30px);
            align-items: flex-start;

            max-width: 800px;
            margin: 90px auto 30px;
            justify-content: space-evenly;
        }


        &_item {
            list-style: none;
            text-align: center;
            max-width: rem(270px);
            border: 1px solid #ebebeb;
            border-radius: rem(6px);
            padding: rem(20px 20px 0);
            margin-bottom: rem(70px);
            transition: box-shadow .3s, border-color .2s, transform .3s, background .3s;

            @include media-breakpoint-down(lg) {
                margin-bottom: 60px;
                max-width: rem(380px);
            }

            @include media-breakpoint-down(md) {
                max-width: rem(340px);
                max-width: calc(50% - 20px);
            }
            
            @include media-breakpoint-down(xs) {
                max-width: 100%;
            }


            &:hover {
                background: #fff;
                border-color: #fff;
                box-shadow: 0 5px 30px rgba(0, 0, 0, 0.15);
                cursor: default;
            }

            /*&:nth-child(2) {
                margin-bottom: auto;

                @include media-breakpoint-down(md) {
                    margin-bottom: rem(70px);
                }
            }

            &:nth-child(3) {
                margin-top: rem(60px);

                @media screen and (max-width: 1390px) {
                    margin-top: 0;
                }
            }*/

        }


        &_icon {
            display: flex;
            justify-content: center;
            width: rem(93px);
            height: rem(93px);
            background: $brand-primary;
            border-radius: 60%;
            margin: -50px auto 25px;

            img {
                align-self: center;
                max-width: rem(55px);
            }
        }

    } //block--features




    &--services {
        margin: rem(20px 0 30px);
        padding: 0;
        display: flex;
        flex: 1 1 100%;
        flex-wrap: wrap;
        justify-content: flex-start;

        @include media-breakpoint-down(lg) {
            justify-content: space-between;
            margin: 0 auto;
            max-width: 800px;
        }


        &_item {
            list-style: none;
            text-align: center;
            width: 100%;
            max-width: rem(380px);
            border: 1px solid #ebebeb;
            border-radius: rem(6px);
            padding: rem(46px 25px 25px);
            //margin-bottom: rem(25px);
            transition: box-shadow .3s, border-color .2s, transform .3s, background .3s;
            cursor: default !important;
            margin: rem(0 25px 25px 0);

            @media screen and (max-width: 1400px) {
                max-width: 373px;
            }

            @include media-breakpoint-down(md) {
                max-width: calc(50% - 15px);
                margin: rem(0 0 25px 0);
            }

            @include media-breakpoint-down(xs) {
                max-width: calc(50% - 5px);
                max-width: 100%;
                padding: rem(30px 10px 15px);
                margin-bottom: rem(10px);
            }


            &:hover {
                background: #fff;
                border-color: #fff;
                box-shadow: 0 5px 30px rgba(0, 0, 0, 0.15);
                cursor: pointer;
            }

            &:nth-child(3),
            &:nth-child(6),
            &:nth-child(9),
            &:nth-child(12),
            &:nth-child(15),
            &:last-child {
                margin-right: 0;

                @include media-breakpoint-down(lg) {
                    margin-right: 25px;
                }

                @include media-breakpoint-down(md) {
                    margin-right: 0;
                }
            }


            a {

                &:hover,
                &:focus {
                    color: initial;
                    text-decoration: none;
                }
            }

        }


        h3 {
            color: $brand-primary;
            letter-spacing: rem(.5px);
            text-shadow: 0 0 0 $brand-primary;
        }

        img {
            display: flex;
            justify-content: center;
            max-width: rem(220px);
            height: auto;
            margin: 0 auto 25px;

            @include media-breakpoint-down(sm) {
                max-width: 90%;
            }
        }

    } //block--services


    &--documents {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;

        @include media-breakpoint-down(xs) {
            justify-content: space-evenly;
        }

        li {
            flex-direction: column;
            display: flex;
            margin: 0 50px 15px 0;

            @include media-breakpoint-down(md) {
                margin: 0 30px 15px 0;
            }

            @include media-breakpoint-down(xs) {
                margin: 0;
            }


            img {
                display: block;
                box-shadow: 0 5px 35px rgba(0, 0, 0, 0.2);
                margin-bottom: rem(20px);
                transition: box-shadow .25s, transform .3s;
                max-width: rem(170px);

                @include media-breakpoint-down(xs) {
                    max-width: rem(140px);
                    margin-bottom: rem(15px);
                }
            }

        }

    }


    &--news {
        background: #fff;
        border-radius: rem(5px);
        max-width: rem(370px);
        display: block;
        transition: box-shadow .3s;

        &:hover,
        &:focus {
            text-decoration: none;
            box-shadow: 0 5px 30px rgba(0, 0, 0, 0.15);

            .link--arrow {
            
                &:before {
                    background: $brand-secondary;
                }
        
                &:after {
                    color: $brand-secondary;
                }
            }
        }

        &_content {
            padding: rem(35px 30px);
            border: 1px solid #eee;

            h3 {
                color: #011a38;
                letter-spacing: 0;
            }

            p {
                line-height: rem(27px);
            }
        }

        time {
            font-size: rem(14px);
            color: #ccc;
            display: block;
            position: relative;
            margin-bottom: rem(15px);

            &:before {
                font-family: 'icomoon';
                content: "\e900";
                font-size: rem(25px);
                color: #ccc;
                font-weight: 300;
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                transform: translate(-3px,4px);
                margin-right: rem(6px);
                transition: color .1s,transform .4s;
                position: relative;
            }
        }
    }


    &--card {
        padding: rem(10px);
        background: #fff;
        border-radius: rem(5px);
        border: 1px solid #eee;
        display: block;
        transition: box-shadow .3s, border .2s;

        &:hover,
        &:focus {
            text-decoration: none;
            border: 1px solid #c9c9c9;
        }

        &_content {
            padding: rem(35px 30px 10px);
        }

    }


    &--review {
        display: flex;
        position: relative;
        border: 1px solid #ebebeb;
        border-radius: rem(6px);
        padding: rem(70px 120px 50px 35px);

        @include media-breakpoint-down(md) {
            padding: rem(50px 50px 50px 35px);
        }

        @include media-breakpoint-down(xs) {
            flex-direction: column;
            padding: rem(30px 25px 50px 25px);
        }

        

        &_customerdetails {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            max-width: rem(180px);
            margin-right: rem(30px);
            font-size: rem(14px);

            //for longer names
            max-width: rem(180px);
            margin-right: rem(70px);
            min-width: rem(180px);

            @include media-breakpoint-down(xs) {
                margin: rem(0 0 30px);
                max-width: 100%;
            }

            strong {
                font-size: rem(16px);
                margin-bottom: rem(8px);
                max-width: rem(180px);

                @include media-breakpoint-down(xs) {
                    max-width: 100%;
                }
            }

        }

        &_img {
            display: block;
            border-radius: 60%;
            overflow: hidden;
            margin-bottom: rem(20px);
        }

        &_content {
            
            &:before {
                font-family: 'icomoon';
                content: "\e905";
                position: absolute;
                font-size: 50px;
                color: #ebebeb;
                transform: translate(-55px, -33px);

                @include media-breakpoint-down(sm) {
                    font-size: 90px;
                    color: #f6f6f6;
                    transform: translate(-15px, -50px);
                    z-index: -1;
                }
            }
            
            strong {
                color: #000;
                display: block;
                margin-bottom: rem(5px);
            }
        }

    }


    &--narrow {
        background: #f6f6f6;
        padding: rem(30px 0);
        text-align: center;
        width: 100%;

        // for section ordering
        order: 95;
    }

}


////////////////////////
///////   NEWS   ///////
////////////////////////

// Big News Cards
.news-card {
    background: #fff;
    box-shadow: 0px 0px 30px #e7e8f0;
    display: block;
    position: relative;
    max-width: 520px;
    margin-bottom: 40px;
    transition: box-shadow .2s;

    display: none;

    @media screen and (max-width: 1600px) {
        max-width: calc(33% - 20px);
    }

    @include media-breakpoint-down(xl) {
        max-width: calc(50% - 20px);
    }

    @include media-breakpoint-down(sm) {
        max-width: 100%;
    }


    &:hover,
    &:focus {
        box-shadow: 0px 0px 30px #dedfea;

        .h4, .h5 {
            color: $brand-secondary;
        }

        img {
            opacity: .93;
        }
    }

    &_wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        align-items: stretch;

        &:after {

            @include media-breakpoint-up(lg) {
                content: "";
                width: 520px;
            }

            @include media-breakpoint-up(md) {
                width: 470px;
            }
        }
    }

    &_block-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;

        .news-card {
            display: block;
            max-width: 670px;
            margin-right: 60px;

            @media screen and (max-width: 1600px) {
                max-width: 600px;
            }

            @include media-breakpoint-down(lg) {
                margin-right: 0;
            }

            picture,
            img {
                width: 100%;
                height: auto;

                @include media-breakpoint-up(sm) {
                    height: 320px;
                }
            }

            .meta-footer {
                margin-top: 55px;
            }
        }
    }


    picture {
        width: 520px;
        height: 320px;
        display: block;

        @media screen and (max-width: 1600px) {
            width: 100%;
            height: auto;
        }

        img {
            width: 520px;
            height: 320px;
            object-fit: cover;
            transition: opacity .3s;

            @media screen and (max-width: 1600px) {
                width: 100%;
                height: auto;
            }

            @include media-breakpoint-down(sm) {
                width: 100%;
                height: 180px;
            }
        }
    }
    
    &_content {
        padding: 41px 30px 27px;
        height: calc(100% - 320px);
        display: flex;
        flex-direction: column;

        @include media-breakpoint-down(sm) {
            padding: 30px 25px 18px;
        }

        h3 {
            margin-bottom: rem(18px);

            @include media-breakpoint-down(sm) {
                font-size: 21px;
                line-height: 28px;
            }
        }

        p {
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 28px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;

            @media screen and (max-width: 1600px) {
                overflow: visible;
                -webkit-box-orient: unset;
            }

            @include media-breakpoint-down(sm) {
                font-size: 17px;
                line-height: 27px;
                margin-bottom: 25px;
            }
        }

        .meta-footer {
            display: block;
            color: $brand-primary;
            font-size: 18px;
            text-shadow: 0 0 $brand-primary;
            width: 100%;
            border-top: 2px solid #f3f3f7;
            padding: 15px 0 0;
            margin-top: auto;
            position: relative;
            
            @include media-breakpoint-down(sm) {
                font-size: 17px;
            }

            &:after {
                font-family: 'buw';
                content: "\e900";
                font-size: 13px;
                font-weight: 500;
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                transition: transform .25s;
                position: absolute;
                bottom: 4px;
                right: 1px;
                color: $brand-secondary;
                
                @include media-breakpoint-down(xl) {
                    content: initial;
                }
            }

            .divider {
                display: inline;
                margin: 0 6px;
                position: relative;
                top: -1px;
            }

            .category-item {

                &:last-of-type:after {
                    content: initial;
                }
    
                &:after {
                    content: ", ";
                    position: relative;
                }
            }

        } //meta-footer

    }
}

// News Card Slider
.news-slider {
    max-width: 1415px;
    margin: 0 auto 50px;
    //transform: translateX(20px);

    @include media-breakpoint-down(lg) {
        max-width: 100%;
        margin: 0 auto 25px;
    }

    .splide__track {
        padding: 25px 0 0 25px;

        @include media-breakpoint-down(xl) {
            overflow: visible;
        }

        @include media-breakpoint-down(lg) {
            padding: 25px 0 0 112px;
        }

        @include media-breakpoint-down(sm) {
            padding: 10px 0 0 0;
        }
    }
    

    .splide__list {
        margin: 0 auto !important;

        li:nth-child(-n+2):last-child .news-card {
            margin-right: 25px !important;
        }
    }

    .splide__pagination {
        bottom: -30px;

        li button {   
            width: 12px;
            height: 12px;
            margin: 5px;
            opacity: 1;
            background: #E7E8F0;
            transition: background .3s;

            @include media-breakpoint-down(lg) {
                width: 16px;
                height: 16px;
                margin: 5px 10px 5px;
            }

            &:hover,
            &:focus {
                background: #dedfe8;
            }

            &.is-active {
                background: $brand-secondary;
                transform: none;
            }
        }
    }


    .splide__arrow {
        opacity: .7;
        background: transparent;
        transition: transform .3s;

        @include media-breakpoint-down(xl) {
            display: none;
        }

        &:hover {
            opacity: 1;
        }

        &--prev {
            left: -100px;

            &:hover {
                transform: translateY(-50%) translateX(-5px);
            }
        }

        &--next {
            right: -100px;

            &:hover {
                transform: translateY(-50%) translateX(5px);
            }
        }

        .arrow {
            
            &:before {
                font-family: 'buw';
                font-size: 38px;
                color: #00000038;
                font-weight: 500;
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                position: relative;
                top: 0;
                right: 0;
            }
        }

        .arrow-prev {
            transition: transform .25s;

            &:before {
                content: "\e903";
            }
        }

        .arrow-next {

            &:before {
                content: "\e905";
            }
        }
    }


    .news-card {
        display: block;
        max-width: 400px;
        margin-right: 80px;

        @include media-breakpoint-up(xl) {
            max-width: 400px !important;
        }

        @include media-breakpoint-down(xxl) {
            margin-right: 50px;
        }

        @include media-breakpoint-down(sm) {
            max-width: 320px;
            margin-right: 60px;
        }

        &:after {
            bottom: 16px;
        }

        &_content {
            min-height: 245px;
            padding: 41px 30px 16px;
            display: flex;
            flex-direction: column;

            @include media-breakpoint-down(sm) {
                min-height: 225px;
                padding: 30px 20px 17px;
            }



            .meta-footer {
                padding: 10px 0 0;

                @include media-breakpoint-down(sm) {
                    font-size: 17px;
                }


                .divider,
                .category-item {

                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                }
            }
        }

        picture {
            height: auto;
        }

        img {
            width: 400px;
            //height: 320px;
            height: auto;
            object-fit: cover;

                @include media-breakpoint-down(sm) {
                width: 100%;
                height: 200px;
            }
        }

    }

}

// Small News Teasers
.news-teaser {
    width: 100%;
    max-width: 520px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0px 0px 30px #E7E8F0;
    transition: box-shadow .2s;
    background: #fff;
    display: none;
    margin-right: 45px;

    @include media-breakpoint-down(sm) {
        flex-direction: column;
        margin-bottom: 20px;
        margin-right: 15px;
        max-width: 300px;
    }

    
    &:nth-child(-n+2) {

        @media screen and (max-width: 1660px) {
            margin-right: 30px;
        }

        @include media-breakpoint-down(sm) {
            margin-right: 15px;
        }
    }

    &:last-child {
        
        @media screen and (min-width: 1400px) and (max-width: 1660px) {
            display: none;
        }
    }

    &:nth-child(-n+3) {
        display: flex;
    }

    &:nth-child(n+3) {
        
        @media screen and (min-width: 1400px) and (max-width: 1660px) {
            display: none;
        }
    }

    //no margin right for every item after third
    &:last-child,
    &:nth-child(3) {
        margin-right: 0;
    }
    


    &:hover,
    &:focus {
        box-shadow: 0px 0px 30px #dedfea;

        h3 {
            color: $brand-secondary;
        }

        img {
            opacity: .9;
        }
    }

    &_wrapper {
        display: flex;
        overflow-x: scroll;
        justify-content: space-between;
        padding: 30px 0;
        margin: -25px 0 10px;
        width: calc(100% + 15px);
        scrollbar-width: none;

        @media screen and (max-width: 1660px) {
            width: 100%;
            justify-content: flex-start;
            margin: -25px 0 0;
        }
    
        @include media-breakpoint-down(xl) {
            overflow-x: scroll;
        }

        @include media-breakpoint-down(sm) {
            //width: 100%;
            //overflow: visible;
            //flex-wrap: wrap;
        }

        &__inner {
            display: flex;
            width: calc(100% - 15px);
            justify-content: space-between;
            justify-content: flex-start;

            @media screen and (min-width: 1400px) and (max-width: 1660px) {
                justify-content: flex-start;
            }

            @media screen and (max-width: 1660px) {
                min-width: 1600px;
            }

            @include media-breakpoint-down(sm) {
                min-width: 1005px;
                justify-content: flex-start;
            }

            &::-webkit-scrollbar {
                height: 0;
                display: none;
            }
        }

        ~ .link-arrow {
            align-self: flex-end;

            @media screen and (max-width: 1660px) {
                align-self: flex-start;
            }
        }

        &::-webkit-scrollbar {
            height: 0;
            display: none;
        }
    }

    &_content {
        padding: 0 20px;

        @include media-breakpoint-down(sm) {
            display: flex;
            flex-wrap: wrap;
            padding: 19px 20px 14px;
        }

        p {
            font-size: 18px;
            line-height: 32px;
            margin: 0;

            @include media-breakpoint-down(lg) {
                display: flex;
                justify-content: space-between;
                width: 100%;
            }

            @include media-breakpoint-down(sm) {
                line-height: 30px;
            }

            .link-arrow {

                @include media-breakpoint-down(sm) {
                    margin-left: auto;
                }
            }
        }

        span {

            &:last-of-type:after {
                content: initial;
            }

            &:after {
                content: ", ";
                position: relative;
            }
        }

        h3 {
            
            @media screen and (max-width: 1600px) {
                white-space: normal;
            }

            @include media-breakpoint-down(sm) {
                font-size: 18px;
                line-height: 27px;
                margin-bottom: 18px;
            }
        }
    }

    &::-webkit-scrollbar {
        height: 0;
        display: none;
    }

    picture {

        @include media-breakpoint-down(sm) {
            width: 100%;
        }

        img {
            width: 185px;
            height: 195px;
            object-fit: cover;
            object-position: left;
            transition: opacity .2s;
    
            @include media-breakpoint-down(sm) {
                width: 100%;
                height: 130px;
                object-position: center;
            }
        }
    }

}

#latestNews {
    padding: 0;

    .container {
        
        @include media-breakpoint-down(sm) {
            width: 440px;
        }

        &:after {

            @include media-breakpoint-down(xl) {
                content: "";
                position: absolute;
                right: 0;
                top: 0;
                width: 140px;
                height: 100%;
                background: linear-gradient(270deg, rgba(252,252,252,1) 55%, rgba(255, 255, 255, 0) 98%);
                z-index: 99;
            }

            @include media-breakpoint-down(sm) {
                content: initial;
            }
        }
    }

}

// Latest News element on startpage
body.home #latestNews {
    margin: -180px 0 0;
    padding: 0;

    @include media-breakpoint-down(xl) {
        margin: -230px 0 0;
    }

    @include media-breakpoint-down(sm) {
        margin: -245px 0 0;
        padding: 50px 0 25px;
        background: #F8F8F8;
    }
}






// Filter Tabs
.filter-tabs {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 65px;
    padding: 0;

    @include media-breakpoint-down(sm) {
        width: calc(100% + 25px);
        margin-bottom: 40px;
    }

    &_tags {

        li a {
            margin: 0 10px 10px 0 !important;

            @include media-breakpoint-down(sm) {
                margin: 0 6px 6px 0 !important;
            }
        }
    }

    &_overview {

        li {

            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(8),
            &:nth-child(9),
            &:nth-child(10),
            &:last-child {
                display: none;
            }
        }
    }


    
    li {

        &.current-cat a {
            color: #fff;
            background: $brand-primary;
            border-color: $brand-primary;
            cursor: default;
        }

        &:last-child {

            a {
                margin-right: 0;
            }
        }

        a {
            font-family: 'Roboto';
            font-size: 18px;
            font-weight: 600;
            color: #898fb7;
            padding: 10px 22px 8px;
            margin: 0 10px 0 0;
            display: inline-flex;
            background: #F8F8FB;
            border-radius: 5px;
            border: 2px solid #D0D2E2;
            transition: background .35s, border-color .35s, color .25s;

            @include media-breakpoint-down(xxl) {
                margin: 0 10px 10px 0;
            }

            @include media-breakpoint-down(sm) {
                font-size: 17px;
                font-weight: 500;
                padding: 7px 13px 5px;
                margin: 0 6px 6px 0;
            }

            &:hover,
            &:focus {
                color: #fff;
                background: $brand-primary;
                border-color: $brand-primary;
            }

            &.active {
                color: #fff;
                background: $brand-primary;
                border-color: $brand-primary;
            }

            .count {
                margin-left: 5px;
            }
        }

    }
}







//Tab Content Slider

.tab-content {
    background: #fff;
    padding: 65px 50px 65px 60px;
    box-shadow: 0 0 30px #e7e8f0;
    max-width: 1450px;

    @include media-breakpoint-down(sm) {
        padding: 33px 27px;
    }


    #videoCarousel {
        margin: 12px auto 100px;

        @include media-breakpoint-down(lg) {
            margin: 0 auto 0;
            transform: scale(.75);
        }
    }

}








// Service Blocks
.service-blocks {
    list-style: none;
    margin: 65px 0 0;
    padding: 0;
    display: flex;
    position: absolute;
    right: 0;
    max-width: 1252px;
    transform: translateX(-20px);

    @media screen and (max-width: 1400px) {
        margin: 30px 0 0;
    }

    @include media-breakpoint-down(xl) {
        position: relative;
        margin: 50px 0 0;
        flex-wrap: wrap;
        justify-content: flex-start;
        width: calc(100% + 80px);
        transform: translateX(-20px);
    }

    @include media-breakpoint-down(md) {
        width: 100%;
        justify-content: space-between;
        transform: none;
    }

    &_content {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    li {
        background: #fff;
        border: 1px solid #E7E8F0;
        padding: 50px 40px 22px;
        padding: 62px 32px 22px 40px;
        margin: 0 -1px;
        display: flex;
        flex-direction: column;
        //height: 465px;
        width: 315px;

        @media screen and (max-width: 1400px) {
            width: 295px;
            padding: 62px 15px 22px 25px;
        }

        @include media-breakpoint-down(xl) {
            width: 315px;
            padding: 62px 32px 22px 40px;
            margin-right: 20px;
            margin-bottom: 20px;
        }

        @include media-breakpoint-down(md) {
            margin-right: 0;
        }

        @include media-breakpoint-down(sm) {
            margin-right: 0;
            flex-direction: row;
            //padding: 22px 10px 20px 17px;
            padding: 22px 15px 20px 17px;
        }


        h3 {

            @include media-breakpoint-down(sm) {
                font-size: 18px;
                line-height: 26px;
                margin-bottom: 8px;
            }
        }


        p {
            font-size: 18px;
            line-height: 28px;

            @include media-breakpoint-down(sm) {
                font-size: 17px;
                line-height: 26px;
            }
        }

        a {
            align-self: flex-end;
            margin-top: auto;

            @include media-breakpoint-down(sm) {
                align-self: flex-start;
            }
        }
    }
    
    img {
        max-height: 70px;
        width: auto;
        margin-right: auto;
        display: block;
        margin-bottom: 40px;

        @include media-breakpoint-down(sm) {
            width: 100%;
            max-width: 57px;
            margin-right: 20px;
        }
    }
}





// Wrapper for some card elements
.cards {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 30px;

    @include media-breakpoint-down(xl) {
        margin: 50px 0 25px;
    }

    li {
        background: #fff;
        margin-bottom: 30px;
        width: calc(50% - 10px);
        padding: 0;

        @include media-breakpoint-down(sm) {
            width: 100%;
            margin-bottom: 12px;
        }

        a {
            display: flex;
            border-left: 10px solid $brand-secondary;
            box-shadow: 0px 0px 30px #e7e8f0;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 30px 19px 20px 25px;
            transition: border .25s, box-shadow .25s;

            @include media-breakpoint-down(xl) {
                border-left: 8px solid $brand-secondary;
                padding: 31px 15px 22px 20px;
            }

            @include media-breakpoint-down(sm) {
                padding: 16px 15px 10px 20px;
            }

            &:hover,
            &:focus {
                border-left: 10px solid $brand-primary;
                box-shadow: 0px 0px 30px #dedfea;
            }
        }

    }



    h5, .h5 {
        color: $brand-primary;
        margin: 0;
    }

    .card-icon {
        width: auto;
        height: auto;
        max-height: 60px;
        margin-right: 13px;
        margin-bottom: 5px;

        @include media-breakpoint-down(xl) {
            max-height: 48px;
            margin-right: 10px;
        }

        @include media-breakpoint-down(sm) {
            max-height: 44px;
            margin-right: 12px;
        }
    }

}



// Brand Cards
.brand-cards {
    
    @include media-breakpoint-down(sm) {
        margin-bottom: 0;
    }

    li {
        margin-bottom: 20px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 12px;
        }

        a {
            border: 0;
            justify-content: center;
            padding: 30px 25px 25px;

            @include media-breakpoint-down(sm) {
                padding: 0;
                height: 75px;
            }

            &:hover,
            &:focus {
                border-left: 0;
                box-shadow: 0px 0px 30px #d6d7e4;

                img {

                    @include media-breakpoint-up(lg) {
                        transform: translateX(3px);
                    }
                }
            }
        }

        img {
            max-width: 200px;
            max-height: 60px;
            width: auto;
            transition: transform .25s;

            @include media-breakpoint-down(sm) {
                max-width: 170px;
                max-height: 42px;
            }
        }
    }


}



// Team Cards
.team-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    margin-bottom: 30px;

    //fill empty grid space of last missing card
    &:after {
        content: "";
        flex: 0 1 375px;
    }

    > li {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 375px;
        min-height: 510px;
        padding: 30px 29px 26px;
        margin-bottom: 50px;
        background: #fff;
        box-shadow: 0px 0px 30px #e7e8f0;

        @media screen and (max-width: 1660px) {
            width: 340px;
        }

        @include media-breakpoint-down(xxl) {
            width: calc(30%);
        }

        @include media-breakpoint-down(lg) {
            width: calc(50% - 30px);
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
            min-height: 100%;
            padding: 30px 26px 15px;
        }

        img {
            display: block;
            border-radius: 60%;
            width: 100%;
            max-width: 250px;
            margin: 0 auto 33px;
            box-shadow: 0px 0px 40px #00000029;

            @media screen and (max-width: 1660px) {
                max-width: 240px;
            }

            @include media-breakpoint-down(xl) {
                max-width: 200px;
            }
        }

        .list {
            
            @include media-breakpoint-down(sm) {
                margin-bottom: 30px;
            }
        }

        .link-arrow {
            align-self: flex-end;
            margin-top: auto;
            text-decoration: none;
        }

        // Special Layout
        &.special {
            background: transparent;
            box-shadow: none;
            justify-content: flex-end;

            @include media-breakpoint-down(sm) {
                min-height: auto;
                padding: 0;
            }

            .link-arrow {
                margin-top: 20px;
            }
        }
    }


    .h4 {
        margin-bottom: 18px;
        display: block;
    }


}



// Teaser Cards
.teaser-cards {
    display: flex;
    list-style: none;
    padding: 0;
    margin-bottom: 60px;
    flex-wrap: wrap;

    @include media-breakpoint-down(lg) {
        margin: 35px 0;
        flex-wrap: wrap;
    }

    li {
        max-width: 710px;
        margin-right: 65px;
        display: flex;
        border-left: 10px solid $brand-secondary;
        background: #fff;
        box-shadow: 0px 0px 30px #e7e8f0;
        width: 100%;
        padding: 30px 26px 20px;
        transition: border .25s, box-shadow .25s;
        flex-direction: column;
        align-self: flex-start;
        flex-wrap: wrap;
        margin-bottom: 30px;
        align-self: stretch;

        @media screen and (min-width: 1200px) and (max-width: 1600px) {
            max-width: calc(50% - 30px);
            margin-right: 30px;
        }

        @include media-breakpoint-down(lg) {
            width: 100%;
            margin: 0 0 20px;
            border-left: 8px solid $brand-secondary;
        }

        h3 {
            font-size: 20px;
            line-height: 31px;
            margin-bottom: 8px;

            @include media-breakpoint-down(lg) {
                margin-bottom: 12px;
            }
        }

        p {
            margin-bottom: 30px;

            @include media-breakpoint-down(sm) {
                line-height: 28px;
                margin-bottom: 25px;
            }
        }

        .link-arrow {
            margin: auto 0 0 0;
        }
    }
}



.feature-cards {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    padding: 0;
    //margin-bottom: 60px;
    justify-content: flex-end;
    transform: translateY(-6px);

    @include media-breakpoint-down(xxl) {
        justify-content: space-between;
        margin: 0;
        transform: none;
    }

    //Horizontal list layout
    &_list {
        justify-content: center;

        @include media-breakpoint-down(xl) {
            justify-content: space-between;
        }

        li {
            text-align: center;
            margin-right: 35px !important;

            @include media-breakpoint-down(xl) {
                margin-right: 0 !important;
            }

            &:last-child {
                margin-right: 0 !important;
            }

            img {
                margin: 0 auto 30px !important;
            }
        }
    }

    &_unboxed {

        li {
            text-align: left;
            background: transparent !important;
            border: 0 !important;
            margin: 0 !important;

            img {
                margin: 0 auto 30px 0 !important;
            }
        }
    }

    &_full {
        justify-content: flex-start;

        li {
            max-width: 525px !important;

            @media screen and (max-width: 1600px) {
                max-width: 470px !important;
                margin-bottom: 30px !important;
            }

            @include media-breakpoint-down(xxl) {
                max-width: 360px ​!important;
            }

            @include media-breakpoint-down(sm) {
                max-width: 100% ​!important;
                margin-bottom: 15px !important;
            }
        }
    }

    li {
        max-width: 315px;
        margin-right: 40px;
        display: flex;
        background: #F8F8F8;
        border: 1px solid #E7E8F0;
        width: 100%;
        padding: 39px 38px 35px;
        transition: border .25s, box-shadow .25s;
        flex-direction: column;
        flex-wrap: wrap;
        margin-bottom: 40px;

        @media screen and (max-width: 1600px) {
            max-width: 312px;
        }

        @include media-breakpoint-down(xxl) {
            max-width: calc(100%/4 - 10px);
            margin-right: 0;
            padding: 39px 30px 30px;
            margin-bottom: 0;
        }

        @include media-breakpoint-down(xl) {
            max-width: calc(50% - 10px);
            margin-bottom: 20px;
        }

        @include media-breakpoint-down(sm) {
            max-width: 100%;
            margin-bottom: 20px;
        }

        &:nth-child(2n) {
            margin-right: 0;
        }

        &:nth-last-child(-n+2) {

            @include media-breakpoint-up(lg) {
                //margin-bottom: 0;
            }
        }

        &:last-child {
                
            @include media-breakpoint-down(sm) {
                margin-bottom: -20px;
            }
        }

        img {
            height: 75px;
            width: auto;
            margin: 0 auto 30px 0;
        }

        h3 {
            font-size: 20px;
            line-height: 30px;
            color: $brand-primary;
            margin-bottom: 10px;
        }

        p {
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 0;
        }

        a {
            text-decoration: underline;
        }
    }

}

.section--gray .feature-cards_list li {
    background: #fff;
}



// Logo Cards
.logo-card {
    align-self: center;
    margin: 0 auto;
    background: #fff;
    padding: 20px;
    box-shadow: 0px 0px 30px #e7e8f0;
    height: 150px;
    justify-content: center;
    align-items: center;
    display: flex;
    transition: box-shadow .2s;

    @include media-breakpoint-down(sm) {
        height: 100px;
        padding: 10px;
    }


    &_list {
        display: flex;
        margin: 0;
        padding: 0;
        list-style: none;
        flex-wrap: wrap;
        max-width: 1520px;

        li {
            margin: 0 40px 40px 0;

            @include media-breakpoint-down(sm) {
                margin: 0 0 15px;
                width: 100%;
            }
        }
    }


    &_link {
        align-self: center;
        margin: 0 auto;

        @include media-breakpoint-down(sm) {
            width: 100%;
            margin: 15px auto 0;
        }

        &:hover,
        &:focus {

            picture {
                box-shadow: 0px 0px 30px #dcdde6;
            }

            img {
                opacity: .7;
            }
        }

        //extra focus outline
        &:focus picture {
            outline: 1px solid #d4d4d4;
        }
    }

    img {
        width: 100%;
        height: auto;
        transition: opacity .2s;

        @include media-breakpoint-down(sm) {
            height: auto !important;
            width: auto !important;
            max-height: 58px;
            max-width: 200px;
        }
    }
}



.logo-card-sidecontent {
    display: flex;
    margin: 0;
    list-style: none;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;

    & > li {
        margin: 0 40px 40px 0;
        background: #fff;
        padding: 20px;
        box-shadow: 0px 0px 30px #e7e8f0;
        height: 106px;
        width: 100%;
        max-width: 691px;
        justify-content: flex-start;
        align-items: center;
        display: flex;
        transition: box-shadow .2s;

        @include media-breakpoint-down(lg) {
            width: 100%;
            margin: 0 0 20px;
        }

        @include media-breakpoint-down(sm) {
            flex-wrap: wrap;
            height: auto;
        }


        &.logo-card-sidecontent__no-logo {
            //width: auto;
            padding: 20px 60px 20px 50px;

            @include media-breakpoint-down(sm) {
                width: 100%;
                padding: 25px 20px 20px;
            }
        }

        picture {
            width: 205px;
            text-align: center;
            margin-right: 80px;

            @include media-breakpoint-down(sm) {
                width: auto;
                margin: 0 0 17px;
            }

            img {
                width: auto;
                max-height: 62px;
                max-width: 195px;
                height: auto;

                @include media-breakpoint-down(sm) {
                    width: auto;
                    max-height: 40px;
                }
            }
        }

    }// li

    
    &_content {

        @include media-breakpoint-down(sm) {
            width: 100%;
        }
        
        a {
            color: $gray-darker;

            &:hover,
            &:focus {
                color: $brand-secondary;
            }
        }
    }
}





.product-card {
    width: 100%;
    max-width: 800px;
    display: flex;
    background: #fff;
    box-shadow: 0px 0px 30px #e7e8f0;
    position: relative;
    margin-bottom: 40px;

    @media screen and (max-width: 1640px) {
        max-width: 680px;
    }

    @include media-breakpoint-down(sm) {
        max-width: 100%;
        flex-wrap: wrap;
        margin-bottom: 50px;
        box-shadow: none;
        background: transparent;
    }

    &_single {

        @include media-breakpoint-down(xl) {
            margin: 30px auto 0 0 !important;
        }
    }

    &_content {
        display: flex;
        flex-wrap: wrap;
        padding: 60px 40px 0;
        flex-direction: column;

        @media screen and (max-width: 1640px) {
            padding: 60px 30px 0;
        }

        @include media-breakpoint-down(xl) {
            padding: 0 25px 0;
            justify-content: center;
        }

        @include media-breakpoint-down(sm) {
            padding: 0;
        }

        a {
            position: absolute;
            bottom: 21px;
            right: 38px;
    
            @media screen and (max-width: 1640px) {
                position: relative;
                bottom: 0;
                right: 0;
                text-align: right;
            }
    
            @include media-breakpoint-down(sm) {
                text-align: left;
            }
        }
    }

    &_container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        padding: 0;
        margin: 0;
        list-style: none;
        
        @include media-breakpoint-down(sm) {
            margin: 30px 0 0;
        }
    }

    a img {
        width: auto;
        height: auto;
        transition: opacity .2s;

        @include media-breakpoint-down(sm) {
            width: 100%;
            margin-bottom: 18px;
        }

        &:hover {
            opacity: .8;
        }
    }

    &_no-img {

        @include media-breakpoint-up(sm) {
            padding-bottom: 50px;
        }
    }

    .h5 {
        margin-bottom: 5px;

        @include media-breakpoint-down(xl) {
            font-size: 22px;
            margin-bottom: 6px;
        }
    }

    p {
        line-height: 30px;

        @include media-breakpoint-up(sm) {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }

        @include media-breakpoint-down(xl) {
            margin-bottom: 20px;
        }

        @include media-breakpoint-down(sm) {
            margin-bottom: 15px;
        }
    }


    > picture > img {
        width: auto;
        height: auto;
        //border: 1px solid #e7e8f0;

        @media screen and (max-width: 1640px) {
            //width: 280px;
            //object-fit: cover;
        }

        @include media-breakpoint-down(xl) {
            //width: 235px;
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
            height: auto;
            object-fit: none;
            margin-bottom: 18px;
        }
    }
}




.cta-block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px 0;
    border-top: 5px solid #fff;

    @include media-breakpoint-down(sm) {
        padding: 38px 0;
    }

    &_narrow {
        padding: 35px 0 30px;

        @include media-breakpoint-down(sm) {
            padding: 28px 0 23px;
        }
    }

    a {
        margin: 0;
        
        @include media-breakpoint-down(sm) {
            font-size: 18px;
        }
    }
}





.infobox {
    display: flex;
    position: relative;
    background: #F8F8F8;
    padding: 31px 65px 35px;
    margin-bottom: 30px;
    justify-content: space-between;

    @include media-breakpoint-down(sm) {
        padding: 30px 30px 30px 55px;
        margin-bottom: 15px;
        flex-wrap: wrap;
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    &_notice {

        strong:before {
            content: "\e90c";
        }
    }

    &_example {

        strong:before {
            content: "\e908";
        }
    }

    p {
        margin-bottom: 0;
    }

    ul {
        margin-top: 30px;
    }

    img {
        width: 338px;
        height: auto;
        margin-left: 30px;
        transform: translateX(15px);

        @include media-breakpoint-down(sm) {
            width: 100%;
            height: auto;
            margin: 20px 0 0;
            transform: none;
        }
    }

    strong {
        font-family: 'Roboto';
        font-size: 22px;
        color: $gray-darker;
        margin-bottom: 13px;
        display: block;

        &:before {
            font-family: 'buw';
            font-size: 24px;
            color: $brand-secondary;
            font-weight: 500;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            position: relative;
            margin: 0 20px 0 -33px;
            top: 1px;
        }
    }

}





.download-block {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;

    @include media-breakpoint-down(xl) {
        flex-wrap: wrap;
    }

    li {
        flex-direction: column;
        display: flex;
        margin: 0 70px 15px 0;

        @include media-breakpoint-down(xl) {
            margin: 0 35px 60px 0;
            max-width: 205px;
        }

        @include media-breakpoint-down(sm) {
            margin: 0 0 60px 0;
        }

        &:last-child {

            @include media-breakpoint-down(sm) {
                margin: 0;
            }
        }

        img {
            display: block;
            max-width: 260px;
            width: 100%;
            height: auto;
            margin-bottom: 25px;
            box-shadow: 0px 0px 30px #e7e8f0;
            
            @include media-breakpoint-down(sm) {
                max-width: 170px;
            }
        }
    }

}




// Reviews Slider
.review-slider {
    max-width: 1180px;
    margin: 0 auto 65px;

    @include media-breakpoint-down(xl) {
        margin: 0 auto 30px;
    }


    @include media-breakpoint-down(sm) {
        margin: 30px auto 50px;
    }

    &_content {
        max-width: 1120px;
        background: #fff;
        box-shadow: 0px 0px 30px #e7e8f0;
        margin: 26px 35px 40px;
        padding: 55px 75px 60px 60px;

        @include media-breakpoint-down(xl) {
            padding: 50px 40px;
            max-width: 800px;
            margin: 25px 105px 50px 30px;
        }

        @include media-breakpoint-down(lg) {
            max-width: 550px;
        }

        @include media-breakpoint-down(sm) {
            padding: 25px;
            margin: 0 40px 0 0;
        }

        img {
            width: auto !important;
            margin-bottom: -12px;

            @include media-breakpoint-down(sm) {
                margin: 12px 0 0px;
            }
        }

        p:last-of-type {
            margin: -5px 0 0;
        }

        em {
            font-size: 20px;
            color: #4D4D4F;

            @include media-breakpoint-down(sm) {
                font-size: 18px;
                line-height: 26px;
                display: block;
            }

            a {
                color: $brand-secondary;

                &:hover,
                &:focus {
                    color: $brand-primary;
                }
            }
        }
    }

    .splide__track {
        //padding: 0 0 0 25px;

        @include media-breakpoint-down(xl) {
            overflow: visible;
        }
    }

    .splide__pagination {
        bottom: -30px;

        @include media-breakpoint-down(sm) {
            bottom: -60px;
        }

        li button {
            width: 12px;
            height: 12px;
            margin: 5px;
            opacity: 1;
            background: #E7E8F0;
            transition: background .3s;

            @include media-breakpoint-down(lg) {
                width: 16px;
                height: 16px;
                margin: 5px 10px 5px;
            }

            &:hover,
            &:focus {
                background: #dedfe8;
            }

            &.is-active {
                background: $brand-secondary;
                transform: none;
            }
        }
    }

    .splide__arrow {
        opacity: .7;
        background: transparent;
        transition: transform .3s;

        @include media-breakpoint-down(sm) {
            display: none;
        }

        &:hover {
            opacity: 1;
        }

        &--prev {
            left: -100px;

            @include media-breakpoint-down(xxl) {
                left: -50px;
            }

            @include media-breakpoint-down(xl) {
                left: -30px;
            }

            &:hover {
                transform: translateY(-50%) translateX(-5px);
            }
        }

        &--next {
            right: -100px;

            @include media-breakpoint-down(xxl) {
                right: -50px;
            }

            @include media-breakpoint-down(xl) {
                right: -30px;
            }

            &:hover {
                transform: translateY(-50%) translateX(5px);
            }
        }

        .arrow {
            
            &:before {
                font-family: 'buw';
                font-size: 38px;
                color: #00000038;
                font-weight: 500;
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                position: relative;
                top: 0;
                right: 0;
            }
        }

        .arrow-prev {
            transition: transform .25s;

            &:before {
                content: "\e903";
            }
        }

        .arrow-next {

            &:before {
                content: "\e905";
            }
        }
    }

    .splide__list {
        margin: 0 auto !important;
    }

}



// Teaser Card Slider
.teaser-slider {
    max-width: 1415px;
    margin: 0 auto 70px;

    @include media-breakpoint-down(xl) {
        margin: 0 auto 20px;
    }

    .splide__list {
        margin: 0 auto !important;

        li:nth-child(-n+2):last-child .teaser-card {
            margin-right: 25px !important;
        }
    }

    .splide__track {
        padding: 25px 0 0 25px;

        @include media-breakpoint-down(xl) {
            overflow: visible;
        }

        @include media-breakpoint-down(lg) {
            padding: 25px 0 0 112px;
        }

        @include media-breakpoint-down(sm) {
            padding: 10px 0 0;
        }
    }

    .splide__pagination {
        bottom: -30px;

        li button {
            width: 12px;
            height: 12px;
            margin: 5px;
            opacity: 1;
            background: #E7E8F0;
            transition: background .3s;

            @include media-breakpoint-down(lg) {
                width: 16px;
                height: 16px;
                margin: 5px 10px 5px;
            }

            &:hover,
            &:focus {
                background: #dedfe8;
            }

            &.is-active {
                background: $brand-secondary;
                transform: none;
            }
        }
    }

    .splide__slide {
        display: flex;
    }

    .splide__arrow {
        opacity: .7;
        background: transparent;
        transition: transform .3s;

        @include media-breakpoint-down(xl) {
            display: none;
        }

        &:hover {
            opacity: 1;
        }

        &--prev {
            left: -100px;

            &:hover {
                transform: translateY(-50%) translateX(-5px);
            }
        }

        &--next {
            right: -100px;

            &:hover {
                transform: translateY(-50%) translateX(5px);
            }
        }

        .arrow {
            
            &:before {
                font-family: 'buw';
                font-size: 38px;
                color: #00000038;
                font-weight: 500;
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                position: relative;
                top: 0;
                right: 0;
            }
        }

        .arrow-prev {
            transition: transform .25s;

            &:before {
                content: "\e903";
            }
        }

        .arrow-next {

            &:before {
                content: "\e905";
            }
        }
    }


    .teaser-card {
        display: flex;
        flex-direction: column;
        max-width: 400px;
        margin-right: 80px;
        margin-bottom: 50px;
        box-shadow: 0px 0px 30px #e7e8f0;

        @include media-breakpoint-up(xl) {
            max-width: 400px !important;
        }
        
        @include media-breakpoint-down(xxl) {
            margin-right: 50px;
        }
        
        @include media-breakpoint-down(sm) {
            //margin-right: 40px;
        }

        &:after {
            bottom: 16px;
        }

        &_content {
            display: flex;
            height: 100%;
            padding: 41px 31px 17px;
            flex-direction: column;
            flex-wrap: wrap;

            @include media-breakpoint-down(sm) {
                padding: 30px 20px 17px;
            }

            p {
                line-height: 30px;
                margin-bottom: 30px;
            }

            .meta-footer {
                align-self: flex-end;
                margin: auto 11px 5px 0;
            }
        }

        img {
            width: 100%;
            height: 310px;
            display: block;
            object-fit: cover;

            @include media-breakpoint-down(sm) {
                width: 100%;
                height: 200px;
            }
        }
    }

}







.overlay-block {
    position: absolute;
    right: 160px;
    top: 0;
    width: 536px;
    background: #fff;
    padding: 40px 40px 50px;
    box-shadow: 0px 0px 30px #e7e8f08c;
    z-index: 99;

    @media screen and (max-width: 1600px) {
        right: 40px;
        width: 470px;
        padding: 40px 35px 35px;
    }

    @include media-breakpoint-down(xl) {
        padding: 35px 45px !important;
        margin: 0 0 -70px !important;
        position: relative !important;
        bottom: 0 !important;
        right: 0 !important;
    }

    @include media-breakpoint-down(sm) {
        margin: 0 0 -40px !important;
        padding: 23px 25px !important;
    }

    &_pulled {
        top: initial;
        bottom: 390px;
    }

    &_gray {
        background: #F8F8F8;
        border: 1px solid #E7E8F0;
    }

    &_bp {
        padding: 50px 60px 60px;
    }

    &_xl {
        right: 35px;
        width: 780px;
        padding: 30px;

        @media screen and (max-width: 1600px) {
            width: 680px;
            right: 0;
        }

        @include media-breakpoint-down(xxl) {
            width: 43%;
            margin: 30px 0 -70px !important;
        }

        @include media-breakpoint-down(xl) {
            width: 90%;
            right: 0;
        }

        @include media-breakpoint-down(lg) {
            width: 100%;
        }

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    p {
        margin-bottom: 23px;
        
        &:last-of-type {
            margin: 0;
        }
    }

    form {

        .col-auto {
            max-width: initial;
            margin-bottom: 12px;
            width: 100%;
        }

        .btn {
            margin: 0 auto 0 0;

            @include media-breakpoint-down(sm) {
                 width: 100%;
            }
        }

        .txt-sm a {
            color: $brand-secondary;
            font-weight: 500;
            text-decoration: underline;

            &:hover {
                color: $brand-primary;
            }
        }

        .wpcf7-acceptance .wpcf7-list-item {
            margin: 0 0 17px;
        }

    } //form
}








.newsletter-block {
    padding: 40px 0 32px;

    @include media-breakpoint-down(xl) {
        padding: 40px 0 20px;
    }

    @include media-breakpoint-down(sm) {
        padding: 40px 0 30px;
        margin-bottom: 25px;
    }

    #newsletterForm {
        display: flex;
        max-width: 1150px;
        justify-content: center;
        align-items: center;
        margin: 0 auto;

        @include media-breakpoint-down(xl) {
            max-width: auto;
            flex-wrap: wrap;
            justify-content: flex-start;
            flex-direction: column;
            align-items: flex-start;
            padding: 0 80px;
        }

        @include media-breakpoint-down(sm) {
            padding: 0 35px;
        }

        .form-wrap {
            display: flex;
            align-items: flex-start;
            width: 100%;
            max-width: 685px;

            @include media-breakpoint-down(xl) {
                justify-content: space-between;
                width: 100%;
                max-width: initial;
            }

            @include media-breakpoint-down(xl) {
                flex-wrap: wrap;
            }
        }

        .form-group {
            min-width: 430px;
            margin: 0 20px 0 84px !important;

            @include media-breakpoint-down(xl) {
                min-width: auto;
                max-width: calc(100% - 270px);
                margin: 0 15px 20px 0!important;
            }

            @include media-breakpoint-down(sm) {
                min-width: auto;
                max-width: 100%;
                margin: 0 0 20px !important;
            }

            .form-control {
                color: $brand-primary;
                background: #fff;
                border: 1px solid #E7E8F0;

                &:focus {
                    border: 1px solid $brand-primary;
                }

                &::placeholder {
                    color: #999;
                }

                select {
                    font-size: 16px;
                    font-style: italic;
                    border: 0;
                }
            }

            label {
                font-size: 16px;
                font-style: italic;
                color: #999;
                margin: 0;
                height: auto;
                line-height: 32px;

                @include media-breakpoint-down(sm) {
                    font-size: 16px;
                    line-height: 24px;
                    width: 230px;
                    margin-top: 5px;
                } 
            }
        }

        .form-check-inline {
            display: inline-block;
            margin: 7px 0 0 -6px;
            width: calc(100% + 50px);

            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }

        .form-check-input {
            margin-right: 19px;

            @include media-breakpoint-down(sm) {
                margin-right: 15px;
            }

            &[type="checkbox"] {
                background: transparent;

                &:before {
                    content: "" !important;
                    cursor: pointer !important;
                    background: transparent;
                    display: inline-block !important;
                    height: 25px !important;
                    width: 25px !important;
                    border: 2px solid #E7E8F0;
                    border-radius: 6px;

                    @include media-breakpoint-down(sm) {
                        height: 22px !important;
                        width: 22px !important;
                        border-radius: 4px;
                    }
                }

            }
        }
        
        input[type="checkbox"]:checked:before {
            background: url(../../../Resources/Public/Images/check.svg) no-repeat 3px 5px;
            background-size: 14px;

             @include media-breakpoint-down(sm) {
                background: url(../../../Resources/Public/Images/check.svg) no-repeat 2px 4px;
            }
        }

        .btn {
            //margin-bottom: 30px;
            min-width: 250px;
        }

        a {
            color: $brand-secondary;

            &:hover,
            &:focus {
                color: $brand-primary;
            }
        }

        h3 {
            
            @include media-breakpoint-down(xl) {
                margin-bottom: 20px;
            }
        }

    }
}










//Calendar List Items
#o365-list-items-outlook_cal_o365 {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0;
    padding: 0;
    list-style: none;

    &:after {

        @include media-breakpoint-up(lg) {
            content: "";
            width: 520px;
        }

        @include media-breakpoint-up(md) {
            content: "";
            width: 470px;
        }
    }

    .o365-list__item,
    .news-card {
        margin: 0 40px 40px 0 !important;

        &:after {
            content: initial;
        }

        &:last-child {
            margin-right: 0 !important;
        }

        &:hover,
        &:focus {
            box-shadow: 0px 0px 30px #dedfea;

            .h4, .h5 {
                color: initial;
            }

            img {
                opacity: 1;
            }
        }

        .meta-footer {
            display: flex;
            justify-content: flex-end;
            text-shadow: none;
        }
    }
}





//Small Calendar Widget
#calendar_ook_cal_o365  {
    display: flex;
    flex-wrap: wrap;
    margin: 45px 0 -45px;


    .fc-header-toolbar {
        display: flex;
        order: 2;
        justify-content: space-between;
        width: 100%;

        //Headline
        .fc-center {
            position: absolute;
            left: 31px;
            top: 30px;

            h2 {
                font-size: rem(24px);
                line-height: rem(32px);
                font-weight: 700;
                margin-bottom: rem(22px);
            }
        }

        .fc-left,
        .fc-right {
            float: none;
        }

        .fc-clear,
        .fc-listMonth-button,
        .fc-printButton-button {
            display: none;
        }

        .fc-button {
            font-family: Roboto;
            font-size: 14px;
            font-weight: 600;
            color: #898fb7;
            padding: 10px 14px 8px;
            margin-right: 10px !important;
            background: #f8f8fb;
            border-radius: 5px;
            border: 2px solid #d0d2e2;
            height: auto;
            box-shadow: none;
            text-shadow: none;
            transition: background .35s,border-color .35s,color .25s;

            &:hover,
            &:focus,
            &.fc-state-active {
                background: $brand-primary;
                border-color: $brand-primary;
                color: #fff;
            }

            &[disabled] {
                pointer-events: none;
            }
        }
    }

    .fc-head-container .fc-widget-header {
        margin-right: 0px !important;
        border: 0;
    }

    //Hide message for no events
    .o365-cal-no-records {
        display: none;
    }

    .fc-scroller.fc-day-grid-container {
        overflow: hidden scroll !important;
        max-height: 500px;
    }


    td.fc-today {
        background: #ee7f010f !important;
    }

    .fc-basic-view .fc-day-number {
        background-color: transparent;
        color: #898FB7;
        font-size: 12px;
        text-align: center;
    }


    .fc-basicWeek-view .fc-row.fc-week.fc-widget-content {
        height: 500px !important;
    }


    table {


        .fc-day-header {
            padding: 6px 10px;
            font-size: 12px;
            color: #fff;
            background-color: $brand-primary;
            text-transform: uppercase;
            font-weight: 400;
            border: 0;
        }

        td.fc-day.fc-widget-content.fc-thu.fc-today {
            background: #131f700d;
        }

        td a.fc-day-grid-event.fc-event {
            background-color: $brand-secondary !important;
            border: 0;
            border-radius: 5px;
            padding: 4px 12px;
            margin: 5px 8px;
            transition: background .2s,color .2s;

            @media screen and (max-width: 1600px) {
                padding: 4px 9px;
                margin: 5px 5px;
            }


            &:hover,
            &:focus {
                background: #dd7602 !important;
                color: #fff;
            }

            .fc-content {
                padding: 0;
                text-align: left;
                font-size: 12px;
                font-weight: 500;
            }

            .fc-time {
                font-weight: 500;
            }

            .fc-title {
                text-transform: capitalize;
                font-weight: 500;
                display: none;
            }
        
            .fc-header-toolbar h2 {
                font-size: 24px;
                font-weight: 600;
            }

            .ms-Icon {
                display: none;
            }
        }



    }
}



//Hide category select
select#change_ook_cal_o365 {
    display: none;
}

.fc-unthemed .fc-content, .fc-unthemed .fc-divider, .fc-unthemed .fc-list-heading td, .fc-unthemed .fc-list-view, .fc-unthemed .fc-popover, .fc-unthemed .fc-row, .fc-unthemed tbody, .fc-unthemed td, .fc-unthemed th, .fc-unthemed thead {
    border-color: #E7E8F0;
}


//Add Uhrzeit to time depending on selected language
#calendar_ook_cal_o365 table td a.fc-day-grid-event.fc-event .fc-time:after {
    content: " Uhr";
}

html[lang="en-US"] #calendar_ook_cal_o365 table td a.fc-day-grid-event.fc-event .fc-time:after {
    content: " ";
}

.old-event {
    background: $brand-secondary;
    padding: 10px 20px 9px;
    color: #fff;
    border-radius: 6px;
    margin: 0 15px;
    display: inline;
    font-size: 16px;
    font-weight: 600;
    vertical-align: bottom;

    @include media-breakpoint-down(sm) {
        padding: 2px 15px 0;
        margin: 8px 0;
        font-size: 15px;
        display: inline-block;
    }
}

.downloadbtn {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;

    a, .btn {
        margin-right: 15px;
    }
}

.o365-single__inner p a {
    //display: none;
}




body .tribe-common {

    header.tribe-events-header {
        z-index: 9;
        box-shadow: none;
    }

    header.tribe-events-calendar-month__header {
        z-index: 0;
        box-shadow: none;
    }

    .tribe-events .tribe-events-c-view-selector__list-item-icon {
        width: 20px;
        margin-bottom: 17px;
    }


    div#tribe-events-view-selector-content {
        min-width: 150px;
    }
}

.tribe-events .datepicker .day.active, .tribe-events .datepicker .day.active.focused, .tribe-events .datepicker .day.active:focus, .tribe-events .datepicker .day.active:hover, .tribe-events .datepicker .month.active, .tribe-events .datepicker .month.active.focused, .tribe-events .datepicker .month.active:focus, .tribe-events .datepicker .month.active:hover, .tribe-events .datepicker .year.active, .tribe-events .datepicker .year.active.focused, .tribe-events .datepicker .year.active:focus, .tribe-events .datepicker .year.active:hover {
    background: #131f70 !important;
}

.tribe-common .tribe-common-c-btn-border, .tribe-common a.tribe-common-c-btn-border {
    border: 1px solid #131f70 !important;
    color: #131f70 !important;
}

.tribe-events .tribe-events-c-subscribe-dropdown .tribe-events-c-subscribe-dropdown__button.tribe-events-c-subscribe-dropdown__button--active, .tribe-events .tribe-events-c-subscribe-dropdown .tribe-events-c-subscribe-dropdown__button:focus, .tribe-events .tribe-events-c-subscribe-dropdown .tribe-events-c-subscribe-dropdown__button:focus-within, .tribe-events .tribe-events-c-subscribe-dropdown .tribe-events-c-subscribe-dropdown__button:hover {
    color: #fff !important;
    background-color: #131f70 !important;
}

header.tribe-events-calendar-list__event-header {
    z-index: 0 !important;
    box-shadow: none !important;
}

.tribe-events .tribe-events-c-view-selector__list-item-icon {
    height: 36px !important;
}

header.tribe-events-calendar-latest-past__event-header {
    background: transparent !important;
    box-shadow: none !important;
}

.tribe-events-c-subscribe-dropdown__button-text {
    transition: color 0s;
}

button.tribe-common-h3.tribe-common-h--alt.tribe-events-c-top-bar__datepicker-button {
    font-size: 18px !important;
}

.tribe-common-l-container.tribe-events-l-container,
#tribe-events-pg-template {
    max-width: 100% !important;
    padding: 0 !important;
}

.tribe-events-tooltip-theme {
    display: none !important;
}

.tribe-events .tribe-events-calendar-month__day-date-daynum {
    color: #999 !important;
    font-weight: 300 !important;
    font-size: 20px !important;
}

.tribe-events .tribe-events-calendar-month__day-date-link {
    color: #131f70 !important;
    font-weight: 600 !important;
}



h1.tribe-events-single-event-title,
.tribe-events-pg-template h1 {
    font-size: rem(50px);
    line-height: rem(60px);
    font-weight: 500;
    margin-bottom: rem(5px);

    @include media-breakpoint-down(lg) {
        font-size: 38px;
        line-height: 48px;
    }

    @include media-breakpoint-down(sm) {
        font-size: rem(28px);
        line-height: rem(34px);
        margin-bottom: rem(26px);
    }

    @media screen and (max-width: 360px) {
        font-size: rem(27px);
        line-height: rem(32px);
        width: calc(100% + 20px);
    }
}


.tribe-events-schedule.tribe-clearfix {
    margin: 0 0 40px;

    h2 {
        color: $brand-primary;
        font-size: 18px;
    }
}

.tribe-events-pg-template h1,
.tribe-events-pg-template h2,
.tribe-events-pg-template h3,
.tribe-events-pg-template h4 {
    font-family: Roboto !important;
}

header.tribe-events-calendar-day__event-header {
    background: transparent;
    box-shadow: none;
}

.tribe-events-single-event-description.tribe-events-content {
    max-width: 996px !important;
}

.tribe-events-single {
    max-width: 84rem !important;
    margin: 0 auto !important;
    padding: 0 var(--bs-gutter-x,.75rem) 0 !important;
}

.tribe-events-view {
    position: relative;
    width: 100%;
    max-width: 84rem !important;
    margin: 0 auto !important;
    padding: 0 var(--bs-gutter-x,.75rem) 0 !important;
}


.tribe-events-back a {
    font-family: "Roboto" !important;
    font-size: rem(18px) !important;
    font-weight: 600 !important;
    color: #fff !important;
    padding: rem(19px 31px 16px) !important;
    border-radius: rem(5px) !important;
    background: $brand-primary !important;
    text-decoration: none !important;
    margin-bottom: rem(20px) !important;
    border: 0 !important;
    transition: background .3s, color .2s, border-color .3s;

    @include media-breakpoint-down(sm) {
        padding: rem(19px 22px 17px) !important;
        margin-bottom: rem(15px) !important;
    }

    &:hover,
    &:focus {
        color: #fff !important;
        background: $brand-primary-hover !important;
        cursor: pointer !important;
        text-decoration: none !important;
    }
}


:root {
    --tec-color-accent-primary: #131f70 !important;
    --tec-color-background-events-bar-submit-button: #131f70 !important;
    --tec-color-background-events-bar-submit-button-hover: #131f70 !important;
    --tec-color-background-events-bar-submit-button-active: #131f70 !important;
    --tec-form-color-accent-primary: #131f70 !important;
    --form-color-accent-primary: #131f70 !important;
    --tec-font-family-sans-serif: Lato,sans-serif !important;
    --tec-font-size-2: 16px !important;
}