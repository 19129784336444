form {

    .form-group {
        width: 100%;
        margin-bottom: 10px;

        input,
        select {
            font-size: 16px;
            font-style: italic;
            border: 0;
        }
    }

}


.form-control {
    padding: 20px 20px 18px;
    border: 1px solid #E7E8F0;
    font-size: 16px;
    font-style: italic;
    color: $gray;
    background: #fff;
    transition: border .3s;

    @include media-breakpoint-down(sm) {
        padding: 18px 15px 16px;
    }

    &:hover {
        border: 1px solid #E7E8F0;
    }

    &:focus {
        color: $gray;
        border-color: $brand-primary;
        outline: none;
        box-shadow: none;
    }

}

form .form-group input:focus, form .form-group select:focus {
    box-shadow: none;
}

#demoRequest,
.wpcf7-form {
    max-width: 880px;
    margin: 0 auto;
    text-align: center;

    .sr-only {
        display: none;
    }

    .col-auto {
        margin-bottom: 20px;
        max-width: 430px;
        width: 100%;

        @include media-breakpoint-down(xl) {
            width: calc(50% - 5px);
            margin-bottom: 10px;
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
            margin-bottom: 10px;
        }
    }

    .form-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    br {
        display: none;
    }

    .btn {
        display: block;
        margin: 0 auto;
    }
}


.txt-sm {
    font-size: 16px;
    line-height: 22px;
    font-style: italic;
    color: $gray;
    text-align: left;
    padding: 0;
    margin: 0;

    a {
        color: inherit;
        text-decoration: underline;

        &:hover,
        &:focus {
            color: $brand-primary;
        }
    }
}


.wpcf7-not-valid-tip {
    color: #fff !important;
    font-size: 0px !important;
    background: $brand-secondary;
    position: relative;
    z-index: 99;
    height: 3px;
    left: 1px;
    margin-bottom: -4px;
    width: calc(100% - 2px);
}

.wpcf7 form .wpcf7-response-output {
    margin: 10px 0 !important;
    border: 0 !important;
    background: $brand-secondary !important;
    color: #fff !important;
    font-size: 18px !important;
    padding: 11px !important;
    border-radius: 3px !important;

    @include media-breakpoint-down(sm) {
        font-size: 16px!important;
        line-height: 22px!important;
    }
}

.wpcf7-response-output.wpcf7-validation-errors {
    padding: 10px 20px;
    background: $brand-primary;
    color: #fff;
    font-size: 15px;
    border-radius: 5px;
}

.screen-reader-response {
    display: none;
}




.wpcf7-form {


    .wpcf7-acceptance {

        .wpcf7-list-item {
            margin: 0 0 30px;

            @include media-breakpoint-down(sm) {
                margin: 15px 0 20px;
            }

            & > label {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
            }
        }
    }


    input[type="checkbox"],
    input#datenschutz {
        opacity: 1;
        margin-right: 20px;
        height: 25px;
        width: 25px;
        background: #fff;
        display: inline-block !important;
        box-shadow: none;
        transform: translate(1px,-2px);

        @include media-breakpoint-down(sm) {
            margin-right: 12px;
        }

        &:before {
            content: "" !important;
            cursor: pointer !important;
            background: #fff;
            display: inline-block !important;
            height: 25px !important;
            width: 25px !important;
            border: 1px solid #E7E8F0;
        }

        &:checked:before {
            background: url(../../../Resources/Public/Images/check.svg) no-repeat #fff;
            background-size: 13px;
            background-position: center;
        }
    }



    input[type="checkbox"] {
        display:none;
    }

    input[type="checkbox"] + label {
        width: 19px;
        height: 19px;
        border-radius: 2px;
        border: 2px solid $gray;
        display: inline-block;
        content: "";
        float: left;
        margin-right: 10px;
        transition: background-color .3s ease,border-color .3s ease;
        margin-top: 1px;
        left: -27px;
        position: absolute;
    }

    input[type="checkbox"] + label:hover {
        cursor: pointer;
    }

    input[type="checkbox"] + label::before {
        width: 20px;
        height: 20px;
        margin: 2px 2px;
        border-radius: 10px;
        background-color: transparent;
        display: block;
        content: "";
        transition: margin .1s linear;
    }

    input[type="checkbox"]:checked+label{
        background-color: $brand-secondary;
        border-color: $brand-secondary;
    }

    input[type="checkbox"]:checked+label::before {
        background: #fff;
        background: url(../../../Resources/Public/Images/check.svg) no-repeat -1px -2px;
        background-size: 14px;
    }

}




.wpcf7 .ajax-loader {
    visibility: hidden;
    display: inline-block;
    background-color: #23282d;
    opacity: 0.75;
    width: 24px;
    height: 24px;
    border: none;
    border-radius: 100%;
    padding: 0;
    position: absolute;
    left: 0;
    right: 0;
    margin: 15px auto;
}



.post-password-form {

    input, .form-control {
        border: 1px solid #e7e8f0;
        margin-bottom: 25px;
    }
}




/* change placeholder color */
::placeholder { 
    color: #9f9f9f;
    opacity: 1;
}

:-ms-input-placeholder {
    color: #9f9f9f;
}

::-ms-input-placeholder {
    color: #9f9f9f;
}









#expandedNewsletterForm {

    .form-group {
        width: 100%;

        .form-control {
            color: $brand-primary;
            background: #fff;
            border: 1px solid #E7E8F0;
            margin-bottom: 12px;

            &:focus {
                border: 1px solid $brand-primary;
            }

            &::placeholder {
                color: #999;
            }

            select {
                font-size: 16px;
                font-style: italic;
                border: 0;
            }
        }

        label {
            font-size: 16px;
            font-style: italic;
            color: #999;
            margin: 0;
            height: auto;
            line-height: 32px;

            @include media-breakpoint-down(sm) {
                font-size: 16px;
                line-height: 24px;
                width: 205px;
                margin-top: 5px;
            } 
        }
    }

    .form-check-inline {
        margin: 8px 0 23px -3px;

        @include media-breakpoint-down(sm) {
            margin: 8px 0 17px -3px;
        }
    }

    .form-check-input {
        margin-right: 19px;

        @include media-breakpoint-down(sm) {
            margin-right: 15px;
        }


        &[type="checkbox"] {
            background: transparent;

            &:before {
                content: "" !important;
                cursor: pointer !important;
                background: transparent;
                display: inline-block !important;
                height: 25px !important;
                width: 25px !important;
                border: 2px solid #E7E8F0;
                border-radius: 6px;

                @include media-breakpoint-down(sm) {
                    height: 22px !important;
                    width: 22px !important;
                    border-radius: 4px;
                }
            }

        }
    }
    
    input[type="checkbox"]:checked:before {
        background: url(../../../Resources/Public/Images/check.svg) no-repeat 3px 5px;
        background-size: 14px;
    }


    a {
        color: $brand-secondary;

        &:hover,
        &:focus {
            color: $brand-primary;
        }
    }

}










//Password login form

.post-password-form {

    p:first-of-type {
        display: none;
    }
}