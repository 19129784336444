html, body {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: $gray-dark;
    background: #fff;
    overflow-x: hidden;
    margin: 0;
    word-break: break-word;
    animation: fadeIn 1.4s;

    @include media-breakpoint-down(sm) {
        height: 100vh;
    }

    @keyframes fadeIn {
        0%,70% {opacity: 0;}
        to {opacity: 1;}
    }

    // no scrolling on mobile when nav is open 
    &.navbar-open {

        @include media-breakpoint-down(md) {
            overflow: hidden;
        }
    }

    &.no-scroll {
        overflow: hidden;
    }
}


.container {
    position: relative;
    max-width: rem(1680px) !important;

    @include media-breakpoint-down(xl) {
        padding: 0 80px;
    }

    @include media-breakpoint-down(md) {
        padding: 0 35px;
    }

    @include media-breakpoint-down(sm) {
        padding: 0 33px 0 34px;
    }

    @media screen and (max-width: 365px) {
        padding: 0 28px 0;
    }

    &-flex {
        display: flex;
        flex-direction: column;
    }
}


main {

    @include media-breakpoint-down(sm) {
        margin-top: 70px;
    }

    &.bg-img {
        background-repeat: no-repeat;
        background-position: top right;
        background-size: contain;
    }
}


#breadcrumbs {
    font-size: 18px;
    padding: 50px 0 35px;
    z-index: 99;
    position: relative;

    @include media-breakpoint-down(lg) {
        font-size: 17px;
        padding: 35px 0 35px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 16px;
        padding: 25px 0 30px;
        width: calc(100% + 20px);
    }

    .breadcrumbArrow:after {
        font-family: 'buw';
        content: "\e904";
        font-size: 13px;
        font-weight: 500;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        transition: color .1s,transform .4s;
        position: relative;
        margin: 0 5px;

        @include media-breakpoint-down(sm) {
            font-size: 12px;
            margin: 0 3px;
        }
    }

    .breadcrumb_last {
        
        color: $gray;
    }
}



.home #breadcrumbs {
    display: none;
}



///// HELPER CLASSES /////
.align-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.no-padding {
    padding: 0 !important;
}

.block-center {
    margin: 0 auto !important;
    display: block !important;
}


.spacer {
    min-width: 130px;
    display: inline-block;
    
    &-xxl {
        min-width: 280px;
    }
    
    &-xl {
        min-width: 200px;
    }
    
    &-md {
        min-width: 160px;
    }

    &-sm {
        min-width: 100px;
    }
}


//margin top & bottom
.m-md {
    margin: 50px 0;
}

.m-xl {
    margin: 100px 0;
}


//margin-bottom
.mb-xxs {
    margin-bottom: 6px !important;
}
.mb-xs {
    margin-bottom: 13px !important;
}
.mb-sm {
    margin-bottom: 30px;
}
.mb-md {
    margin-bottom: 50px;
}
.mb-lg {
    margin-bottom: 80px;

    @include media-breakpoint-down(xl) {
        margin-bottom: 40px;
    }

    @include media-breakpoint-down(sm) {
        margin-bottom: 20px;
    }

    &_mobile {
        
        @include media-breakpoint-down(xl) {
            margin-bottom: 80px !important;
        }
    }
}
.mb-xl {
    margin-bottom: 120px;

    @include media-breakpoint-down(sm) {
        margin-bottom: 60px;
    }
}




//margin-top
.mt-sm {
    margin-top: 30px;
}
.mt-md {
    margin-top: 45px !important;

    @include media-breakpoint-down(md) {
        margin: 25px 0 !important;
    }
}


.mt-xl {

    @include media-breakpoint-up(xl) {
        margin-top: 300px !important;
    }
}



//margin-right
.mr-sm {

    @include media-breakpoint-up(lg) {
        margin-right: 50px !important;
    }
}

.mr-md {

    @include media-breakpoint-up(lg) {
        margin-right: 90px !important;
    }
}

.mr-xl {

    @include media-breakpoint-up(lg) {
        margin-right: 145px !important;
    }
}


.ml-auto {

    @include media-breakpoint-up(lg) {
        margin-left: auto !important;
    }
}


//padding-bottom
.pb-sm {
    padding-bottom: 50px !important;
}
.pb-md {
    padding-bottom: 80px !important;

    @include media-breakpoint-down(sm) {
        padding-bottom: 55px !important;
    }
}
.pb-xl {
    padding-bottom: 120px !important;
}
.pb-xxl {
    padding-bottom: 220px !important;

    @include media-breakpoint-down(xl) {
        padding-bottom: 70px !important;
    }
}

// Special case, removes padding of technical informations block on product pages 
.section--product_header ~ section .row > div > .pl-xxl {

    @include media-breakpoint-down(xl) {
        padding: 0;
        margin-bottom: 40px;
    }
}


//padding-top
.pt-sm {
    padding-top: 50px !important;
}
.pt-md {
    padding-top: 80px !important;
}


//padding-left
.pl-xxl {

    @include media-breakpoint-up(lg) {
        padding-left: 140px;
    }
}

//padding-right
.pr-md {

    @include media-breakpoint-up(lg) {
        padding-right: 90px;
    }
}




.pull-left_lg {

    @media screen and (min-width: 1600px) {
        transform: translateX(-100px);
    }
}





//no margin for the last p-tag of this div
.no-pmb p:last-of-type {
    margin-bottom: 0;
}


.col-max_sm {

    @include media-breakpoint-up(xl) {
        max-width: 735px;
    }
}

.col-max_md {

    @include media-breakpoint-up(xl) {
        max-width: 800px;
    }
}

.col-max_lg {

    @include media-breakpoint-up(xl) {
        max-width: 955px;
    }
}



.text-left_sm {

    @include media-breakpoint-down(xl) {
        text-align: left !important;
    }
}








.layout-block-sm {

    @include media-breakpoint-down(xl) {
        max-width: 980px;
        margin: 0 auto;
        padding-right: calc(var(--bs-gutter-x) / 2);
    }

    @include media-breakpoint-down(md) {
        max-width: 680px;
    }
}





.block-shadow {
    box-shadow: 0px 0px 30px #E7E8F0;
}

.text-shadow {
    text-shadow: 0px 0px 0px $gray-darker;
}



// Refresh animation on filter-btn click
.refresh_on {
    opacity: 1;
    animation: refresh1 .7s ease forwards;

    @keyframes refresh1 {
        from {opacity:0;}
        to {opacity:1;}
    }
}

.refresh_off {
    opacity: 1;
    animation: refresh2 .7s ease forwards;

    @keyframes refresh2 {
        from {opacity:0;}
        to {opacity:1;}
    }
}


///// Table Layouts /////

.table-list {

    tr {

        td {

            &:first-child {
                font-weight: 700;
                padding-right: rem(25px);
                min-width: rem(160px);
            }
        }
    }
}


table {

    @include media-breakpoint-down(sm) {
        font-size: 14px;
    }
}



iframe {

    @include media-breakpoint-down(sm) {
        max-width: 100%;
        height: auto;
        margin: 20px 0;
    }
}

iframe[src*="youtube"] {

    @include media-breakpoint-up(sm) {
        height: unset;
        min-height: 315px;
    }
}

.alignright {
    text-align: right;
    margin: 5px 10px;
    float: right;
}

.alignleft {
    text-align: left;
    margin: 5px 10px;
    float: left;
}

.aligncenter {
    text-align: center;
    margin: 0 auto;
}



///// POPOVER /////

.popover {
    border: 1px solid #e0e0e0;
    box-shadow: 0px 3px 8px rgba(0,0,0,.07);
    top: -5px !important;

    &-body {
        padding: 10px 15px;
        color: #666;
        border: 0;
    }
}



///// GENERAL OPTIONS /////

//text selection highlight color
::selection {
    background: $brand-primary;
    color: #fff;
}