$icomoon-font-family: "buw" !default;
$icomoon-font-path: "../webfonts" !default;

$icon-close: "\e910";
$icon-checked: "\e911";
$icon-arroe_right_s2: "\e900";
$icon-arrow_down: "\e901";
$icon-arrow_left: "\e902";
$icon-arrow_left_s1: "\e903";
$icon-arrow_right: "\e904";
$icon-arrow_right_s1: "\e905";
$icon-download: "\e906";
$icon-email: "\e907";
$icon-lamp: "\e908";
$icon-language: "\e909";
$icon-linkedin: "\e90a";
$icon-phone: "\e90b";
$icon-pin: "\e90c";
$icon-search: "\e90d";
$icon-xing: "\e90e";
$icon-youtube: "\e90f";


@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?c65ghw') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?c65ghw') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?c65ghw##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-checked {
  &:before {
    content: $icon-checked;     
    color: #131f70;
  }
}
.icon-arroe_right_s2 {
  &:before {
    content: $icon-arroe_right_s2;     
    color: #ee7f01;
  }
}
.icon-arrow_down {
  &:before {
    content: $icon-arrow_down;     
    color: #898fb7;
  }
}
.icon-arrow_left {
  &:before {
    content: $icon-arrow_left;     
    color: #131f70;
  }
}
.icon-arrow_left_s1 {
  &:before {
    content: $icon-arrow_left_s1;     
    color: #ccc;
  }
}
.icon-arrow_right {
  &:before {
    content: $icon-arrow_right;     
    color: #131f70;
  }
}
.icon-arrow_right_s1 {
  &:before {
    content: $icon-arrow_right_s1;     
    color: #ccc;
  }
}
.icon-download {
  &:before {
    content: $icon-download;     
    color: #ee7f01;
  }
}
.icon-email {
  &:before {
    content: $icon-email;     
    color: #898fb7;
  }
}
.icon-lamp {
  &:before {
    content: $icon-lamp;     
    color: #ee7f01;
  }
}
.icon-language {
  &:before {
    content: $icon-language;     
    color: #898fb7;
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin;     
    color: #fff;
  }
}
.icon-phone {
  &:before {
    content: $icon-phone;     
    color: #898fb7;
  }
}
.icon-pin {
  &:before {
    content: $icon-pin;     
    color: #ee7f01;
  }
}
.icon-search {
  &:before {
    content: $icon-search;     
    color: #898fb7;
  }
}
.icon-xing {
  &:before {
    content: $icon-xing;     
    color: #fff;
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube;     
    color: #fff;
  }
}

