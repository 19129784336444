#functionsTabContent {
    margin: 0;
            
    @include media-breakpoint-down(lg) {
        margin-bottom: 30px;
    }
    
    @include media-breakpoint-down(sm) {
        margin-bottom: 5px;
    }

    
    img {
        width: auto;
        height: auto;
        max-width: 100%;
        padding: 30px 60px 30px 30px;
        margin: 20px 0;
        background: #fff;
        box-shadow: 0px 0px 30px #e7e8f0;
        width: fit-content;
        display: block;
            
        @include media-breakpoint-down(sm) {
            padding: 10px;
        }

        &.alignright {
            margin: 15px 0 20px 20px;
            
            @include media-breakpoint-down(sm) {
                float: none;
            }
        }

        &.alignleft {
            margin: 15px 20px 20px 0px;
            
            @include media-breakpoint-down(sm) {
                float: none;
            }
        }
    }

}

.tab-container {
    
    @include media-breakpoint-down(sm) {
        padding-left: 0 !important;
    }

    @include media-breakpoint-down(md) {
        padding-left: rem(15px) !important;
    }

    #functionsTab {
        width: calc(100% + 10px);
        transform: translateX(-10px);

        @include media-breakpoint-down(xl) {
            width: calc(100% + 50px);
            transform: translateX(-50px);
        }

        @include media-breakpoint-down(lg) {
            width: 100%;
            transform: none;
        }

        @include media-breakpoint-down(sm) {
            margin-bottom: -30px;
        }
    }

    .nav-pills .nav-link {
        font-size: 20px;
        font-weight: 700;
        color: #606060;
        background: #EAEAEA;
        border-radius: 0;
        border-left: 10px solid transparent;
        padding: 22px 40px 20px 63px;
        margin-bottom: 10px;
        transition: background .2s;
        position: relative;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        @include media-breakpoint-down(xl) {
            font-size: 18px;
            padding: 20px 15px 18px 50px;
            margin-bottom: 16px;
            border-left: 8px solid transparent;
        }

        @include media-breakpoint-down(sm) {
            padding: 17px 15px 15px 50px;
        }

        &:hover,
        &:focus {
            background: #e1e1e1;
        }

        &.active {
            color: $gray-darker;
            background: #fff;
            box-shadow: 0px 0px 30px #e7e8f0;
            border-left: 10px solid $brand-secondary;

            @include media-breakpoint-down(xl) {
                border-left: 8px solid $brand-secondary;
            }

            &:before {
                font-family: 'buw';
                content: "\e900";
                font-size: 13px;
                font-weight: 500;
                color: $brand-secondary;
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                transition: transform .25s;
                position: absolute;
                top: 27px;
                left: 30px;

                @include media-breakpoint-down(xl) {
                    top: 24px;
                    left: 20px;
                }

                @include media-breakpoint-down(sm) {
                    top: 21px;
                }
            }

            &:hover {
                color: $gray-darker;
                background: #fff;
                box-shadow: 0px 0px 30px #e7e8f0;
                border-left: 10px solid $brand-secondary;

                @include media-breakpoint-down(xl) {
                    border-left: 8px solid $brand-secondary;
                }
            }
        }

    }

    .show > .nav-link {
        color: #fff;
        background: linear-gradient(180deg,#c1c1c1,#797979);
    }

}