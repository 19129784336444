.section {
    position: relative;
    padding: rem(125px 0);
    width: 100%;


    &--blog {

        img {
            width: auto;
            height: auto;
            max-width: 100%;

            @include media-breakpoint-down(md) {
                margin: 20px 0;
            }
        }

        .col {
            max-width: 996px;
        }
    }

    &--gray {
        background: #F8F8F8;
    }

    &--title {
        padding: 0;

        .img-block img {

            @include media-breakpoint-down(lg) {
                margin: 15px 0 90px -80px;
                width: calc(100% + 160px);
                height: 420px;
            }

            @include media-breakpoint-down(sm) {
                margin: 15px 0 80px -35px;
                width: calc(100% + 70px);
                height: 260px;
            }
        }

        .img-block_mobile img {

            @include media-breakpoint-down(lg) {
                margin: 15px 0 90px -80px;
                width: calc(100% + 160px);
                height: 420px;
                object-fit: cover;
            }

            @include media-breakpoint-down(sm) {
                margin: 15px 0 80px -35px;
                width: calc(100% + 70px);
                height: 260px;
            }
        }

        p:last-of-type {
            //margin: 0;
        }
    }


    &--content {
        padding: 100px 0;

        @include media-breakpoint-down(lg) {
            padding: 60px 0;
        }

        // No margin for last paragraph
        div[class*=' col'] p:last-of-type {

            @include media-breakpoint-up(lg) {
                //margin: 0;
            }
        }


        figure {
            max-width: 668px;

            @include media-breakpoint-down(lg) {
                max-width: 100%;
            }

            img {
                max-width: 668px;
                width: 100%;
                height: auto;
                //object-fit: none;
            }

            figcaption {
                font-size: 18px;
                line-height: 30px;
                font-style: italic;
                color: #ccc;
                margin-top: 15px;

                @include media-breakpoint-down(lg) {
                    margin-top: 12px;
                }

                @include media-breakpoint-down(sm) {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }

        img {
            width: 100%;
            height: auto;
            //max-width: 500px;
        }

    }

    &--narrow {
        padding: 65px 0;
    }

    &--sm {
        padding: 85px 0;

        @include media-breakpoint-down(sm) {
            padding: 65px 0;
        }
    }

    &--md {
        padding: 105px 0 87px;

        @include media-breakpoint-down(sm) {
            padding: 70px 0 100px;
        }
    }


    //Product Header
    &--product_header {
        background: url(../Images/Assets/products_bg.png) no-repeat;
        background-position: bottom right;
        background-size: auto;
        margin-top: -110px;
        padding: 127px 0 90px;
        position: relative;

        @include media-breakpoint-down(xl) {
            margin-top: -100px;
            padding: 120px 0 30px;
            background-position: top right;
            background-color: #f8f8f8;
        }

        @include media-breakpoint-down(sm) {
            padding: 120px 0 15px;
            background-size: 180%;
        }


        &:after {

            @include media-breakpoint-down(xl) {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                height: 100%;
                width: 100%;
                background: linear-gradient(0deg,#f8f8f8 15%,hsla(0,0%,99%,0));
                z-index: 0;
            }

            @include media-breakpoint-down(sm) {
                background: linear-gradient(0deg,#f8f8f8 45%,hsla(0,0%,99%,0));
            }

            @media screen and (max-width: 400px) {
                top: 280px;
                height: 200px;
            }
        }

        .row {
            z-index: 9;
            position: relative;
        }

        picture {
            display: flex;
            align-items: center;
        }

        img {
            width: auto;
            height: auto;
            max-width: 600px;
            transform: translate(-100px,-30px);

            @include media-breakpoint-down(xxl) {
                max-width: 480px;
                transform: translate(50px,-30px);
            }

            @include media-breakpoint-down(xl) {
                max-width: calc(100% + 70px);
                transform: translate(-20px,-60px);
            }

            @include media-breakpoint-down(md) {
                width: 350px;
                margin-bottom: 20px;
                transform: none;
            }

            @include media-breakpoint-down(sm) {
                max-height: 220px;
                max-width: 100%;
                width: auto;
                margin-bottom: 35px;
            }

            /* Landscape Tablet */
            @media only screen 
            and (min-device-width : 680px)
            and (max-device-height : 840px)
            and (orientation : landscape) {
                width: calc(100% + 100px);
                max-height: 340px;
                transform: translate(-50px,-90px);
            }


            &.teaser-pulled {
                transform: translate(40px,-60px);
                max-height: 540px;
                margin-bottom: -50px;

                @include media-breakpoint-down(xxl) {
                    transform: translate(100px,-60px);
                }

                @include media-breakpoint-down(xl) {
                    transform: none;
                    margin-bottom: 30px;
                }

                @include media-breakpoint-down(md) {
                    max-height: 420px;
                }

                @include media-breakpoint-down(sm) {
                    max-height: 310px;
                }
            }

            &.teaser-xl {

                @include media-breakpoint-up(xl) {
                    min-width: calc(100% + 70px);
                }
            }
        } //img


        .btn ~ .btn {

            @include media-breakpoint-down(lg) {
                margin-left: 0;
            }
        }

    }


    &--img {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @include media-breakpoint-down(sm) {
            padding: 50px 0 30px;
        }
    }


    &--benefits {
        padding: 100px 0;

        @include media-breakpoint-down(lg) {
            padding: 100px 0 60px;
        }

        @include media-breakpoint-down(sm) {
            padding: 75px 0 40px;
        }


        &_block {
            position: absolute;
            right: 0;

            @include media-breakpoint-down(xl) {
                position: relative;
            }

            @include media-breakpoint-down(sm) {
                right: initial;
                max-width: 100%;
                padding-right: calc(var(--bs-gutter-x) / 2);
                padding-left: calc(var(--bs-gutter-x) / 2);
            }
        }

        &_content {
            max-width: 860px;
            margin: 0 100px 0 auto;

            @media screen and (max-width: 1600px) {
                max-width: 770px;
                margin: 0 0 0 auto;
            }

            @include media-breakpoint-down(xl) {
                margin: 0;
            }
        }

        .img-block {
            margin: -200px 0 0 -140px;
            display: block;
            width: auto;

            @include media-breakpoint-up(lg) {
                min-width: 750px;
                min-height: 1110px;
            }

            @media screen and (max-width: 1600px) {
                margin: -200px 0 0 -180px;
            }

            @media screen and (max-width: 1400px) {
                margin: -200px 0 0 -320px;
            }

            @include media-breakpoint-down(xl) {
                margin: -105px 0 80px -80px;
                min-height: auto;
                min-width: auto;
            }

            @include media-breakpoint-down(sm) {
                margin: -105px 0 45px -50px;
            }

            img {
                width: auto;
                height: auto;
                box-shadow: 0px 0px 40px #4d4d4f33;

                @include media-breakpoint-down(xl) {
                    width: calc(100% + 200px)!important;
                }

                @include media-breakpoint-down(sm) {
                    width: calc(100% + 105px)!important
                }
            }

        }
    }


    &--tags {

        @include media-breakpoint-down(sm) {
            padding: 50px 0 40px;
            margin: 30px 0 70px !important;
        }
    }


    &--search {
        padding: 60px 0;
        counter-reset: section;

        article {
            padding: 40px 0;
            border-bottom: 1px solid #ececec;
            max-width: 990px;

            &:first-of-type {
                padding-top:  0;
            }

            h2 {
                margin-bottom: 15px;

                &:before {
                    counter-increment: section;
                    content: "" counter(section) ") ";
                    display: inline-block;
                    margin-right: 15px;
                    color: #c7c7c7;
                }
            }
        }
    }





    &-illustration {   
        background-size: cover !important;
        background-position: top right !important;
        background-repeat: no-repeat !important;

        @include media-breakpoint-down(xl) {
            background: #fff !important;
        }

        &_v2 {
            background-position: top left !important;

            @include media-breakpoint-down(xl) {
                background: #fff !important;
            }
        }
    }





    &#home {
        background: #fff;
        padding: 110px 0 265px;
        background-size: auto !important;
        background-repeat: no-repeat !important;
        background-position: right bottom !important;

        @media screen and (min-width: 2100px) {
            background-position: 80% bottom !important;
        }

        @media screen and (max-width: 1660px) {
            background-position: 160% bottom !important;
        }

        @media screen and (max-width: 1500px) {
            background-position: 200% bottom !important;
        }

        @media screen and (max-width: 1400px) {
            background-position: 160% 90% !important;
            background-size: 70% !important;
        }

        @include media-breakpoint-down(xl) {
            padding-top: 470px;
            background-position: center -30px !important;
            background-size: auto !important;
        }

        @include media-breakpoint-down(sm) {
            padding: 270px 0 250px;
            background-position: center top !important;
            background-size: 140%!important;
        }


        &:after {

            @media screen and (min-width: 2100px) {
                content: "";
                position: absolute;
                right: -50px;
                top: 0;
                width: 19%;
                height: 920px;
                background: linear-gradient(270deg, #ffffff 78%, #fcfcfc00 100%);
            }
        }
    }

} //section


#sectionVideos {


    iframe[src*="youtube"] {

        @include media-breakpoint-up(md) {
            height: 460px;
        }
    }
}