@charset "UTF-8";
/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0)); }

*,
*::before,
*::after {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }

body {
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25; }

hr:not([size]) {
  height: 1px; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw); }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.5rem; } }

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw); }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 2rem; } }

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw); }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.75rem; } }

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw); }
  @media (min-width: 1200px) {
    h4, .h4 {
      font-size: 1.5rem; } }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small, .small {
  font-size: 0.875em; }

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #0d6efd;
  text-decoration: underline; }
  a:hover {
    color: #0a58ca; }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }
  select:disabled {
    opacity: 1; }

[list]::-webkit-calendar-picker-indicator {
  display: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit; }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem; } }
  legend + * {
    clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 5rem; } }

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 4.5rem; } }

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 4rem; } }

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.5rem; } }

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-5 {
      font-size: 3rem; } }

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-6 {
      font-size: 2.5rem; } }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 0.875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }
  .blockquote > :last-child {
    margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px; } }

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) / 2);
    padding-left: calc(var(--bs-gutter-x) / 2);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem; } }

.form-label {
  margin-bottom: 0.5rem; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem; }

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control[type="file"] {
    overflow: hidden; }
    .form-control[type="file"]:not(:disabled):not([readonly]) {
      cursor: pointer; }
  .form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .form-control::-webkit-date-and-time-value {
    height: 1.5em; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }
  .form-control::file-selector-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: #212529;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::file-selector-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #dde0e3; }
  .form-control::-webkit-file-upload-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: #212529;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::-webkit-file-upload-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #dde0e3; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }
  .form-control-sm::file-selector-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem; }
  .form-control-sm::-webkit-file-upload-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem; }

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }
  .form-control-lg::file-selector-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }
  .form-control-lg::-webkit-file-upload-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px); }

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px); }

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px); }

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0.375rem; }
  .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer; }
  .form-control-color::-moz-color-swatch {
    height: 1.5em;
    border-radius: 0.25rem; }
  .form-control-color::-webkit-color-swatch {
    height: 1.5em;
    border-radius: 0.25rem; }

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .form-select:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .form-select[multiple], .form-select[size]:not([size="1"]) {
    padding-right: 0.75rem;
    background-image: none; }
  .form-select:disabled {
    background-color: #e9ecef; }
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #212529; }

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem; }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.5em; }

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact; }
  .form-check-input[type="checkbox"] {
    border-radius: 0.25em; }
  .form-check-input[type="radio"] {
    border-radius: 50%; }
  .form-check-input:active {
    filter: brightness(90%); }
  .form-check-input:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .form-check-input:checked {
    background-color: #0d6efd;
    border-color: #0d6efd; }
    .form-check-input:checked[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }
    .form-check-input:checked[type="radio"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #0d6efd;
    border-color: #0d6efd;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5; }
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    opacity: 0.5; }

.form-switch {
  padding-left: 2.5em; }
  .form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-switch .form-check-input {
        transition: none; } }
    .form-switch .form-check-input:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e"); }
    .form-switch .form-check-input:checked {
      background-position: right center;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }
  .btn-check[disabled] + .btn, .btn-check:disabled + .btn {
    pointer-events: none;
    filter: none;
    opacity: 0.65; }

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .form-range:focus {
    outline: 0; }
    .form-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
    .form-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .form-range::-moz-focus-outer {
    border: 0; }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-webkit-slider-thumb {
        transition: none; } }
    .form-range::-webkit-slider-thumb:active {
      background-color: #b6d4fe; }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-moz-range-thumb {
        transition: none; } }
    .form-range::-moz-range-thumb:active {
      background-color: #b6d4fe; }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range:disabled {
    pointer-events: none; }
    .form-range:disabled::-webkit-slider-thumb {
      background-color: #adb5bd; }
    .form-range:disabled::-moz-range-thumb {
      background-color: #adb5bd; }

.form-floating {
  position: relative; }
  .form-floating > .form-control,
  .form-floating > .form-select {
    height: calc(3.5rem + 2px);
    padding: 1rem 0.75rem; }
  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem 0.75rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-floating > label {
        transition: none; } }
  .form-floating > .form-control::placeholder {
    color: transparent; }
  .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .form-floating > .form-control:-webkit-autofill {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-select ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control:-webkit-autofill ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }
  .input-group > .form-control:focus,
  .input-group > .form-select:focus {
    z-index: 3; }
  .input-group .btn {
    position: relative;
    z-index: 2; }
    .input-group .btn:focus {
      z-index: 3; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem; }

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #198754;
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: #198754;
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25); }

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754; }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: #198754; }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25); }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #198754; }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid, .was-validated
.input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 3; }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: #dc3545; }
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #dc3545; }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid, .was-validated
.input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 3; }

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529; }
  .btn-check:focus + .btn, .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .btn:disabled, .btn.disabled,
  fieldset:disabled .btn {
    pointer-events: none;
    opacity: 0.65; }

.btn-primary {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca; }
  .btn-check:focus + .btn-primary, .btn-primary:focus {
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca;
    box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5); }
  .btn-check:checked + .btn-primary,
  .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0a58ca;
    border-color: #0a53be; }
    .btn-check:checked + .btn-primary:focus,
    .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5); }
  .btn-primary:disabled, .btn-primary.disabled {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd; }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5c636a;
    border-color: #565e64; }
  .btn-check:focus + .btn-secondary, .btn-secondary:focus {
    color: #fff;
    background-color: #5c636a;
    border-color: #565e64;
    box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5); }
  .btn-check:checked + .btn-secondary,
  .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #565e64;
    border-color: #51585e; }
    .btn-check:checked + .btn-secondary:focus,
    .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5); }
  .btn-secondary:disabled, .btn-secondary.disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }

.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754; }
  .btn-success:hover {
    color: #fff;
    background-color: #157347;
    border-color: #146c43; }
  .btn-check:focus + .btn-success, .btn-success:focus {
    color: #fff;
    background-color: #157347;
    border-color: #146c43;
    box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5); }
  .btn-check:checked + .btn-success,
  .btn-check:active + .btn-success, .btn-success:active, .btn-success.active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #146c43;
    border-color: #13653f; }
    .btn-check:checked + .btn-success:focus,
    .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5); }
  .btn-success:disabled, .btn-success.disabled {
    color: #fff;
    background-color: #198754;
    border-color: #198754; }

.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0; }
  .btn-info:hover {
    color: #000;
    background-color: #31d2f2;
    border-color: #25cff2; }
  .btn-check:focus + .btn-info, .btn-info:focus {
    color: #000;
    background-color: #31d2f2;
    border-color: #25cff2;
    box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5); }
  .btn-check:checked + .btn-info,
  .btn-check:active + .btn-info, .btn-info:active, .btn-info.active,
  .show > .btn-info.dropdown-toggle {
    color: #000;
    background-color: #3dd5f3;
    border-color: #25cff2; }
    .btn-check:checked + .btn-info:focus,
    .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5); }
  .btn-info:disabled, .btn-info.disabled {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0; }

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #000;
    background-color: #ffca2c;
    border-color: #ffc720; }
  .btn-check:focus + .btn-warning, .btn-warning:focus {
    color: #000;
    background-color: #ffca2c;
    border-color: #ffc720;
    box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5); }
  .btn-check:checked + .btn-warning,
  .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active,
  .show > .btn-warning.dropdown-toggle {
    color: #000;
    background-color: #ffcd39;
    border-color: #ffc720; }
    .btn-check:checked + .btn-warning:focus,
    .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5); }
  .btn-warning:disabled, .btn-warning.disabled {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107; }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #bb2d3b;
    border-color: #b02a37; }
  .btn-check:focus + .btn-danger, .btn-danger:focus {
    color: #fff;
    background-color: #bb2d3b;
    border-color: #b02a37;
    box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5); }
  .btn-check:checked + .btn-danger,
  .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #b02a37;
    border-color: #a52834; }
    .btn-check:checked + .btn-danger:focus,
    .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5); }
  .btn-danger:disabled, .btn-danger.disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }

.btn-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #000;
    background-color: #f9fafb;
    border-color: #f9fafb; }
  .btn-check:focus + .btn-light, .btn-light:focus {
    color: #000;
    background-color: #f9fafb;
    border-color: #f9fafb;
    box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5); }
  .btn-check:checked + .btn-light,
  .btn-check:active + .btn-light, .btn-light:active, .btn-light.active,
  .show > .btn-light.dropdown-toggle {
    color: #000;
    background-color: #f9fafb;
    border-color: #f9fafb; }
    .btn-check:checked + .btn-light:focus,
    .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5); }
  .btn-light:disabled, .btn-light.disabled {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }

.btn-dark {
  color: #fff;
  background-color: #212529;
  border-color: #212529; }
  .btn-dark:hover {
    color: #fff;
    background-color: #1c1f23;
    border-color: #1a1e21; }
  .btn-check:focus + .btn-dark, .btn-dark:focus {
    color: #fff;
    background-color: #1c1f23;
    border-color: #1a1e21;
    box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5); }
  .btn-check:checked + .btn-dark,
  .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1a1e21;
    border-color: #191c1f; }
    .btn-check:checked + .btn-dark:focus,
    .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5); }
  .btn-dark:disabled, .btn-dark.disabled {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }

.btn-outline-primary {
  color: #0d6efd;
  border-color: #0d6efd; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd; }
  .btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5); }
  .btn-check:checked + .btn-outline-primary,
  .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd; }
    .btn-check:checked + .btn-outline-primary:focus,
    .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5); }
  .btn-outline-primary:disabled, .btn-outline-primary.disabled {
    color: #0d6efd;
    background-color: transparent; }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
    box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5); }
  .btn-check:checked + .btn-outline-secondary,
  .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-check:checked + .btn-outline-secondary:focus,
    .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
    color: #6c757d;
    background-color: transparent; }

.btn-outline-success {
  color: #198754;
  border-color: #198754; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #198754;
    border-color: #198754; }
  .btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5); }
  .btn-check:checked + .btn-outline-success,
  .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
    color: #fff;
    background-color: #198754;
    border-color: #198754; }
    .btn-check:checked + .btn-outline-success:focus,
    .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5); }
  .btn-outline-success:disabled, .btn-outline-success.disabled {
    color: #198754;
    background-color: transparent; }

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0; }
  .btn-outline-info:hover {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0; }
  .btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5); }
  .btn-check:checked + .btn-outline-info,
  .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0; }
    .btn-check:checked + .btn-outline-info:focus,
    .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5); }
  .btn-outline-info:disabled, .btn-outline-info.disabled {
    color: #0dcaf0;
    background-color: transparent; }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5); }
  .btn-check:checked + .btn-outline-warning,
  .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-check:checked + .btn-outline-warning:focus,
    .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning:disabled, .btn-outline-warning.disabled {
    color: #ffc107;
    background-color: transparent; }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5); }
  .btn-check:checked + .btn-outline-danger,
  .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-check:checked + .btn-outline-danger:focus,
    .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger:disabled, .btn-outline-danger.disabled {
    color: #dc3545;
    background-color: transparent; }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
    box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5); }
  .btn-check:checked + .btn-outline-light,
  .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-check:checked + .btn-outline-light:focus,
    .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light:disabled, .btn-outline-light.disabled {
    color: #f8f9fa;
    background-color: transparent; }

.btn-outline-dark {
  color: #212529;
  border-color: #212529; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }
  .btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
    box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5); }
  .btn-check:checked + .btn-outline-dark,
  .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }
    .btn-check:checked + .btn-outline-dark:focus,
    .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5); }
  .btn-outline-dark:disabled, .btn-outline-dark.disabled {
    color: #212529;
    background-color: transparent; }

.btn-link {
  font-weight: 400;
  color: #0d6efd;
  text-decoration: underline; }
  .btn-link:hover {
    color: #0a58ca; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d; }

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }
  .dropdown-menu[data-bs-popper] {
    left: 0;
    margin-top: 0.125rem; }

.dropdown-menu-start {
  --bs-position: start; }
  .dropdown-menu-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */; }

.dropdown-menu-end {
  --bs-position: end; }
  .dropdown-menu-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */; }

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start; }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto /* rtl:ignore */;
      left: 0 /* rtl:ignore */; }
  .dropdown-menu-sm-end {
    --bs-position: end; }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0 /* rtl:ignore */;
      left: auto /* rtl:ignore */; } }

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start; }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto /* rtl:ignore */;
      left: 0 /* rtl:ignore */; }
  .dropdown-menu-md-end {
    --bs-position: end; }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0 /* rtl:ignore */;
      left: auto /* rtl:ignore */; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start; }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto /* rtl:ignore */;
      left: 0 /* rtl:ignore */; }
  .dropdown-menu-lg-end {
    --bs-position: end; }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0 /* rtl:ignore */;
      left: auto /* rtl:ignore */; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start; }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto /* rtl:ignore */;
      left: 0 /* rtl:ignore */; }
  .dropdown-menu-xl-end {
    --bs-position: end; }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0 /* rtl:ignore */;
      left: auto /* rtl:ignore */; } }

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start; }
    .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto /* rtl:ignore */;
      left: 0 /* rtl:ignore */; }
  .dropdown-menu-xxl-end {
    --bs-position: end; }
    .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0 /* rtl:ignore */;
      left: auto /* rtl:ignore */; } }

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%; }
  .dropend .dropdown-menu[data-bs-popper] {
    margin-top: 0;
    margin-left: 0.125rem; }

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropend .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-toggle::after {
  vertical-align: 0; }

.dropstart .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto; }
  .dropstart .dropdown-menu[data-bs-popper] {
    margin-top: 0;
    margin-right: 0.125rem; }

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropstart .dropdown-toggle::after {
  display: none; }

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropstart .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15); }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #1e2125;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #0d6efd; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529; }

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15); }
  .dropdown-menu-dark .dropdown-item {
    color: #dee2e6; }
    .dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
      color: #fff;
      background-color: rgba(255, 255, 255, 0.15); }
    .dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
      color: #fff;
      background-color: #0d6efd; }
    .dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
      color: #adb5bd; }
  .dropdown-menu-dark .dropdown-divider {
    border-color: rgba(0, 0, 0, 0.15); }
  .dropdown-menu-dark .dropdown-item-text {
    color: #dee2e6; }
  .dropdown-menu-dark .dropdown-header {
    color: #adb5bd; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none; } }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: none;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6;
      isolation: isolate; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0d6efd; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }
  .navbar > .container,
  .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static; }

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none; } }
  .navbar-toggler:hover {
    text-decoration: none; }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0.25rem; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto; }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55); }
  .navbar-light .navbar-text a,
  .navbar-light .navbar-text a:hover,
  .navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55); }
  .navbar-dark .navbar-text a,
  .navbar-dark .navbar-text a:hover,
  .navbar-dark .navbar-text a:focus {
    color: #fff; }

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      transition: none; } }
  .accordion-button:not(.collapsed) {
    color: #0c63e4;
    background-color: #e7f1ff;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125); }
    .accordion-button:not(.collapsed)::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      transform: rotate(180deg); }
  .accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform 0.2s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .accordion-button::after {
        transition: none; } }
  .accordion-button:hover {
    z-index: 2; }
  .accordion-button:focus {
    z-index: 3;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }

.accordion-header {
  margin-bottom: 0; }

.accordion-item {
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .accordion-item:first-of-type {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .accordion-item:first-of-type .accordion-button {
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
  .accordion-item:last-of-type {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
    .accordion-item:last-of-type .accordion-button.collapsed {
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
    .accordion-item:last-of-type .accordion-collapse {
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem; }

.accordion-body {
  padding: 1rem 1.25rem; }

.accordion-flush .accordion-collapse {
  border-width: 0; }

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .accordion-flush .accordion-item:first-child {
    border-top: 0; }
  .accordion-flush .accordion-item:last-child {
    border-bottom: 0; }
  .accordion-flush .accordion-item .accordion-button {
    border-radius: 0; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-numbered {
  list-style-type: none;
  counter-reset: section; }
  .list-group-numbered > li::before {
    content: counters(section, ".") ". ";
    counter-increment: section; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #084298;
  background-color: #cfe2ff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #084298;
    background-color: #bacbe6; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #084298;
    border-color: #084298; }

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #41464b;
    background-color: #cbccce; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #41464b;
    border-color: #41464b; }

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #0f5132;
    background-color: #bcd0c7; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #0f5132;
    border-color: #0f5132; }

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #055160;
    background-color: #badce3; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #055160;
    border-color: #055160; }

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #664d03;
    background-color: #e6dbb9; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #664d03;
    border-color: #664d03; }

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #842029;
    background-color: #dfc2c4; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #842029;
    border-color: #842029; }

.list-group-item-light {
  color: #636464;
  background-color: #fefefe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #636464;
    background-color: #e5e5e5; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #636464;
    border-color: #636464; }

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #141619;
    background-color: #bebebf; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #141619;
    border-color: #141619; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-50 {
  top: 50% !important; }

.top-100 {
  top: 100% !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-100 {
  bottom: 100% !important; }

.start-0 {
  left: 0 !important; }

.start-50 {
  left: 50% !important; }

.start-100 {
  left: 100% !important; }

.end-0 {
  right: 0 !important; }

.end-50 {
  right: 50% !important; }

.end-100 {
  right: 100% !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: 1px solid #dee2e6 !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: 1px solid #dee2e6 !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #0d6efd !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #198754 !important; }

.border-info {
  border-color: #0dcaf0 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #212529 !important; }

.border-white {
  border-color: #fff !important; }

.border-1 {
  border-width: 1px !important; }

.border-2 {
  border-width: 2px !important; }

.border-3 {
  border-width: 3px !important; }

.border-4 {
  border-width: 4px !important; }

.border-5 {
  border-width: 5px !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.gap-0 {
  gap: 0 !important; }

.gap-1 {
  gap: 0.25rem !important; }

.gap-2 {
  gap: 0.5rem !important; }

.gap-3 {
  gap: 1rem !important; }

.gap-4 {
  gap: 1.5rem !important; }

.gap-5 {
  gap: 3rem !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.25rem !important; }

.me-2 {
  margin-right: 0.5rem !important; }

.me-3 {
  margin-right: 1rem !important; }

.me-4 {
  margin-right: 1.5rem !important; }

.me-5 {
  margin-right: 3rem !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.25rem !important; }

.ms-2 {
  margin-left: 0.5rem !important; }

.ms-3 {
  margin-left: 1rem !important; }

.ms-4 {
  margin-left: 1.5rem !important; }

.ms-5 {
  margin-left: 3rem !important; }

.ms-auto {
  margin-left: auto !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.25rem !important; }

.pe-2 {
  padding-right: 0.5rem !important; }

.pe-3 {
  padding-right: 1rem !important; }

.pe-4 {
  padding-right: 1.5rem !important; }

.pe-5 {
  padding-right: 3rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.25rem !important; }

.ps-2 {
  padding-left: 0.5rem !important; }

.ps-3 {
  padding-left: 1rem !important; }

.ps-4 {
  padding-left: 1.5rem !important; }

.ps-5 {
  padding-left: 3rem !important; }

.font-monospace {
  font-family: var(--bs-font-monospace) !important; }

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important; }

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important; }

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important; }

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important; }

.fs-5 {
  font-size: 1.25rem !important; }

.fs-6 {
  font-size: 1rem !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-lighter {
  font-weight: lighter !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-bold {
  font-weight: 700 !important; }

.fw-bolder {
  font-weight: bolder !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.5 !important; }

.lh-lg {
  line-height: 2 !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

/* rtl:end:remove */
.text-primary {
  color: #0d6efd !important; }

.text-secondary {
  color: #6c757d !important; }

.text-success {
  color: #198754 !important; }

.text-info {
  color: #0dcaf0 !important; }

.text-warning {
  color: #ffc107 !important; }

.text-danger {
  color: #dc3545 !important; }

.text-light {
  color: #f8f9fa !important; }

.text-dark {
  color: #212529 !important; }

.text-white {
  color: #fff !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  color: inherit !important; }

.bg-primary {
  background-color: #0d6efd !important; }

.bg-secondary {
  background-color: #6c757d !important; }

.bg-success {
  background-color: #198754 !important; }

.bg-info {
  background-color: #0dcaf0 !important; }

.bg-warning {
  background-color: #ffc107 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

.bg-dark {
  background-color: #212529 !important; }

.bg-body {
  background-color: #fff !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: 0.2rem !important; }

.rounded-2 {
  border-radius: 0.25rem !important; }

.rounded-3 {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-sm-0 {
    gap: 0 !important; }
  .gap-sm-1 {
    gap: 0.25rem !important; }
  .gap-sm-2 {
    gap: 0.5rem !important; }
  .gap-sm-3 {
    gap: 1rem !important; }
  .gap-sm-4 {
    gap: 1.5rem !important; }
  .gap-sm-5 {
    gap: 3rem !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.25rem !important; }
  .me-sm-2 {
    margin-right: 0.5rem !important; }
  .me-sm-3 {
    margin-right: 1rem !important; }
  .me-sm-4 {
    margin-right: 1.5rem !important; }
  .me-sm-5 {
    margin-right: 3rem !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.25rem !important; }
  .ms-sm-2 {
    margin-left: 0.5rem !important; }
  .ms-sm-3 {
    margin-left: 1rem !important; }
  .ms-sm-4 {
    margin-left: 1.5rem !important; }
  .ms-sm-5 {
    margin-left: 3rem !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.25rem !important; }
  .pe-sm-2 {
    padding-right: 0.5rem !important; }
  .pe-sm-3 {
    padding-right: 1rem !important; }
  .pe-sm-4 {
    padding-right: 1.5rem !important; }
  .pe-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.25rem !important; }
  .ps-sm-2 {
    padding-left: 0.5rem !important; }
  .ps-sm-3 {
    padding-left: 1rem !important; }
  .ps-sm-4 {
    padding-left: 1.5rem !important; }
  .ps-sm-5 {
    padding-left: 3rem !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-md-0 {
    gap: 0 !important; }
  .gap-md-1 {
    gap: 0.25rem !important; }
  .gap-md-2 {
    gap: 0.5rem !important; }
  .gap-md-3 {
    gap: 1rem !important; }
  .gap-md-4 {
    gap: 1.5rem !important; }
  .gap-md-5 {
    gap: 3rem !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.25rem !important; }
  .me-md-2 {
    margin-right: 0.5rem !important; }
  .me-md-3 {
    margin-right: 1rem !important; }
  .me-md-4 {
    margin-right: 1.5rem !important; }
  .me-md-5 {
    margin-right: 3rem !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.25rem !important; }
  .ms-md-2 {
    margin-left: 0.5rem !important; }
  .ms-md-3 {
    margin-left: 1rem !important; }
  .ms-md-4 {
    margin-left: 1.5rem !important; }
  .ms-md-5 {
    margin-left: 3rem !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.25rem !important; }
  .pe-md-2 {
    padding-right: 0.5rem !important; }
  .pe-md-3 {
    padding-right: 1rem !important; }
  .pe-md-4 {
    padding-right: 1.5rem !important; }
  .pe-md-5 {
    padding-right: 3rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.25rem !important; }
  .ps-md-2 {
    padding-left: 0.5rem !important; }
  .ps-md-3 {
    padding-left: 1rem !important; }
  .ps-md-4 {
    padding-left: 1.5rem !important; }
  .ps-md-5 {
    padding-left: 3rem !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-lg-0 {
    gap: 0 !important; }
  .gap-lg-1 {
    gap: 0.25rem !important; }
  .gap-lg-2 {
    gap: 0.5rem !important; }
  .gap-lg-3 {
    gap: 1rem !important; }
  .gap-lg-4 {
    gap: 1.5rem !important; }
  .gap-lg-5 {
    gap: 3rem !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.25rem !important; }
  .me-lg-2 {
    margin-right: 0.5rem !important; }
  .me-lg-3 {
    margin-right: 1rem !important; }
  .me-lg-4 {
    margin-right: 1.5rem !important; }
  .me-lg-5 {
    margin-right: 3rem !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.25rem !important; }
  .ms-lg-2 {
    margin-left: 0.5rem !important; }
  .ms-lg-3 {
    margin-left: 1rem !important; }
  .ms-lg-4 {
    margin-left: 1.5rem !important; }
  .ms-lg-5 {
    margin-left: 3rem !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.25rem !important; }
  .pe-lg-2 {
    padding-right: 0.5rem !important; }
  .pe-lg-3 {
    padding-right: 1rem !important; }
  .pe-lg-4 {
    padding-right: 1.5rem !important; }
  .pe-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.25rem !important; }
  .ps-lg-2 {
    padding-left: 0.5rem !important; }
  .ps-lg-3 {
    padding-left: 1rem !important; }
  .ps-lg-4 {
    padding-left: 1.5rem !important; }
  .ps-lg-5 {
    padding-left: 3rem !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xl-0 {
    gap: 0 !important; }
  .gap-xl-1 {
    gap: 0.25rem !important; }
  .gap-xl-2 {
    gap: 0.5rem !important; }
  .gap-xl-3 {
    gap: 1rem !important; }
  .gap-xl-4 {
    gap: 1.5rem !important; }
  .gap-xl-5 {
    gap: 3rem !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.25rem !important; }
  .me-xl-2 {
    margin-right: 0.5rem !important; }
  .me-xl-3 {
    margin-right: 1rem !important; }
  .me-xl-4 {
    margin-right: 1.5rem !important; }
  .me-xl-5 {
    margin-right: 3rem !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.25rem !important; }
  .ms-xl-2 {
    margin-left: 0.5rem !important; }
  .ms-xl-3 {
    margin-left: 1rem !important; }
  .ms-xl-4 {
    margin-left: 1.5rem !important; }
  .ms-xl-5 {
    margin-left: 3rem !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.25rem !important; }
  .pe-xl-2 {
    padding-right: 0.5rem !important; }
  .pe-xl-3 {
    padding-right: 1rem !important; }
  .pe-xl-4 {
    padding-right: 1.5rem !important; }
  .pe-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.25rem !important; }
  .ps-xl-2 {
    padding-left: 0.5rem !important; }
  .ps-xl-3 {
    padding-left: 1rem !important; }
  .ps-xl-4 {
    padding-left: 1.5rem !important; }
  .ps-xl-5 {
    padding-left: 3rem !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important; }
  .float-xxl-end {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xxl-0 {
    gap: 0 !important; }
  .gap-xxl-1 {
    gap: 0.25rem !important; }
  .gap-xxl-2 {
    gap: 0.5rem !important; }
  .gap-xxl-3 {
    gap: 1rem !important; }
  .gap-xxl-4 {
    gap: 1.5rem !important; }
  .gap-xxl-5 {
    gap: 3rem !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.25rem !important; }
  .mt-xxl-2 {
    margin-top: 0.5rem !important; }
  .mt-xxl-3 {
    margin-top: 1rem !important; }
  .mt-xxl-4 {
    margin-top: 1.5rem !important; }
  .mt-xxl-5 {
    margin-top: 3rem !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1 {
    margin-right: 0.25rem !important; }
  .me-xxl-2 {
    margin-right: 0.5rem !important; }
  .me-xxl-3 {
    margin-right: 1rem !important; }
  .me-xxl-4 {
    margin-right: 1.5rem !important; }
  .me-xxl-5 {
    margin-right: 3rem !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xxl-3 {
    margin-bottom: 1rem !important; }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-5 {
    margin-bottom: 3rem !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1 {
    margin-left: 0.25rem !important; }
  .ms-xxl-2 {
    margin-left: 0.5rem !important; }
  .ms-xxl-3 {
    margin-left: 1rem !important; }
  .ms-xxl-4 {
    margin-left: 1.5rem !important; }
  .ms-xxl-5 {
    margin-left: 3rem !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.25rem !important; }
  .pt-xxl-2 {
    padding-top: 0.5rem !important; }
  .pt-xxl-3 {
    padding-top: 1rem !important; }
  .pt-xxl-4 {
    padding-top: 1.5rem !important; }
  .pt-xxl-5 {
    padding-top: 3rem !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1 {
    padding-right: 0.25rem !important; }
  .pe-xxl-2 {
    padding-right: 0.5rem !important; }
  .pe-xxl-3 {
    padding-right: 1rem !important; }
  .pe-xxl-4 {
    padding-right: 1.5rem !important; }
  .pe-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xxl-3 {
    padding-bottom: 1rem !important; }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-5 {
    padding-bottom: 3rem !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1 {
    padding-left: 0.25rem !important; }
  .ps-xxl-2 {
    padding-left: 0.5rem !important; }
  .ps-xxl-3 {
    padding-left: 1rem !important; }
  .ps-xxl-4 {
    padding-left: 1.5rem !important; }
  .ps-xxl-5 {
    padding-left: 3rem !important; }
  .text-xxl-start {
    text-align: left !important; }
  .text-xxl-end {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important; }
  .fs-2 {
    font-size: 2rem !important; }
  .fs-3 {
    font-size: 1.75rem !important; }
  .fs-4 {
    font-size: 1.5rem !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

/* Custom CSS */
/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(""), url("../webfonts/lato-v17-latin-regular.woff2") format("woff2"), url("../webfonts/lato-v17-latin-regular.woff") format("woff"); }

/* lato-700 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(""), url("../webfonts/lato-v17-latin-700.woff2") format("woff2"), url("../webfonts/lato-v17-latin-700.woff") format("woff"); }

/* lato-900 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local(""), url("../webfonts/lato-v17-latin-900.woff2") format("woff2"), url("../webfonts/lato-v17-latin-900.woff") format("woff"); }

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(""), url("../webfonts/roboto-v27-latin-regular.woff2") format("woff2"), url("../webfonts/roboto-v27-latin-regular.woff") format("woff"); }

/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local(""), url("../webfonts/roboto-v27-latin-500.woff2") format("woff2"), url("../webfonts/roboto-v27-latin-500.woff") format("woff"); }

/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(""), url("../webfonts/roboto-v27-latin-700.woff2") format("woff2"), url("../webfonts/roboto-v27-latin-700.woff") format("woff"); }

.wpcf7 .screen-reader-response {
  position: absolute;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  width: 1px;
  margin: 0;
  padding: 0;
  border: 0; }

.wpcf7 form .wpcf7-response-output {
  margin: 2em 0.5em 1em;
  padding: 0.2em 1em;
  border: 2px solid #00a0d2;
  /* Blue */ }

.wpcf7 form.init .wpcf7-response-output,
.wpcf7 form.resetting .wpcf7-response-output,
.wpcf7 form.submitting .wpcf7-response-output {
  display: none; }

.wpcf7 form.sent .wpcf7-response-output {
  border-color: #46b450;
  /* Green */ }

.wpcf7 form.failed .wpcf7-response-output,
.wpcf7 form.aborted .wpcf7-response-output {
  border-color: #dc3232;
  /* Red */ }

.wpcf7 form.spam .wpcf7-response-output {
  border-color: #f56e28;
  /* Orange */ }

.wpcf7 form.invalid .wpcf7-response-output,
.wpcf7 form.unaccepted .wpcf7-response-output {
  border-color: #ffb900;
  /* Yellow */ }

.wpcf7-form-control-wrap {
  position: relative; }

.wpcf7-not-valid-tip {
  color: #dc3232;
  /* Red */
  font-size: 1em;
  font-weight: normal;
  display: block; }

.use-floating-validation-tip .wpcf7-not-valid-tip {
  position: relative;
  top: -2ex;
  left: 1em;
  z-index: 100;
  border: 1px solid #dc3232;
  background: #fff;
  padding: .2em .8em;
  width: 24em; }

.wpcf7-list-item {
  display: inline-block;
  margin: 0 0 0 1em; }

.wpcf7-list-item-label::before,
.wpcf7-list-item-label::after {
  content: " "; }

.wpcf7 .ajax-loader {
  visibility: hidden;
  display: inline-block;
  background-color: #23282d;
  /* Dark Gray 800 */
  opacity: 0.75;
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 100%;
  padding: 0;
  margin: 0 24px;
  position: relative; }

.wpcf7 form.submitting .ajax-loader {
  visibility: visible; }

.wpcf7 .ajax-loader::before {
  content: '';
  position: absolute;
  background-color: #fbfbfc;
  /* Light Gray 100 */
  top: 4px;
  left: 4px;
  width: 6px;
  height: 6px;
  border: none;
  border-radius: 100%;
  transform-origin: 8px 8px;
  animation-name: spin;
  animation-duration: 1000ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite; }

@media (prefers-reduced-motion: reduce) {
  .wpcf7 .ajax-loader::before {
    animation-name: blink;
    animation-duration: 2000ms; } }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@keyframes blink {
  from {
    opacity: 0; }
  50% {
    opacity: 1; }
  to {
    opacity: 0; } }

.wpcf7 input[type="file"] {
  cursor: pointer; }

.wpcf7 input[type="file"]:disabled {
  cursor: default; }

.wpcf7 .wpcf7-submit:disabled {
  cursor: not-allowed; }

.wpcf7 input[type="url"],
.wpcf7 input[type="email"],
.wpcf7 input[type="tel"] {
  direction: ltr; }

html, body {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #4d4d4f;
  background: #fff;
  overflow-x: hidden;
  margin: 0;
  word-break: break-word;
  animation: fadeIn 1.4s; }
  @media (max-width: 575.98px) {
    html, body {
      height: 100vh; } }

@keyframes fadeIn {
  0%, 70% {
    opacity: 0; }
  to {
    opacity: 1; } }
  @media (max-width: 767.98px) {
    html.navbar-open, body.navbar-open {
      overflow: hidden; } }
  html.no-scroll, body.no-scroll {
    overflow: hidden; }

.container {
  position: relative;
  max-width: 84rem !important; }
  @media (max-width: 1199.98px) {
    .container {
      padding: 0 80px; } }
  @media (max-width: 767.98px) {
    .container {
      padding: 0 35px; } }
  @media (max-width: 575.98px) {
    .container {
      padding: 0 33px 0 34px; } }
  @media screen and (max-width: 365px) {
    .container {
      padding: 0 28px 0; } }
  .container-flex {
    display: flex;
    flex-direction: column; }

@media (max-width: 575.98px) {
  main {
    margin-top: 70px; } }

main.bg-img {
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain; }

#breadcrumbs {
  font-size: 18px;
  padding: 50px 0 35px;
  z-index: 99;
  position: relative; }
  @media (max-width: 991.98px) {
    #breadcrumbs {
      font-size: 17px;
      padding: 35px 0 35px; } }
  @media (max-width: 575.98px) {
    #breadcrumbs {
      font-size: 16px;
      padding: 25px 0 30px;
      width: calc(100% + 20px); } }
  #breadcrumbs .breadcrumbArrow:after {
    font-family: 'buw';
    content: "\e904";
    font-size: 13px;
    font-weight: 500;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    transition: color .1s,transform .4s;
    position: relative;
    margin: 0 5px; }
    @media (max-width: 575.98px) {
      #breadcrumbs .breadcrumbArrow:after {
        font-size: 12px;
        margin: 0 3px; } }
  #breadcrumbs .breadcrumb_last {
    color: #999999; }

.home #breadcrumbs {
  display: none; }

.align-center {
  display: flex;
  align-items: center;
  justify-content: center; }

.no-padding {
  padding: 0 !important; }

.block-center {
  margin: 0 auto !important;
  display: block !important; }

.spacer {
  min-width: 130px;
  display: inline-block; }
  .spacer-xxl {
    min-width: 280px; }
  .spacer-xl {
    min-width: 200px; }
  .spacer-md {
    min-width: 160px; }
  .spacer-sm {
    min-width: 100px; }

.m-md {
  margin: 50px 0; }

.m-xl {
  margin: 100px 0; }

.mb-xxs {
  margin-bottom: 6px !important; }

.mb-xs {
  margin-bottom: 13px !important; }

.mb-sm {
  margin-bottom: 30px; }

.mb-md {
  margin-bottom: 50px; }

.mb-lg {
  margin-bottom: 80px; }
  @media (max-width: 1199.98px) {
    .mb-lg {
      margin-bottom: 40px; } }
  @media (max-width: 575.98px) {
    .mb-lg {
      margin-bottom: 20px; } }
  @media (max-width: 1199.98px) {
    .mb-lg_mobile {
      margin-bottom: 80px !important; } }

.mb-xl {
  margin-bottom: 120px; }
  @media (max-width: 575.98px) {
    .mb-xl {
      margin-bottom: 60px; } }

.mt-sm {
  margin-top: 30px; }

.mt-md {
  margin-top: 45px !important; }
  @media (max-width: 767.98px) {
    .mt-md {
      margin: 25px 0 !important; } }

@media (min-width: 1200px) {
  .mt-xl {
    margin-top: 300px !important; } }

@media (min-width: 992px) {
  .mr-sm {
    margin-right: 50px !important; } }

@media (min-width: 992px) {
  .mr-md {
    margin-right: 90px !important; } }

@media (min-width: 992px) {
  .mr-xl {
    margin-right: 145px !important; } }

@media (min-width: 992px) {
  .ml-auto {
    margin-left: auto !important; } }

.pb-sm {
  padding-bottom: 50px !important; }

.pb-md {
  padding-bottom: 80px !important; }
  @media (max-width: 575.98px) {
    .pb-md {
      padding-bottom: 55px !important; } }

.pb-xl {
  padding-bottom: 120px !important; }

.pb-xxl {
  padding-bottom: 220px !important; }
  @media (max-width: 1199.98px) {
    .pb-xxl {
      padding-bottom: 70px !important; } }

@media (max-width: 1199.98px) {
  .section--product_header ~ section .row > div > .pl-xxl {
    padding: 0;
    margin-bottom: 40px; } }

.pt-sm {
  padding-top: 50px !important; }

.pt-md {
  padding-top: 80px !important; }

@media (min-width: 992px) {
  .pl-xxl {
    padding-left: 140px; } }

@media (min-width: 992px) {
  .pr-md {
    padding-right: 90px; } }

@media screen and (min-width: 1600px) {
  .pull-left_lg {
    transform: translateX(-100px); } }

.no-pmb p:last-of-type {
  margin-bottom: 0; }

@media (min-width: 1200px) {
  .col-max_sm {
    max-width: 735px; } }

@media (min-width: 1200px) {
  .col-max_md {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .col-max_lg {
    max-width: 955px; } }

@media (max-width: 1199.98px) {
  .text-left_sm {
    text-align: left !important; } }

@media (max-width: 1199.98px) {
  .layout-block-sm {
    max-width: 980px;
    margin: 0 auto;
    padding-right: calc(var(--bs-gutter-x) / 2); } }

@media (max-width: 767.98px) {
  .layout-block-sm {
    max-width: 680px; } }

.block-shadow {
  box-shadow: 0px 0px 30px #E7E8F0; }

.text-shadow {
  text-shadow: 0px 0px 0px #2A2A2A; }

.refresh_on {
  opacity: 1;
  animation: refresh1 .7s ease forwards; }

@keyframes refresh1 {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.refresh_off {
  opacity: 1;
  animation: refresh2 .7s ease forwards; }

@keyframes refresh2 {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.table-list tr td:first-child {
  font-weight: 700;
  padding-right: 1.25rem;
  min-width: 8rem; }

@media (max-width: 575.98px) {
  table {
    font-size: 14px; } }

@media (max-width: 575.98px) {
  iframe {
    max-width: 100%;
    height: auto;
    margin: 20px 0; } }

@media (min-width: 576px) {
  iframe[src*="youtube"] {
    height: unset;
    min-height: 315px; } }

.alignright {
  text-align: right;
  margin: 5px 10px;
  float: right; }

.alignleft {
  text-align: left;
  margin: 5px 10px;
  float: left; }

.aligncenter {
  text-align: center;
  margin: 0 auto; }

.popover {
  border: 1px solid #e0e0e0;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.07);
  top: -5px !important; }
  .popover-body {
    padding: 10px 15px;
    color: #666;
    border: 0; }

::selection {
  background: #131f70;
  color: #fff; }

a,
button {
  cursor: pointer;
  color: #131f70;
  text-decoration: none;
  transition: color .3s; }
  a:hover, a:focus,
  button:hover,
  button:focus {
    color: #ee7f01; }

.btn.active.focus-visible, .btn.active:focus-visible, .btn.focus-visible,
.btn:active.focus-visible, .btn:active:focus-visible, .btn:focus-visible,
:focus-visible, :focus-visible:active, a:focus-visible, button:focus-visible,
button:focus-visible:active {
  outline: 1px solid #131f70;
  box-shadow: none; }

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #131f70;
  border-color: transparent; }

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #131f70;
  border-color: #131f70; }

button {
  border: none;
  padding: 0;
  background: none; }

.btn,
.button {
  font-family: "Roboto";
  font-size: 0.9rem;
  font-weight: 600;
  color: #fff;
  padding: 0.95rem 1.55rem 0.8rem;
  border-radius: 0.25rem;
  background: #131f70;
  text-decoration: none;
  margin-bottom: 1rem;
  border: 0;
  transition: background .3s, color .2s, border-color .3s; }
  @media (max-width: 575.98px) {
    .btn,
    .button {
      padding: 0.95rem 1.1rem 0.85rem;
      margin-bottom: 0.75rem; } }
  .btn:hover, .btn:focus,
  .button:hover,
  .button:focus {
    color: #fff;
    background: #131F70CC;
    cursor: pointer;
    text-decoration: none; }
  .btn--primary,
  .button--primary {
    background: #131f70; }
    .btn--primary:hover, .btn--primary:focus,
    .button--primary:hover,
    .button--primary:focus {
      color: #fff;
      background: #ee7f01; }
  .btn--ghost,
  .button--ghost {
    background: transparent;
    color: #131f70;
    border: 2px solid #131f70;
    padding: 0.85rem 1.55rem 0.7rem; }
    @media (max-width: 575.98px) {
      .btn--ghost,
      .button--ghost {
        padding: 0.9rem 1.1rem 0.7rem; } }
    .btn--ghost:hover, .btn--ghost:focus,
    .button--ghost:hover,
    .button--ghost:focus {
      color: #fff;
      background: #131f70; }
  .btn--narrow,
  .button--narrow {
    padding: 0.7rem 1.95rem 0.6rem; }
  @media (max-width: 575.98px) {
    .btn_mobile,
    .button_mobile {
      font-family: "Roboto";
      font-size: 0.9rem;
      font-weight: 600;
      color: #fff !important;
      padding: 1.05rem 1.55rem 0.9rem;
      border-radius: 0.25rem;
      background: #131f70;
      text-decoration: none;
      margin-bottom: 1rem;
      border: 0;
      transition: background .3s, color .2s, border-color .3s; } }
  @media (max-width: 575.98px) {
    .btn_mobile:after,
    .button_mobile:after {
      content: initial !important; } }
  @media (max-width: 575.98px) {
    .btn_mobile:hover, .btn_mobile:focus,
    .button_mobile:hover,
    .button_mobile:focus {
      color: #fff;
      background: #131F70CC;
      cursor: pointer;
      text-decoration: none; } }

.btn ~ .btn {
  margin-left: 15px; }
  @media (max-width: 1199.98px) {
    .btn ~ .btn {
      margin-left: 0; } }
  @media (max-width: 767.98px) {
    .btn ~ .btn {
      margin-left: 0; } }

.link-arrow {
  position: relative;
  font-weight: 600;
  color: #ee7f01; }
  .link-arrow:hover, .link-arrow:focus {
    color: #131f70; }
    .link-arrow:hover:after, .link-arrow:focus:after {
      transform: translateX(3px); }
  .link-arrow:after {
    font-family: 'buw';
    content: "\e900";
    font-size: 13px;
    font-weight: 500;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    transition: transform .25s;
    position: relative;
    top: -2px;
    margin-left: 8px; }
    @media (max-width: 991.98px) {
      .link-arrow:after {
        margin-left: 5px; } }
    @media (max-width: 575.98px) {
      .link-arrow:after {
        top: -1px; } }
  .link-arrow_reverse {
    display: inline-block;
    margin: 0 0 12px 12px; }
    .link-arrow_reverse:hover:before, .link-arrow_reverse:focus:before {
      transform: translateX(3px); }
    .link-arrow_reverse:before {
      font-family: 'buw';
      content: "\e900";
      font-size: 14px;
      font-weight: 500;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-transition: -webkit-transform .25s;
      transition: -webkit-transform .25s;
      transition: transform .25s;
      transition: transform .25s, -webkit-transform .25s;
      position: relative;
      top: -2px;
      margin-right: 18px; }
      @media (max-width: 575.98px) {
        .link-arrow_reverse:before {
          font-size: 13px;
          top: -2px;
          margin-right: 15px; } }
    .link-arrow_reverse:after {
      content: initial; }
  .link-arrow_back:hover:before, .link-arrow_back:focus:before {
    transform: translateX(-3px) rotate(180deg); }
  .link-arrow_back:before {
    font-family: 'buw';
    content: "\e900";
    font-size: 13.5px;
    font-weight: 500;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    transition: transform .25s;
    position: relative;
    top: -2px;
    margin-right: 20px;
    transform: rotate(180deg); }
  .link-arrow_back:after {
    content: initial; }

.link-download {
  position: relative;
  font-weight: 600;
  color: #ee7f01;
  letter-spacing: -.1px;
  line-height: 31px;
  margin-left: 42px; }
  @media (max-width: 1399.98px) {
    .link-download {
      margin-left: 40px; } }
  @media (max-width: 575.98px) {
    .link-download {
      font-size: 18px;
      line-height: 24px;
      margin-left: 30px; } }
  .link-download:hover, .link-download:focus {
    color: #131f70; }
  .link-download:before {
    font-family: 'buw';
    content: "\e906";
    font-size: 25px;
    font-weight: 500;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    transition: transform .25s;
    position: absolute;
    top: 0px;
    left: -42px; }
    @media (max-width: 991.98px) {
      .link-download:before {
        font-size: 24px; } }
    @media (max-width: 575.98px) {
      .link-download:before {
        font-size: 20px;
        top: -2px;
        left: -30px; } }

.hide-link {
  display: none; }

.no-link {
  text-decoration: none;
  pointer-events: none; }

.list,
.list ul,
.infobox ul {
  padding-left: 24px;
  padding-left: 0;
  margin-bottom: 7px;
  list-style: none; }
  .list_col2,
  .list ul_col2,
  .infobox ul_col2 {
    column-count: 2; }
    @media (max-width: 575.98px) {
      .list_col2,
      .list ul_col2,
      .infobox ul_col2 {
        column-count: 1; } }
  .list_col3,
  .list ul_col3,
  .infobox ul_col3 {
    column-count: 3; }
    @media (max-width: 991.98px) {
      .list_col3,
      .list ul_col3,
      .infobox ul_col3 {
        column-count: 2; } }
    @media (max-width: 575.98px) {
      .list_col3,
      .list ul_col3,
      .infobox ul_col3 {
        column-count: 1; } }
  .list_col4,
  .list ul_col4,
  .infobox ul_col4 {
    column-count: 4; }
    @media (max-width: 991.98px) {
      .list_col4,
      .list ul_col4,
      .infobox ul_col4 {
        column-count: 2; } }
    @media (max-width: 575.98px) {
      .list_col4,
      .list ul_col4,
      .infobox ul_col4 {
        column-count: 1; } }
  .list_sm,
  .list ul_sm,
  .infobox ul_sm {
    max-width: 780px; }
  .list_md,
  .list ul_md,
  .infobox ul_md {
    max-width: 950px; }
  .list_xl,
  .list ul_xl,
  .infobox ul_xl {
    max-width: 1200px; }
  .list li,
  .list ul li,
  .infobox ul li {
    color: #999999;
    line-height: 30px;
    margin-bottom: 7px;
    padding-left: 21px;
    position: relative; }
    .list li:before,
    .list ul li:before,
    .infobox ul li:before {
      content: "•";
      color: #ee7f01;
      display: inline-block;
      margin-right: 0.4rem;
      transform: translateY(-1px);
      position: absolute;
      left: 0; }

.list {
  margin-bottom: 30px; }
  .list_md li {
    margin-bottom: 15px; }

.list--checked {
  padding: 0;
  margin-bottom: 53px; }
  @media (max-width: 991.98px) {
    .list--checked {
      margin-bottom: 45px; } }
  @media (max-width: 575.98px) {
    .list--checked {
      margin-bottom: 35px; } }
  .list--checked li {
    padding-left: 30px;
    margin-bottom: 3px;
    list-style: none;
    position: relative; }
    .list--checked li:before {
      font-family: 'buw';
      content: "\e911";
      font-size: 16px;
      color: #131f70;
      font-weight: 500;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      transition: color .1s,transform .4s;
      position: absolute;
      left: 0;
      top: 0; }

.list--arrows {
  padding: 0;
  list-style: none;
  margin: 0;
  position: relative;
  font-family: 'Roboto';
  font-weight: 700;
  color: #2A2A2A; }
  .list--arrows li:before {
    font-family: 'buw';
    content: "\e900";
    font-size: 15px;
    font-weight: 500;
    color: #2A2A2A;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    transition: transform .25s;
    position: relative;
    top: -1px;
    left: 0;
    margin-right: 9px; }

.download-list {
  position: relative;
  max-width: 860px; }
  .download-list li:first-child {
    position: absolute;
    top: calc(50% - 40px);
    left: calc(100% - 170px); }
    @media (max-width: 991.98px) {
      .download-list li:first-child {
        position: relative;
        top: 0;
        left: 0;
        margin: 20px 0 25px;
        opacity: .5; } }
    .download-list li:first-child img {
      width: auto;
      max-width: 170px;
      height: auto;
      max-height: 60px; }
      @media (max-width: 991.98px) {
        .download-list li:first-child img {
          max-height: 50px; } }
      @media (max-width: 575.98px) {
        .download-list li:first-child img {
          width: auto;
          max-width: initial;
          max-height: 42px; } }

.no-list {
  padding: 0;
  list-style: none; }
  .no-list li {
    margin-bottom: 0.6rem; }

main .quicklinks {
  font-family: 'Roboto';
  font-weight: 600;
  list-style: none;
  padding: 0;
  columns: 2;
  column-gap: 30px;
  max-width: 600px;
  align-self: flex-start;
  margin: 147px auto 33px 0; }
  @media (max-width: 1199.98px) {
    main .quicklinks {
      margin: 15px auto 33px 0 !important; } }
  @media (max-width: 575.98px) {
    main .quicklinks {
      columns: 1; } }
  main .quicklinks_list {
    columns: 1;
    margin: 0 auto 33px 170px;
    max-width: 450px;
    align-self: flex-end; }
    @media (max-width: 1199.98px) {
      main .quicklinks_list {
        margin: 20px 0 40px !important; } }
    @media (max-width: 575.98px) {
      main .quicklinks_list {
        margin: 15px 0 25px !important; } }
  main .quicklinks_pulled {
    margin: 147px -70px 33px auto; }
  main .quicklinks li {
    min-width: 285px;
    margin: 0 0 2px 27px; }
  main .quicklinks a {
    position: relative; }
    main .quicklinks a:hover:before {
      transform: translateX(2px); }
    main .quicklinks a:before {
      font-family: 'buw';
      content: "\e900";
      font-size: 13px;
      font-weight: 500;
      color: #ee7f01;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-transition: -webkit-transform .25s;
      transition: -webkit-transform .25s;
      transition: transform .25s;
      transition: transform .25s, -webkit-transform .25s;
      position: relative;
      top: -2px;
      margin-right: 10px;
      position: absolute;
      top: -4px;
      left: -25px;
      margin-right: 0; }

h1, .h1, .h1 {
  font-size: 2.5rem;
  line-height: 3rem;
  font-weight: 500;
  margin-bottom: 1.3rem; }
  @media (max-width: 991.98px) {
    h1, .h1, .h1 {
      font-size: 38px;
      line-height: 48px; } }
  @media (max-width: 575.98px) {
    h1, .h1, .h1 {
      font-size: 1.4rem;
      line-height: 1.7rem;
      margin-bottom: 1.3rem; } }
  @media screen and (max-width: 360px) {
    h1, .h1, .h1 {
      font-size: 1.35rem;
      line-height: 1.6rem;
      width: calc(100% + 20px); } }
  h1_subline,
  h1 em,
  .h1 em, .h1_subline,
  .h1 em {
    font-size: 20px;
    font-weight: 700;
    line-height: 42px;
    font-style: normal;
    display: block; }
    @media (max-width: 991.98px) {
      h1_subline,
      h1 em,
      .h1 em, .h1_subline,
      .h1 em {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 5px; } }

h2, .h2, .h2 {
  font-size: 1.9rem;
  line-height: 2.4rem;
  font-weight: 500;
  margin-bottom: 1.9rem; }
  @media (max-width: 575.98px) {
    h2, .h2, .h2 {
      font-size: 1.4rem;
      line-height: 1.7rem;
      margin-bottom: 1rem; } }

h3, .h3, .h3 {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  margin-bottom: 1.6rem; }
  @media (max-width: 575.98px) {
    h3, .h3, .h3 {
      font-size: 1.2rem;
      line-height: 1.5rem;
      margin-bottom: 1.4rem; } }
  h3_sm, .h3_sm {
    font-size: 1.3rem;
    line-height: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.85rem; }

h4, .h4, .h4 {
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 700;
  margin-bottom: 1.1rem; }
  @media (max-width: 575.98px) {
    h4, .h4, .h4 {
      margin-bottom: 12px; } }

h5, .h5, .h5 {
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: 700;
  margin-bottom: 0.7rem; }
  @media (max-width: 1199.98px) {
    h5, .h5, .h5 {
      font-size: 0.9rem; } }

@media (max-width: 575.98px) {
  .h1_mobile {
    font-size: 1.4rem;
    line-height: 1.7rem;
    font-weight: 500;
    margin-bottom: 1rem; } }

h1, .h1, .h1, h2, .h2, .h2, h3, .h3, .h3, h4, .h4, .h4, h5, .h5, .h5, h6, .h6, .h6 {
  font-family: "Roboto";
  color: #2A2A2A;
  position: relative;
  transition: color .3s; }

p, ul, ol,
ol ol, ol ul, ul ol, ul ul,
.tribe-events-content p {
  font-weight: 400;
  color: #999999;
  line-height: 1.6rem;
  margin-bottom: 1.65rem; }
  @media (max-width: 575.98px) {
    p, ul, ol,
    ol ol, ol ul, ul ol, ul ul,
    .tribe-events-content p {
      font-size: 18px;
      margin-bottom: 20px;
      line-height: 1.4rem; } }

p a {
  color: #ee7f01; }
  p a:hover, p a:focus {
    color: #131f70; }

@media (max-width: 1199.98px) {
  .br-desktop {
    display: none; } }

@media (min-width: 576px) {
  .br-mobile {
    display: none; } }

blockquote {
  font-size: 24px;
  font-weight: 500;
  font-style: italic;
  padding: 20px 25px;
  margin: 0 30px 60px;
  color: #131f70;
  border-left: 4px solid #131f70;
  display: inline-block;
  text-transform: uppercase; }
  blockquote p {
    margin: 0;
    line-height: 31px; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-internal-autofill-selected {
  -webkit-text-fill-color: #4d4d4f !important;
  background-color: #fff !important; }

b, strong, .strong {
  font-weight: 700; }

.content-block {
  margin-bottom: 45px; }
  .content-block:after {
    clear: both;
    content: "";
    display: table; }

.meta {
  color: #131f70;
  font-size: 18px; }
  @media (max-width: 575.98px) {
    .meta {
      font-size: 17px; } }
  .meta .divider {
    display: inline;
    margin: 0 6px;
    position: relative;
    top: -1px; }

@media (max-width: 991.98px) {
  .table-wrapper {
    overflow-x: auto; } }

table {
  width: 100%;
  margin-bottom: 30px;
  white-space: nowrap; }
  @media (max-width: 575.98px) {
    table {
      font-size: 18px; } }
  table th {
    padding: 11px 10px;
    background: #f8f8f8;
    font-weight: 600; }
  table td {
    padding: 10px;
    border: 1px solid #f8f8f8; }
  table a {
    color: #ee7f01; }
    table a:hover, table a:focus {
      color: #131f70; }

.block--features {
  margin: 0;
  padding: 0;
  display: flex;
  flex: 1 1 100%;
  flex-wrap: wrap;
  height: auto !important;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  justify-content: space-around; }
  @media screen and (max-width: 1400px) {
    .block--features {
      align-items: flex-start;
      margin: 90px auto 30px;
      justify-content: space-evenly;
      flex-direction: row;
      height: auto !important; } }
  @media (max-width: 991.98px) {
    .block--features {
      flex-direction: row;
      justify-content: space-between;
      height: 100% !important;
      margin-top: 2.5rem;
      padding-bottom: 1.5rem;
      align-items: flex-start;
      max-width: 800px;
      margin: 90px auto 30px;
      justify-content: space-evenly; } }
  .block--features_item {
    list-style: none;
    text-align: center;
    max-width: 13.5rem;
    border: 1px solid #ebebeb;
    border-radius: 0.3rem;
    padding: 1rem 1rem 0;
    margin-bottom: 3.5rem;
    transition: box-shadow .3s, border-color .2s, transform .3s, background .3s;
    max-width: 100%;
    /*&:nth-child(2) {
                margin-bottom: auto;

                @include media-breakpoint-down(md) {
                    margin-bottom: rem(70px);
                }
            }

            &:nth-child(3) {
                margin-top: rem(60px);

                @media screen and (max-width: 1390px) {
                    margin-top: 0;
                }
            }*/ }
    @media (max-width: 991.98px) {
      .block--features_item {
        margin-bottom: 60px;
        max-width: 19rem; } }
    @media (max-width: 767.98px) {
      .block--features_item {
        max-width: 17rem;
        max-width: calc(50% - 20px); } }
    .block--features_item:hover {
      background: #fff;
      border-color: #fff;
      box-shadow: 0 5px 30px rgba(0, 0, 0, 0.15);
      cursor: default; }
  .block--features_icon {
    display: flex;
    justify-content: center;
    width: 4.65rem;
    height: 4.65rem;
    background: #131f70;
    border-radius: 60%;
    margin: -50px auto 25px; }
    .block--features_icon img {
      align-self: center;
      max-width: 2.75rem; }

.block--services {
  margin: 1rem 0 1.5rem;
  padding: 0;
  display: flex;
  flex: 1 1 100%;
  flex-wrap: wrap;
  justify-content: flex-start; }
  @media (max-width: 991.98px) {
    .block--services {
      justify-content: space-between;
      margin: 0 auto;
      max-width: 800px; } }
  .block--services_item {
    list-style: none;
    text-align: center;
    width: 100%;
    max-width: 19rem;
    border: 1px solid #ebebeb;
    border-radius: 0.3rem;
    padding: 2.3rem 1.25rem 1.25rem;
    transition: box-shadow .3s, border-color .2s, transform .3s, background .3s;
    cursor: default !important;
    margin: 0 1.25rem 1.25rem 0;
    max-width: calc(50% - 5px);
    max-width: 100%;
    padding: 1.5rem 0.5rem 0.75rem;
    margin-bottom: 0.5rem; }
    @media screen and (max-width: 1400px) {
      .block--services_item {
        max-width: 373px; } }
    @media (max-width: 767.98px) {
      .block--services_item {
        max-width: calc(50% - 15px);
        margin: 0 0 1.25rem 0; } }
    .block--services_item:hover {
      background: #fff;
      border-color: #fff;
      box-shadow: 0 5px 30px rgba(0, 0, 0, 0.15);
      cursor: pointer; }
    .block--services_item:nth-child(3), .block--services_item:nth-child(6), .block--services_item:nth-child(9), .block--services_item:nth-child(12), .block--services_item:nth-child(15), .block--services_item:last-child {
      margin-right: 0; }
      @media (max-width: 991.98px) {
        .block--services_item:nth-child(3), .block--services_item:nth-child(6), .block--services_item:nth-child(9), .block--services_item:nth-child(12), .block--services_item:nth-child(15), .block--services_item:last-child {
          margin-right: 25px; } }
      @media (max-width: 767.98px) {
        .block--services_item:nth-child(3), .block--services_item:nth-child(6), .block--services_item:nth-child(9), .block--services_item:nth-child(12), .block--services_item:nth-child(15), .block--services_item:last-child {
          margin-right: 0; } }
    .block--services_item a:hover, .block--services_item a:focus {
      color: initial;
      text-decoration: none; }
  .block--services h3, .block--services .h3 {
    color: #131f70;
    letter-spacing: 0.025rem;
    text-shadow: 0 0 0 #131f70; }
  .block--services img {
    display: flex;
    justify-content: center;
    max-width: 11rem;
    height: auto;
    margin: 0 auto 25px; }
    @media (max-width: 575.98px) {
      .block--services img {
        max-width: 90%; } }

.block--documents {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-evenly; }
  .block--documents li {
    flex-direction: column;
    display: flex;
    margin: 0 50px 15px 0;
    margin: 0; }
    @media (max-width: 767.98px) {
      .block--documents li {
        margin: 0 30px 15px 0; } }
    .block--documents li img {
      display: block;
      box-shadow: 0 5px 35px rgba(0, 0, 0, 0.2);
      margin-bottom: 1rem;
      transition: box-shadow .25s, transform .3s;
      max-width: 8.5rem;
      max-width: 7rem;
      margin-bottom: 0.75rem; }

.block--news {
  background: #fff;
  border-radius: 0.25rem;
  max-width: 18.5rem;
  display: block;
  transition: box-shadow .3s; }
  .block--news:hover, .block--news:focus {
    text-decoration: none;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.15); }
    .block--news:hover .link--arrow:before, .block--news:focus .link--arrow:before {
      background: #ee7f01; }
    .block--news:hover .link--arrow:after, .block--news:focus .link--arrow:after {
      color: #ee7f01; }
  .block--news_content {
    padding: 1.75rem 1.5rem;
    border: 1px solid #eee; }
    .block--news_content h3, .block--news_content .h3 {
      color: #011a38;
      letter-spacing: 0; }
    .block--news_content p {
      line-height: 1.35rem; }
  .block--news time {
    font-size: 0.7rem;
    color: #ccc;
    display: block;
    position: relative;
    margin-bottom: 0.75rem; }
    .block--news time:before {
      font-family: 'icomoon';
      content: "\e900";
      font-size: 1.25rem;
      color: #ccc;
      font-weight: 300;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      transform: translate(-3px, 4px);
      margin-right: 0.3rem;
      transition: color .1s,transform .4s;
      position: relative; }

.block--card {
  padding: 0.5rem;
  background: #fff;
  border-radius: 0.25rem;
  border: 1px solid #eee;
  display: block;
  transition: box-shadow .3s, border .2s; }
  .block--card:hover, .block--card:focus {
    text-decoration: none;
    border: 1px solid #c9c9c9; }
  .block--card_content {
    padding: 1.75rem 1.5rem 0.5rem; }

.block--review {
  display: flex;
  position: relative;
  border: 1px solid #ebebeb;
  border-radius: 0.3rem;
  padding: 3.5rem 6rem 2.5rem 1.75rem;
  flex-direction: column;
  padding: 1.5rem 1.25rem 2.5rem 1.25rem; }
  @media (max-width: 767.98px) {
    .block--review {
      padding: 2.5rem 2.5rem 2.5rem 1.75rem; } }
  .block--review_customerdetails {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 9rem;
    margin-right: 1.5rem;
    font-size: 0.7rem;
    max-width: 9rem;
    margin-right: 3.5rem;
    min-width: 9rem;
    margin: 0 0 1.5rem;
    max-width: 100%; }
    .block--review_customerdetails strong {
      font-size: 0.8rem;
      margin-bottom: 0.4rem;
      max-width: 9rem;
      max-width: 100%; }
  .block--review_img {
    display: block;
    border-radius: 60%;
    overflow: hidden;
    margin-bottom: 1rem; }
  .block--review_content:before {
    font-family: 'icomoon';
    content: "\e905";
    position: absolute;
    font-size: 50px;
    color: #ebebeb;
    transform: translate(-55px, -33px); }
    @media (max-width: 575.98px) {
      .block--review_content:before {
        font-size: 90px;
        color: #f6f6f6;
        transform: translate(-15px, -50px);
        z-index: -1; } }
  .block--review_content strong {
    color: #000;
    display: block;
    margin-bottom: 0.25rem; }

.block--narrow {
  background: #f6f6f6;
  padding: 1.5rem 0;
  text-align: center;
  width: 100%;
  order: 95; }

.news-card {
  background: #fff;
  box-shadow: 0px 0px 30px #e7e8f0;
  display: block;
  position: relative;
  max-width: 520px;
  margin-bottom: 40px;
  transition: box-shadow .2s;
  display: none; }
  @media screen and (max-width: 1600px) {
    .news-card {
      max-width: calc(33% - 20px); } }
  @media (max-width: 1199.98px) {
    .news-card {
      max-width: calc(50% - 20px); } }
  @media (max-width: 575.98px) {
    .news-card {
      max-width: 100%; } }
  .news-card:hover, .news-card:focus {
    box-shadow: 0px 0px 30px #dedfea; }
    .news-card:hover .h4, .news-card:hover .h5, .news-card:focus .h4, .news-card:focus .h5 {
      color: #ee7f01; }
    .news-card:hover img, .news-card:focus img {
      opacity: .93; }
  .news-card_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    align-items: stretch; }
    @media (min-width: 992px) {
      .news-card_wrapper:after {
        content: "";
        width: 520px; } }
    @media (min-width: 768px) {
      .news-card_wrapper:after {
        width: 470px; } }
  .news-card_block-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start; }
    .news-card_block-wrapper .news-card {
      display: block;
      max-width: 670px;
      margin-right: 60px; }
      @media screen and (max-width: 1600px) {
        .news-card_block-wrapper .news-card {
          max-width: 600px; } }
      @media (max-width: 991.98px) {
        .news-card_block-wrapper .news-card {
          margin-right: 0; } }
      .news-card_block-wrapper .news-card picture,
      .news-card_block-wrapper .news-card img {
        width: 100%;
        height: auto; }
        @media (min-width: 576px) {
          .news-card_block-wrapper .news-card picture,
          .news-card_block-wrapper .news-card img {
            height: 320px; } }
      .news-card_block-wrapper .news-card .meta-footer {
        margin-top: 55px; }
  .news-card picture {
    width: 520px;
    height: 320px;
    display: block; }
    @media screen and (max-width: 1600px) {
      .news-card picture {
        width: 100%;
        height: auto; } }
    .news-card picture img {
      width: 520px;
      height: 320px;
      object-fit: cover;
      transition: opacity .3s; }
      @media screen and (max-width: 1600px) {
        .news-card picture img {
          width: 100%;
          height: auto; } }
      @media (max-width: 575.98px) {
        .news-card picture img {
          width: 100%;
          height: 180px; } }
  .news-card_content {
    padding: 41px 30px 27px;
    height: calc(100% - 320px);
    display: flex;
    flex-direction: column; }
    @media (max-width: 575.98px) {
      .news-card_content {
        padding: 30px 25px 18px; } }
    .news-card_content h3, .news-card_content .h3 {
      margin-bottom: 0.9rem; }
      @media (max-width: 575.98px) {
        .news-card_content h3, .news-card_content .h3 {
          font-size: 21px;
          line-height: 28px; } }
    .news-card_content p {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 28px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical; }
      @media screen and (max-width: 1600px) {
        .news-card_content p {
          overflow: visible;
          -webkit-box-orient: unset; } }
      @media (max-width: 575.98px) {
        .news-card_content p {
          font-size: 17px;
          line-height: 27px;
          margin-bottom: 25px; } }
    .news-card_content .meta-footer {
      display: block;
      color: #131f70;
      font-size: 18px;
      text-shadow: 0 0 #131f70;
      width: 100%;
      border-top: 2px solid #f3f3f7;
      padding: 15px 0 0;
      margin-top: auto;
      position: relative; }
      @media (max-width: 575.98px) {
        .news-card_content .meta-footer {
          font-size: 17px; } }
      .news-card_content .meta-footer:after {
        font-family: 'buw';
        content: "\e900";
        font-size: 13px;
        font-weight: 500;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        transition: transform .25s;
        position: absolute;
        bottom: 4px;
        right: 1px;
        color: #ee7f01; }
        @media (max-width: 1199.98px) {
          .news-card_content .meta-footer:after {
            content: initial; } }
      .news-card_content .meta-footer .divider {
        display: inline;
        margin: 0 6px;
        position: relative;
        top: -1px; }
      .news-card_content .meta-footer .category-item:last-of-type:after {
        content: initial; }
      .news-card_content .meta-footer .category-item:after {
        content: ", ";
        position: relative; }

.news-slider {
  max-width: 1415px;
  margin: 0 auto 50px; }
  @media (max-width: 991.98px) {
    .news-slider {
      max-width: 100%;
      margin: 0 auto 25px; } }
  .news-slider .splide__track {
    padding: 25px 0 0 25px; }
    @media (max-width: 1199.98px) {
      .news-slider .splide__track {
        overflow: visible; } }
    @media (max-width: 991.98px) {
      .news-slider .splide__track {
        padding: 25px 0 0 112px; } }
    @media (max-width: 575.98px) {
      .news-slider .splide__track {
        padding: 10px 0 0 0; } }
  .news-slider .splide__list {
    margin: 0 auto !important; }
    .news-slider .splide__list li:nth-child(-n+2):last-child .news-card {
      margin-right: 25px !important; }
  .news-slider .splide__pagination {
    bottom: -30px; }
    .news-slider .splide__pagination li button {
      width: 12px;
      height: 12px;
      margin: 5px;
      opacity: 1;
      background: #E7E8F0;
      transition: background .3s; }
      @media (max-width: 991.98px) {
        .news-slider .splide__pagination li button {
          width: 16px;
          height: 16px;
          margin: 5px 10px 5px; } }
      .news-slider .splide__pagination li button:hover, .news-slider .splide__pagination li button:focus {
        background: #dedfe8; }
      .news-slider .splide__pagination li button.is-active {
        background: #ee7f01;
        transform: none; }
  .news-slider .splide__arrow {
    opacity: .7;
    background: transparent;
    transition: transform .3s; }
    @media (max-width: 1199.98px) {
      .news-slider .splide__arrow {
        display: none; } }
    .news-slider .splide__arrow:hover {
      opacity: 1; }
    .news-slider .splide__arrow--prev {
      left: -100px; }
      .news-slider .splide__arrow--prev:hover {
        transform: translateY(-50%) translateX(-5px); }
    .news-slider .splide__arrow--next {
      right: -100px; }
      .news-slider .splide__arrow--next:hover {
        transform: translateY(-50%) translateX(5px); }
    .news-slider .splide__arrow .arrow:before {
      font-family: 'buw';
      font-size: 38px;
      color: #00000038;
      font-weight: 500;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      position: relative;
      top: 0;
      right: 0; }
    .news-slider .splide__arrow .arrow-prev {
      transition: transform .25s; }
      .news-slider .splide__arrow .arrow-prev:before {
        content: "\e903"; }
    .news-slider .splide__arrow .arrow-next:before {
      content: "\e905"; }
  .news-slider .news-card {
    display: block;
    max-width: 400px;
    margin-right: 80px; }
    @media (min-width: 1200px) {
      .news-slider .news-card {
        max-width: 400px !important; } }
    @media (max-width: 1399.98px) {
      .news-slider .news-card {
        margin-right: 50px; } }
    @media (max-width: 575.98px) {
      .news-slider .news-card {
        max-width: 320px;
        margin-right: 60px; } }
    .news-slider .news-card:after {
      bottom: 16px; }
    .news-slider .news-card_content {
      min-height: 245px;
      padding: 41px 30px 16px;
      display: flex;
      flex-direction: column; }
      @media (max-width: 575.98px) {
        .news-slider .news-card_content {
          min-height: 225px;
          padding: 30px 20px 17px; } }
      .news-slider .news-card_content .meta-footer {
        padding: 10px 0 0; }
        @media (max-width: 575.98px) {
          .news-slider .news-card_content .meta-footer {
            font-size: 17px; } }
        @media (max-width: 575.98px) {
          .news-slider .news-card_content .meta-footer .divider,
          .news-slider .news-card_content .meta-footer .category-item {
            display: none; } }
    .news-slider .news-card picture {
      height: auto; }
    .news-slider .news-card img {
      width: 400px;
      height: auto;
      object-fit: cover; }
      @media (max-width: 575.98px) {
        .news-slider .news-card img {
          width: 100%;
          height: 200px; } }

.news-teaser {
  width: 100%;
  max-width: 520px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 0px 30px #E7E8F0;
  transition: box-shadow .2s;
  background: #fff;
  display: none;
  margin-right: 45px; }
  @media (max-width: 575.98px) {
    .news-teaser {
      flex-direction: column;
      margin-bottom: 20px;
      margin-right: 15px;
      max-width: 300px; } }
  @media screen and (max-width: 1660px) {
    .news-teaser:nth-child(-n+2) {
      margin-right: 30px; } }
  @media (max-width: 575.98px) {
    .news-teaser:nth-child(-n+2) {
      margin-right: 15px; } }
  @media screen and (min-width: 1400px) and (max-width: 1660px) {
    .news-teaser:last-child {
      display: none; } }
  .news-teaser:nth-child(-n+3) {
    display: flex; }
  @media screen and (min-width: 1400px) and (max-width: 1660px) {
    .news-teaser:nth-child(n+3) {
      display: none; } }
  .news-teaser:last-child, .news-teaser:nth-child(3) {
    margin-right: 0; }
  .news-teaser:hover, .news-teaser:focus {
    box-shadow: 0px 0px 30px #dedfea; }
    .news-teaser:hover h3, .news-teaser:hover .h3, .news-teaser:focus h3, .news-teaser:focus .h3 {
      color: #ee7f01; }
    .news-teaser:hover img, .news-teaser:focus img {
      opacity: .9; }
  .news-teaser_wrapper {
    display: flex;
    overflow-x: scroll;
    justify-content: space-between;
    padding: 30px 0;
    margin: -25px 0 10px;
    width: calc(100% + 15px);
    scrollbar-width: none; }
    @media screen and (max-width: 1660px) {
      .news-teaser_wrapper {
        width: 100%;
        justify-content: flex-start;
        margin: -25px 0 0; } }
    @media (max-width: 1199.98px) {
      .news-teaser_wrapper {
        overflow-x: scroll; } }
    .news-teaser_wrapper__inner {
      display: flex;
      width: calc(100% - 15px);
      justify-content: space-between;
      justify-content: flex-start; }
      @media screen and (min-width: 1400px) and (max-width: 1660px) {
        .news-teaser_wrapper__inner {
          justify-content: flex-start; } }
      @media screen and (max-width: 1660px) {
        .news-teaser_wrapper__inner {
          min-width: 1600px; } }
      @media (max-width: 575.98px) {
        .news-teaser_wrapper__inner {
          min-width: 1005px;
          justify-content: flex-start; } }
      .news-teaser_wrapper__inner::-webkit-scrollbar {
        height: 0;
        display: none; }
    .news-teaser_wrapper ~ .link-arrow {
      align-self: flex-end; }
      @media screen and (max-width: 1660px) {
        .news-teaser_wrapper ~ .link-arrow {
          align-self: flex-start; } }
    .news-teaser_wrapper::-webkit-scrollbar {
      height: 0;
      display: none; }
  .news-teaser_content {
    padding: 0 20px; }
    @media (max-width: 575.98px) {
      .news-teaser_content {
        display: flex;
        flex-wrap: wrap;
        padding: 19px 20px 14px; } }
    .news-teaser_content p {
      font-size: 18px;
      line-height: 32px;
      margin: 0; }
      @media (max-width: 991.98px) {
        .news-teaser_content p {
          display: flex;
          justify-content: space-between;
          width: 100%; } }
      @media (max-width: 575.98px) {
        .news-teaser_content p {
          line-height: 30px; } }
      @media (max-width: 575.98px) {
        .news-teaser_content p .link-arrow {
          margin-left: auto; } }
    .news-teaser_content span:last-of-type:after {
      content: initial; }
    .news-teaser_content span:after {
      content: ", ";
      position: relative; }
    @media screen and (max-width: 1600px) {
      .news-teaser_content h3, .news-teaser_content .h3 {
        white-space: normal; } }
    @media (max-width: 575.98px) {
      .news-teaser_content h3, .news-teaser_content .h3 {
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 18px; } }
  .news-teaser::-webkit-scrollbar {
    height: 0;
    display: none; }
  @media (max-width: 575.98px) {
    .news-teaser picture {
      width: 100%; } }
  .news-teaser picture img {
    width: 185px;
    height: 195px;
    object-fit: cover;
    object-position: left;
    transition: opacity .2s; }
    @media (max-width: 575.98px) {
      .news-teaser picture img {
        width: 100%;
        height: 130px;
        object-position: center; } }

#latestNews {
  padding: 0; }
  @media (max-width: 575.98px) {
    #latestNews .container {
      width: 440px; } }
  @media (max-width: 1199.98px) {
    #latestNews .container:after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 140px;
      height: 100%;
      background: linear-gradient(270deg, #fcfcfc 55%, rgba(255, 255, 255, 0) 98%);
      z-index: 99; } }
  @media (max-width: 575.98px) {
    #latestNews .container:after {
      content: initial; } }

body.home #latestNews {
  margin: -180px 0 0;
  padding: 0; }
  @media (max-width: 1199.98px) {
    body.home #latestNews {
      margin: -230px 0 0; } }
  @media (max-width: 575.98px) {
    body.home #latestNews {
      margin: -245px 0 0;
      padding: 50px 0 25px;
      background: #F8F8F8; } }

.filter-tabs {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 65px;
  padding: 0; }
  @media (max-width: 575.98px) {
    .filter-tabs {
      width: calc(100% + 25px);
      margin-bottom: 40px; } }
  .filter-tabs_tags li a {
    margin: 0 10px 10px 0 !important; }
    @media (max-width: 575.98px) {
      .filter-tabs_tags li a {
        margin: 0 6px 6px 0 !important; } }
  .filter-tabs_overview li:nth-child(2), .filter-tabs_overview li:nth-child(3), .filter-tabs_overview li:nth-child(8), .filter-tabs_overview li:nth-child(9), .filter-tabs_overview li:nth-child(10), .filter-tabs_overview li:last-child {
    display: none; }
  .filter-tabs li.current-cat a {
    color: #fff;
    background: #131f70;
    border-color: #131f70;
    cursor: default; }
  .filter-tabs li:last-child a {
    margin-right: 0; }
  .filter-tabs li a {
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 600;
    color: #898fb7;
    padding: 10px 22px 8px;
    margin: 0 10px 0 0;
    display: inline-flex;
    background: #F8F8FB;
    border-radius: 5px;
    border: 2px solid #D0D2E2;
    transition: background .35s, border-color .35s, color .25s; }
    @media (max-width: 1399.98px) {
      .filter-tabs li a {
        margin: 0 10px 10px 0; } }
    @media (max-width: 575.98px) {
      .filter-tabs li a {
        font-size: 17px;
        font-weight: 500;
        padding: 7px 13px 5px;
        margin: 0 6px 6px 0; } }
    .filter-tabs li a:hover, .filter-tabs li a:focus {
      color: #fff;
      background: #131f70;
      border-color: #131f70; }
    .filter-tabs li a.active {
      color: #fff;
      background: #131f70;
      border-color: #131f70; }
    .filter-tabs li a .count {
      margin-left: 5px; }

.tab-content {
  background: #fff;
  padding: 65px 50px 65px 60px;
  box-shadow: 0 0 30px #e7e8f0;
  max-width: 1450px; }
  @media (max-width: 575.98px) {
    .tab-content {
      padding: 33px 27px; } }
  .tab-content #videoCarousel {
    margin: 12px auto 100px; }
    @media (max-width: 991.98px) {
      .tab-content #videoCarousel {
        margin: 0 auto 0;
        transform: scale(0.75); } }

.service-blocks {
  list-style: none;
  margin: 65px 0 0;
  padding: 0;
  display: flex;
  position: absolute;
  right: 0;
  max-width: 1252px;
  transform: translateX(-20px); }
  @media screen and (max-width: 1400px) {
    .service-blocks {
      margin: 30px 0 0; } }
  @media (max-width: 1199.98px) {
    .service-blocks {
      position: relative;
      margin: 50px 0 0;
      flex-wrap: wrap;
      justify-content: flex-start;
      width: calc(100% + 80px);
      transform: translateX(-20px); } }
  @media (max-width: 767.98px) {
    .service-blocks {
      width: 100%;
      justify-content: space-between;
      transform: none; } }
  .service-blocks_content {
    display: flex;
    flex-direction: column;
    height: 100%; }
  .service-blocks li {
    background: #fff;
    border: 1px solid #E7E8F0;
    padding: 50px 40px 22px;
    padding: 62px 32px 22px 40px;
    margin: 0 -1px;
    display: flex;
    flex-direction: column;
    width: 315px; }
    @media screen and (max-width: 1400px) {
      .service-blocks li {
        width: 295px;
        padding: 62px 15px 22px 25px; } }
    @media (max-width: 1199.98px) {
      .service-blocks li {
        width: 315px;
        padding: 62px 32px 22px 40px;
        margin-right: 20px;
        margin-bottom: 20px; } }
    @media (max-width: 767.98px) {
      .service-blocks li {
        margin-right: 0; } }
    @media (max-width: 575.98px) {
      .service-blocks li {
        margin-right: 0;
        flex-direction: row;
        padding: 22px 15px 20px 17px; } }
    @media (max-width: 575.98px) {
      .service-blocks li h3, .service-blocks li .h3 {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 8px; } }
    .service-blocks li p {
      font-size: 18px;
      line-height: 28px; }
      @media (max-width: 575.98px) {
        .service-blocks li p {
          font-size: 17px;
          line-height: 26px; } }
    .service-blocks li a {
      align-self: flex-end;
      margin-top: auto; }
      @media (max-width: 575.98px) {
        .service-blocks li a {
          align-self: flex-start; } }
  .service-blocks img {
    max-height: 70px;
    width: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 40px; }
    @media (max-width: 575.98px) {
      .service-blocks img {
        width: 100%;
        max-width: 57px;
        margin-right: 20px; } }

.cards {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 30px; }
  @media (max-width: 1199.98px) {
    .cards {
      margin: 50px 0 25px; } }
  .cards li {
    background: #fff;
    margin-bottom: 30px;
    width: calc(50% - 10px);
    padding: 0; }
    @media (max-width: 575.98px) {
      .cards li {
        width: 100%;
        margin-bottom: 12px; } }
    .cards li a {
      display: flex;
      border-left: 10px solid #ee7f01;
      box-shadow: 0px 0px 30px #e7e8f0;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 30px 19px 20px 25px;
      transition: border .25s, box-shadow .25s; }
      @media (max-width: 1199.98px) {
        .cards li a {
          border-left: 8px solid #ee7f01;
          padding: 31px 15px 22px 20px; } }
      @media (max-width: 575.98px) {
        .cards li a {
          padding: 16px 15px 10px 20px; } }
      .cards li a:hover, .cards li a:focus {
        border-left: 10px solid #131f70;
        box-shadow: 0px 0px 30px #dedfea; }
  .cards h5, .cards .h5, .cards .h5 {
    color: #131f70;
    margin: 0; }
  .cards .card-icon {
    width: auto;
    height: auto;
    max-height: 60px;
    margin-right: 13px;
    margin-bottom: 5px; }
    @media (max-width: 1199.98px) {
      .cards .card-icon {
        max-height: 48px;
        margin-right: 10px; } }
    @media (max-width: 575.98px) {
      .cards .card-icon {
        max-height: 44px;
        margin-right: 12px; } }

@media (max-width: 575.98px) {
  .brand-cards {
    margin-bottom: 0; } }

.brand-cards li {
  margin-bottom: 20px; }
  @media (max-width: 575.98px) {
    .brand-cards li {
      margin-bottom: 12px; } }
  .brand-cards li a {
    border: 0;
    justify-content: center;
    padding: 30px 25px 25px; }
    @media (max-width: 575.98px) {
      .brand-cards li a {
        padding: 0;
        height: 75px; } }
    .brand-cards li a:hover, .brand-cards li a:focus {
      border-left: 0;
      box-shadow: 0px 0px 30px #d6d7e4; }
      @media (min-width: 992px) {
        .brand-cards li a:hover img, .brand-cards li a:focus img {
          transform: translateX(3px); } }
  .brand-cards li img {
    max-width: 200px;
    max-height: 60px;
    width: auto;
    transition: transform .25s; }
    @media (max-width: 575.98px) {
      .brand-cards li img {
        max-width: 170px;
        max-height: 42px; } }

.team-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin-bottom: 30px; }
  .team-cards:after {
    content: "";
    flex: 0 1 375px; }
  .team-cards > li {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 375px;
    min-height: 510px;
    padding: 30px 29px 26px;
    margin-bottom: 50px;
    background: #fff;
    box-shadow: 0px 0px 30px #e7e8f0; }
    @media screen and (max-width: 1660px) {
      .team-cards > li {
        width: 340px; } }
    @media (max-width: 1399.98px) {
      .team-cards > li {
        width: calc(30%); } }
    @media (max-width: 991.98px) {
      .team-cards > li {
        width: calc(50% - 30px); } }
    @media (max-width: 575.98px) {
      .team-cards > li {
        width: 100%;
        min-height: 100%;
        padding: 30px 26px 15px; } }
    .team-cards > li img {
      display: block;
      border-radius: 60%;
      width: 100%;
      max-width: 250px;
      margin: 0 auto 33px;
      box-shadow: 0px 0px 40px #00000029; }
      @media screen and (max-width: 1660px) {
        .team-cards > li img {
          max-width: 240px; } }
      @media (max-width: 1199.98px) {
        .team-cards > li img {
          max-width: 200px; } }
    @media (max-width: 575.98px) {
      .team-cards > li .list {
        margin-bottom: 30px; } }
    .team-cards > li .link-arrow {
      align-self: flex-end;
      margin-top: auto;
      text-decoration: none; }
    .team-cards > li.special {
      background: transparent;
      box-shadow: none;
      justify-content: flex-end; }
      @media (max-width: 575.98px) {
        .team-cards > li.special {
          min-height: auto;
          padding: 0; } }
      .team-cards > li.special .link-arrow {
        margin-top: 20px; }
  .team-cards .h4 {
    margin-bottom: 18px;
    display: block; }

.teaser-cards {
  display: flex;
  list-style: none;
  padding: 0;
  margin-bottom: 60px;
  flex-wrap: wrap; }
  @media (max-width: 991.98px) {
    .teaser-cards {
      margin: 35px 0;
      flex-wrap: wrap; } }
  .teaser-cards li {
    max-width: 710px;
    margin-right: 65px;
    display: flex;
    border-left: 10px solid #ee7f01;
    background: #fff;
    box-shadow: 0px 0px 30px #e7e8f0;
    width: 100%;
    padding: 30px 26px 20px;
    transition: border .25s, box-shadow .25s;
    flex-direction: column;
    align-self: flex-start;
    flex-wrap: wrap;
    margin-bottom: 30px;
    align-self: stretch; }
    @media screen and (min-width: 1200px) and (max-width: 1600px) {
      .teaser-cards li {
        max-width: calc(50% - 30px);
        margin-right: 30px; } }
    @media (max-width: 991.98px) {
      .teaser-cards li {
        width: 100%;
        margin: 0 0 20px;
        border-left: 8px solid #ee7f01; } }
    .teaser-cards li h3, .teaser-cards li .h3 {
      font-size: 20px;
      line-height: 31px;
      margin-bottom: 8px; }
      @media (max-width: 991.98px) {
        .teaser-cards li h3, .teaser-cards li .h3 {
          margin-bottom: 12px; } }
    .teaser-cards li p {
      margin-bottom: 30px; }
      @media (max-width: 575.98px) {
        .teaser-cards li p {
          line-height: 28px;
          margin-bottom: 25px; } }
    .teaser-cards li .link-arrow {
      margin: auto 0 0 0; }

.feature-cards {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  padding: 0;
  justify-content: flex-end;
  transform: translateY(-6px); }
  @media (max-width: 1399.98px) {
    .feature-cards {
      justify-content: space-between;
      margin: 0;
      transform: none; } }
  .feature-cards_list {
    justify-content: center; }
    @media (max-width: 1199.98px) {
      .feature-cards_list {
        justify-content: space-between; } }
    .feature-cards_list li {
      text-align: center;
      margin-right: 35px !important; }
      @media (max-width: 1199.98px) {
        .feature-cards_list li {
          margin-right: 0 !important; } }
      .feature-cards_list li:last-child {
        margin-right: 0 !important; }
      .feature-cards_list li img {
        margin: 0 auto 30px !important; }
  .feature-cards_unboxed li {
    text-align: left;
    background: transparent !important;
    border: 0 !important;
    margin: 0 !important; }
    .feature-cards_unboxed li img {
      margin: 0 auto 30px 0 !important; }
  .feature-cards_full {
    justify-content: flex-start; }
    .feature-cards_full li {
      max-width: 525px !important; }
      @media screen and (max-width: 1600px) {
        .feature-cards_full li {
          max-width: 470px !important;
          margin-bottom: 30px !important; } }
      @media (max-width: 1399.98px) {
        .feature-cards_full li {
          max-width: 360px ​ !important; } }
      @media (max-width: 575.98px) {
        .feature-cards_full li {
          max-width: 100% ​ !important;
          margin-bottom: 15px !important; } }
  .feature-cards li {
    max-width: 315px;
    margin-right: 40px;
    display: flex;
    background: #F8F8F8;
    border: 1px solid #E7E8F0;
    width: 100%;
    padding: 39px 38px 35px;
    transition: border .25s, box-shadow .25s;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 40px; }
    @media screen and (max-width: 1600px) {
      .feature-cards li {
        max-width: 312px; } }
    @media (max-width: 1399.98px) {
      .feature-cards li {
        max-width: calc(100%/4 - 10px);
        margin-right: 0;
        padding: 39px 30px 30px;
        margin-bottom: 0; } }
    @media (max-width: 1199.98px) {
      .feature-cards li {
        max-width: calc(50% - 10px);
        margin-bottom: 20px; } }
    @media (max-width: 575.98px) {
      .feature-cards li {
        max-width: 100%;
        margin-bottom: 20px; } }
    .feature-cards li:nth-child(2n) {
      margin-right: 0; }
    @media (max-width: 575.98px) {
      .feature-cards li:last-child {
        margin-bottom: -20px; } }
    .feature-cards li img {
      height: 75px;
      width: auto;
      margin: 0 auto 30px 0; }
    .feature-cards li h3, .feature-cards li .h3 {
      font-size: 20px;
      line-height: 30px;
      color: #131f70;
      margin-bottom: 10px; }
    .feature-cards li p {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 0; }
    .feature-cards li a {
      text-decoration: underline; }

.section--gray .feature-cards_list li {
  background: #fff; }

.logo-card {
  align-self: center;
  margin: 0 auto;
  background: #fff;
  padding: 20px;
  box-shadow: 0px 0px 30px #e7e8f0;
  height: 150px;
  justify-content: center;
  align-items: center;
  display: flex;
  transition: box-shadow .2s; }
  @media (max-width: 575.98px) {
    .logo-card {
      height: 100px;
      padding: 10px; } }
  .logo-card_list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    flex-wrap: wrap;
    max-width: 1520px; }
    .logo-card_list li {
      margin: 0 40px 40px 0; }
      @media (max-width: 575.98px) {
        .logo-card_list li {
          margin: 0 0 15px;
          width: 100%; } }
  .logo-card_link {
    align-self: center;
    margin: 0 auto; }
    @media (max-width: 575.98px) {
      .logo-card_link {
        width: 100%;
        margin: 15px auto 0; } }
    .logo-card_link:hover picture, .logo-card_link:focus picture {
      box-shadow: 0px 0px 30px #dcdde6; }
    .logo-card_link:hover img, .logo-card_link:focus img {
      opacity: .7; }
    .logo-card_link:focus picture {
      outline: 1px solid #d4d4d4; }
  .logo-card img {
    width: 100%;
    height: auto;
    transition: opacity .2s; }
    @media (max-width: 575.98px) {
      .logo-card img {
        height: auto !important;
        width: auto !important;
        max-height: 58px;
        max-width: 200px; } }

.logo-card-sidecontent {
  display: flex;
  margin: 0;
  list-style: none;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start; }
  .logo-card-sidecontent > li {
    margin: 0 40px 40px 0;
    background: #fff;
    padding: 20px;
    box-shadow: 0px 0px 30px #e7e8f0;
    height: 106px;
    width: 100%;
    max-width: 691px;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    transition: box-shadow .2s; }
    @media (max-width: 991.98px) {
      .logo-card-sidecontent > li {
        width: 100%;
        margin: 0 0 20px; } }
    @media (max-width: 575.98px) {
      .logo-card-sidecontent > li {
        flex-wrap: wrap;
        height: auto; } }
    .logo-card-sidecontent > li.logo-card-sidecontent__no-logo {
      padding: 20px 60px 20px 50px; }
      @media (max-width: 575.98px) {
        .logo-card-sidecontent > li.logo-card-sidecontent__no-logo {
          width: 100%;
          padding: 25px 20px 20px; } }
    .logo-card-sidecontent > li picture {
      width: 205px;
      text-align: center;
      margin-right: 80px; }
      @media (max-width: 575.98px) {
        .logo-card-sidecontent > li picture {
          width: auto;
          margin: 0 0 17px; } }
      .logo-card-sidecontent > li picture img {
        width: auto;
        max-height: 62px;
        max-width: 195px;
        height: auto; }
        @media (max-width: 575.98px) {
          .logo-card-sidecontent > li picture img {
            width: auto;
            max-height: 40px; } }
  @media (max-width: 575.98px) {
    .logo-card-sidecontent_content {
      width: 100%; } }
  .logo-card-sidecontent_content a {
    color: #2A2A2A; }
    .logo-card-sidecontent_content a:hover, .logo-card-sidecontent_content a:focus {
      color: #ee7f01; }

.product-card {
  width: 100%;
  max-width: 800px;
  display: flex;
  background: #fff;
  box-shadow: 0px 0px 30px #e7e8f0;
  position: relative;
  margin-bottom: 40px; }
  @media screen and (max-width: 1640px) {
    .product-card {
      max-width: 680px; } }
  @media (max-width: 575.98px) {
    .product-card {
      max-width: 100%;
      flex-wrap: wrap;
      margin-bottom: 50px;
      box-shadow: none;
      background: transparent; } }
  @media (max-width: 1199.98px) {
    .product-card_single {
      margin: 30px auto 0 0 !important; } }
  .product-card_content {
    display: flex;
    flex-wrap: wrap;
    padding: 60px 40px 0;
    flex-direction: column; }
    @media screen and (max-width: 1640px) {
      .product-card_content {
        padding: 60px 30px 0; } }
    @media (max-width: 1199.98px) {
      .product-card_content {
        padding: 0 25px 0;
        justify-content: center; } }
    @media (max-width: 575.98px) {
      .product-card_content {
        padding: 0; } }
    .product-card_content a {
      position: absolute;
      bottom: 21px;
      right: 38px; }
      @media screen and (max-width: 1640px) {
        .product-card_content a {
          position: relative;
          bottom: 0;
          right: 0;
          text-align: right; } }
      @media (max-width: 575.98px) {
        .product-card_content a {
          text-align: left; } }
  .product-card_container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    list-style: none; }
    @media (max-width: 575.98px) {
      .product-card_container {
        margin: 30px 0 0; } }
  .product-card a img {
    width: auto;
    height: auto;
    transition: opacity .2s; }
    @media (max-width: 575.98px) {
      .product-card a img {
        width: 100%;
        margin-bottom: 18px; } }
    .product-card a img:hover {
      opacity: .8; }
  @media (min-width: 576px) {
    .product-card_no-img {
      padding-bottom: 50px; } }
  .product-card .h5 {
    margin-bottom: 5px; }
    @media (max-width: 1199.98px) {
      .product-card .h5 {
        font-size: 22px;
        margin-bottom: 6px; } }
  .product-card p {
    line-height: 30px; }
    @media (min-width: 576px) {
      .product-card p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical; } }
    @media (max-width: 1199.98px) {
      .product-card p {
        margin-bottom: 20px; } }
    @media (max-width: 575.98px) {
      .product-card p {
        margin-bottom: 15px; } }
  .product-card > picture > img {
    width: auto;
    height: auto; }
    @media (max-width: 575.98px) {
      .product-card > picture > img {
        width: 100%;
        height: auto;
        object-fit: none;
        margin-bottom: 18px; } }

.cta-block {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 0;
  border-top: 5px solid #fff; }
  @media (max-width: 575.98px) {
    .cta-block {
      padding: 38px 0; } }
  .cta-block_narrow {
    padding: 35px 0 30px; }
    @media (max-width: 575.98px) {
      .cta-block_narrow {
        padding: 28px 0 23px; } }
  .cta-block a {
    margin: 0; }
    @media (max-width: 575.98px) {
      .cta-block a {
        font-size: 18px; } }

.infobox {
  display: flex;
  position: relative;
  background: #F8F8F8;
  padding: 31px 65px 35px;
  margin-bottom: 30px;
  justify-content: space-between; }
  @media (max-width: 575.98px) {
    .infobox {
      padding: 30px 30px 30px 55px;
      margin-bottom: 15px;
      flex-wrap: wrap; } }
  .infobox:last-of-type {
    margin-bottom: 0; }
  .infobox_notice strong:before {
    content: "\e90c"; }
  .infobox_example strong:before {
    content: "\e908"; }
  .infobox p {
    margin-bottom: 0; }
  .infobox ul {
    margin-top: 30px; }
  .infobox img {
    width: 338px;
    height: auto;
    margin-left: 30px;
    transform: translateX(15px); }
    @media (max-width: 575.98px) {
      .infobox img {
        width: 100%;
        height: auto;
        margin: 20px 0 0;
        transform: none; } }
  .infobox strong {
    font-family: 'Roboto';
    font-size: 22px;
    color: #2A2A2A;
    margin-bottom: 13px;
    display: block; }
    .infobox strong:before {
      font-family: 'buw';
      font-size: 24px;
      color: #ee7f01;
      font-weight: 500;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      position: relative;
      margin: 0 20px 0 -33px;
      top: 1px; }

.download-block {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex; }
  @media (max-width: 1199.98px) {
    .download-block {
      flex-wrap: wrap; } }
  .download-block li {
    flex-direction: column;
    display: flex;
    margin: 0 70px 15px 0; }
    @media (max-width: 1199.98px) {
      .download-block li {
        margin: 0 35px 60px 0;
        max-width: 205px; } }
    @media (max-width: 575.98px) {
      .download-block li {
        margin: 0 0 60px 0; } }
    @media (max-width: 575.98px) {
      .download-block li:last-child {
        margin: 0; } }
    .download-block li img {
      display: block;
      max-width: 260px;
      width: 100%;
      height: auto;
      margin-bottom: 25px;
      box-shadow: 0px 0px 30px #e7e8f0; }
      @media (max-width: 575.98px) {
        .download-block li img {
          max-width: 170px; } }

.review-slider {
  max-width: 1180px;
  margin: 0 auto 65px; }
  @media (max-width: 1199.98px) {
    .review-slider {
      margin: 0 auto 30px; } }
  @media (max-width: 575.98px) {
    .review-slider {
      margin: 30px auto 50px; } }
  .review-slider_content {
    max-width: 1120px;
    background: #fff;
    box-shadow: 0px 0px 30px #e7e8f0;
    margin: 26px 35px 40px;
    padding: 55px 75px 60px 60px; }
    @media (max-width: 1199.98px) {
      .review-slider_content {
        padding: 50px 40px;
        max-width: 800px;
        margin: 25px 105px 50px 30px; } }
    @media (max-width: 991.98px) {
      .review-slider_content {
        max-width: 550px; } }
    @media (max-width: 575.98px) {
      .review-slider_content {
        padding: 25px;
        margin: 0 40px 0 0; } }
    .review-slider_content img {
      width: auto !important;
      margin-bottom: -12px; }
      @media (max-width: 575.98px) {
        .review-slider_content img {
          margin: 12px 0 0px; } }
    .review-slider_content p:last-of-type {
      margin: -5px 0 0; }
    .review-slider_content em {
      font-size: 20px;
      color: #4D4D4F; }
      @media (max-width: 575.98px) {
        .review-slider_content em {
          font-size: 18px;
          line-height: 26px;
          display: block; } }
      .review-slider_content em a {
        color: #ee7f01; }
        .review-slider_content em a:hover, .review-slider_content em a:focus {
          color: #131f70; }
  @media (max-width: 1199.98px) {
    .review-slider .splide__track {
      overflow: visible; } }
  .review-slider .splide__pagination {
    bottom: -30px; }
    @media (max-width: 575.98px) {
      .review-slider .splide__pagination {
        bottom: -60px; } }
    .review-slider .splide__pagination li button {
      width: 12px;
      height: 12px;
      margin: 5px;
      opacity: 1;
      background: #E7E8F0;
      transition: background .3s; }
      @media (max-width: 991.98px) {
        .review-slider .splide__pagination li button {
          width: 16px;
          height: 16px;
          margin: 5px 10px 5px; } }
      .review-slider .splide__pagination li button:hover, .review-slider .splide__pagination li button:focus {
        background: #dedfe8; }
      .review-slider .splide__pagination li button.is-active {
        background: #ee7f01;
        transform: none; }
  .review-slider .splide__arrow {
    opacity: .7;
    background: transparent;
    transition: transform .3s; }
    @media (max-width: 575.98px) {
      .review-slider .splide__arrow {
        display: none; } }
    .review-slider .splide__arrow:hover {
      opacity: 1; }
    .review-slider .splide__arrow--prev {
      left: -100px; }
      @media (max-width: 1399.98px) {
        .review-slider .splide__arrow--prev {
          left: -50px; } }
      @media (max-width: 1199.98px) {
        .review-slider .splide__arrow--prev {
          left: -30px; } }
      .review-slider .splide__arrow--prev:hover {
        transform: translateY(-50%) translateX(-5px); }
    .review-slider .splide__arrow--next {
      right: -100px; }
      @media (max-width: 1399.98px) {
        .review-slider .splide__arrow--next {
          right: -50px; } }
      @media (max-width: 1199.98px) {
        .review-slider .splide__arrow--next {
          right: -30px; } }
      .review-slider .splide__arrow--next:hover {
        transform: translateY(-50%) translateX(5px); }
    .review-slider .splide__arrow .arrow:before {
      font-family: 'buw';
      font-size: 38px;
      color: #00000038;
      font-weight: 500;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      position: relative;
      top: 0;
      right: 0; }
    .review-slider .splide__arrow .arrow-prev {
      transition: transform .25s; }
      .review-slider .splide__arrow .arrow-prev:before {
        content: "\e903"; }
    .review-slider .splide__arrow .arrow-next:before {
      content: "\e905"; }
  .review-slider .splide__list {
    margin: 0 auto !important; }

.teaser-slider {
  max-width: 1415px;
  margin: 0 auto 70px; }
  @media (max-width: 1199.98px) {
    .teaser-slider {
      margin: 0 auto 20px; } }
  .teaser-slider .splide__list {
    margin: 0 auto !important; }
    .teaser-slider .splide__list li:nth-child(-n+2):last-child .teaser-card {
      margin-right: 25px !important; }
  .teaser-slider .splide__track {
    padding: 25px 0 0 25px; }
    @media (max-width: 1199.98px) {
      .teaser-slider .splide__track {
        overflow: visible; } }
    @media (max-width: 991.98px) {
      .teaser-slider .splide__track {
        padding: 25px 0 0 112px; } }
    @media (max-width: 575.98px) {
      .teaser-slider .splide__track {
        padding: 10px 0 0; } }
  .teaser-slider .splide__pagination {
    bottom: -30px; }
    .teaser-slider .splide__pagination li button {
      width: 12px;
      height: 12px;
      margin: 5px;
      opacity: 1;
      background: #E7E8F0;
      transition: background .3s; }
      @media (max-width: 991.98px) {
        .teaser-slider .splide__pagination li button {
          width: 16px;
          height: 16px;
          margin: 5px 10px 5px; } }
      .teaser-slider .splide__pagination li button:hover, .teaser-slider .splide__pagination li button:focus {
        background: #dedfe8; }
      .teaser-slider .splide__pagination li button.is-active {
        background: #ee7f01;
        transform: none; }
  .teaser-slider .splide__slide {
    display: flex; }
  .teaser-slider .splide__arrow {
    opacity: .7;
    background: transparent;
    transition: transform .3s; }
    @media (max-width: 1199.98px) {
      .teaser-slider .splide__arrow {
        display: none; } }
    .teaser-slider .splide__arrow:hover {
      opacity: 1; }
    .teaser-slider .splide__arrow--prev {
      left: -100px; }
      .teaser-slider .splide__arrow--prev:hover {
        transform: translateY(-50%) translateX(-5px); }
    .teaser-slider .splide__arrow--next {
      right: -100px; }
      .teaser-slider .splide__arrow--next:hover {
        transform: translateY(-50%) translateX(5px); }
    .teaser-slider .splide__arrow .arrow:before {
      font-family: 'buw';
      font-size: 38px;
      color: #00000038;
      font-weight: 500;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      position: relative;
      top: 0;
      right: 0; }
    .teaser-slider .splide__arrow .arrow-prev {
      transition: transform .25s; }
      .teaser-slider .splide__arrow .arrow-prev:before {
        content: "\e903"; }
    .teaser-slider .splide__arrow .arrow-next:before {
      content: "\e905"; }
  .teaser-slider .teaser-card {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin-right: 80px;
    margin-bottom: 50px;
    box-shadow: 0px 0px 30px #e7e8f0; }
    @media (min-width: 1200px) {
      .teaser-slider .teaser-card {
        max-width: 400px !important; } }
    @media (max-width: 1399.98px) {
      .teaser-slider .teaser-card {
        margin-right: 50px; } }
    .teaser-slider .teaser-card:after {
      bottom: 16px; }
    .teaser-slider .teaser-card_content {
      display: flex;
      height: 100%;
      padding: 41px 31px 17px;
      flex-direction: column;
      flex-wrap: wrap; }
      @media (max-width: 575.98px) {
        .teaser-slider .teaser-card_content {
          padding: 30px 20px 17px; } }
      .teaser-slider .teaser-card_content p {
        line-height: 30px;
        margin-bottom: 30px; }
      .teaser-slider .teaser-card_content .meta-footer {
        align-self: flex-end;
        margin: auto 11px 5px 0; }
    .teaser-slider .teaser-card img {
      width: 100%;
      height: 310px;
      display: block;
      object-fit: cover; }
      @media (max-width: 575.98px) {
        .teaser-slider .teaser-card img {
          width: 100%;
          height: 200px; } }

.overlay-block {
  position: absolute;
  right: 160px;
  top: 0;
  width: 536px;
  background: #fff;
  padding: 40px 40px 50px;
  box-shadow: 0px 0px 30px #e7e8f08c;
  z-index: 99; }
  @media screen and (max-width: 1600px) {
    .overlay-block {
      right: 40px;
      width: 470px;
      padding: 40px 35px 35px; } }
  @media (max-width: 1199.98px) {
    .overlay-block {
      padding: 35px 45px !important;
      margin: 0 0 -70px !important;
      position: relative !important;
      bottom: 0 !important;
      right: 0 !important; } }
  @media (max-width: 575.98px) {
    .overlay-block {
      margin: 0 0 -40px !important;
      padding: 23px 25px !important; } }
  .overlay-block_pulled {
    top: initial;
    bottom: 390px; }
  .overlay-block_gray {
    background: #F8F8F8;
    border: 1px solid #E7E8F0; }
  .overlay-block_bp {
    padding: 50px 60px 60px; }
  .overlay-block_xl {
    right: 35px;
    width: 780px;
    padding: 30px; }
    @media screen and (max-width: 1600px) {
      .overlay-block_xl {
        width: 680px;
        right: 0; } }
    @media (max-width: 1399.98px) {
      .overlay-block_xl {
        width: 43%;
        margin: 30px 0 -70px !important; } }
    @media (max-width: 1199.98px) {
      .overlay-block_xl {
        width: 90%;
        right: 0; } }
    @media (max-width: 991.98px) {
      .overlay-block_xl {
        width: 100%; } }
    @media (max-width: 767.98px) {
      .overlay-block_xl {
        display: none; } }
  .overlay-block p {
    margin-bottom: 23px; }
    .overlay-block p:last-of-type {
      margin: 0; }
  .overlay-block form .col-auto {
    max-width: initial;
    margin-bottom: 12px;
    width: 100%; }
  .overlay-block form .btn {
    margin: 0 auto 0 0; }
    @media (max-width: 575.98px) {
      .overlay-block form .btn {
        width: 100%; } }
  .overlay-block form .txt-sm a {
    color: #ee7f01;
    font-weight: 500;
    text-decoration: underline; }
    .overlay-block form .txt-sm a:hover {
      color: #131f70; }
  .overlay-block form .wpcf7-acceptance .wpcf7-list-item {
    margin: 0 0 17px; }

.newsletter-block {
  padding: 40px 0 32px; }
  @media (max-width: 1199.98px) {
    .newsletter-block {
      padding: 40px 0 20px; } }
  @media (max-width: 575.98px) {
    .newsletter-block {
      padding: 40px 0 30px;
      margin-bottom: 25px; } }
  .newsletter-block #newsletterForm {
    display: flex;
    max-width: 1150px;
    justify-content: center;
    align-items: center;
    margin: 0 auto; }
    @media (max-width: 1199.98px) {
      .newsletter-block #newsletterForm {
        max-width: auto;
        flex-wrap: wrap;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 80px; } }
    @media (max-width: 575.98px) {
      .newsletter-block #newsletterForm {
        padding: 0 35px; } }
    .newsletter-block #newsletterForm .form-wrap {
      display: flex;
      align-items: flex-start;
      width: 100%;
      max-width: 685px; }
      @media (max-width: 1199.98px) {
        .newsletter-block #newsletterForm .form-wrap {
          justify-content: space-between;
          width: 100%;
          max-width: initial; } }
      @media (max-width: 1199.98px) {
        .newsletter-block #newsletterForm .form-wrap {
          flex-wrap: wrap; } }
    .newsletter-block #newsletterForm .form-group {
      min-width: 430px;
      margin: 0 20px 0 84px !important; }
      @media (max-width: 1199.98px) {
        .newsletter-block #newsletterForm .form-group {
          min-width: auto;
          max-width: calc(100% - 270px);
          margin: 0 15px 20px 0 !important; } }
      @media (max-width: 575.98px) {
        .newsletter-block #newsletterForm .form-group {
          min-width: auto;
          max-width: 100%;
          margin: 0 0 20px !important; } }
      .newsletter-block #newsletterForm .form-group .form-control {
        color: #131f70;
        background: #fff;
        border: 1px solid #E7E8F0; }
        .newsletter-block #newsletterForm .form-group .form-control:focus {
          border: 1px solid #131f70; }
        .newsletter-block #newsletterForm .form-group .form-control::placeholder {
          color: #999; }
        .newsletter-block #newsletterForm .form-group .form-control select {
          font-size: 16px;
          font-style: italic;
          border: 0; }
      .newsletter-block #newsletterForm .form-group label {
        font-size: 16px;
        font-style: italic;
        color: #999;
        margin: 0;
        height: auto;
        line-height: 32px; }
        @media (max-width: 575.98px) {
          .newsletter-block #newsletterForm .form-group label {
            font-size: 16px;
            line-height: 24px;
            width: 230px;
            margin-top: 5px; } }
    .newsletter-block #newsletterForm .form-check-inline {
      display: inline-block;
      margin: 7px 0 0 -6px;
      width: calc(100% + 50px); }
      @media (max-width: 575.98px) {
        .newsletter-block #newsletterForm .form-check-inline {
          width: 100%; } }
    .newsletter-block #newsletterForm .form-check-input {
      margin-right: 19px; }
      @media (max-width: 575.98px) {
        .newsletter-block #newsletterForm .form-check-input {
          margin-right: 15px; } }
      .newsletter-block #newsletterForm .form-check-input[type="checkbox"] {
        background: transparent; }
        .newsletter-block #newsletterForm .form-check-input[type="checkbox"]:before {
          content: "" !important;
          cursor: pointer !important;
          background: transparent;
          display: inline-block !important;
          height: 25px !important;
          width: 25px !important;
          border: 2px solid #E7E8F0;
          border-radius: 6px; }
          @media (max-width: 575.98px) {
            .newsletter-block #newsletterForm .form-check-input[type="checkbox"]:before {
              height: 22px !important;
              width: 22px !important;
              border-radius: 4px; } }
    .newsletter-block #newsletterForm input[type="checkbox"]:checked:before {
      background: url(../../../Resources/Public/Images/check.svg) no-repeat 3px 5px;
      background-size: 14px; }
      @media (max-width: 575.98px) {
        .newsletter-block #newsletterForm input[type="checkbox"]:checked:before {
          background: url(../../../Resources/Public/Images/check.svg) no-repeat 2px 4px; } }
    .newsletter-block #newsletterForm .btn {
      min-width: 250px; }
    .newsletter-block #newsletterForm a {
      color: #ee7f01; }
      .newsletter-block #newsletterForm a:hover, .newsletter-block #newsletterForm a:focus {
        color: #131f70; }
    @media (max-width: 1199.98px) {
      .newsletter-block #newsletterForm h3, .newsletter-block #newsletterForm .h3 {
        margin-bottom: 20px; } }

#o365-list-items-outlook_cal_o365 {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  list-style: none; }
  @media (min-width: 992px) {
    #o365-list-items-outlook_cal_o365:after {
      content: "";
      width: 520px; } }
  @media (min-width: 768px) {
    #o365-list-items-outlook_cal_o365:after {
      content: "";
      width: 470px; } }
  #o365-list-items-outlook_cal_o365 .o365-list__item,
  #o365-list-items-outlook_cal_o365 .news-card {
    margin: 0 40px 40px 0 !important; }
    #o365-list-items-outlook_cal_o365 .o365-list__item:after,
    #o365-list-items-outlook_cal_o365 .news-card:after {
      content: initial; }
    #o365-list-items-outlook_cal_o365 .o365-list__item:last-child,
    #o365-list-items-outlook_cal_o365 .news-card:last-child {
      margin-right: 0 !important; }
    #o365-list-items-outlook_cal_o365 .o365-list__item:hover, #o365-list-items-outlook_cal_o365 .o365-list__item:focus,
    #o365-list-items-outlook_cal_o365 .news-card:hover,
    #o365-list-items-outlook_cal_o365 .news-card:focus {
      box-shadow: 0px 0px 30px #dedfea; }
      #o365-list-items-outlook_cal_o365 .o365-list__item:hover .h4, #o365-list-items-outlook_cal_o365 .o365-list__item:hover .h5, #o365-list-items-outlook_cal_o365 .o365-list__item:focus .h4, #o365-list-items-outlook_cal_o365 .o365-list__item:focus .h5,
      #o365-list-items-outlook_cal_o365 .news-card:hover .h4,
      #o365-list-items-outlook_cal_o365 .news-card:hover .h5,
      #o365-list-items-outlook_cal_o365 .news-card:focus .h4,
      #o365-list-items-outlook_cal_o365 .news-card:focus .h5 {
        color: initial; }
      #o365-list-items-outlook_cal_o365 .o365-list__item:hover img, #o365-list-items-outlook_cal_o365 .o365-list__item:focus img,
      #o365-list-items-outlook_cal_o365 .news-card:hover img,
      #o365-list-items-outlook_cal_o365 .news-card:focus img {
        opacity: 1; }
    #o365-list-items-outlook_cal_o365 .o365-list__item .meta-footer,
    #o365-list-items-outlook_cal_o365 .news-card .meta-footer {
      display: flex;
      justify-content: flex-end;
      text-shadow: none; }

#calendar_ook_cal_o365 {
  display: flex;
  flex-wrap: wrap;
  margin: 45px 0 -45px; }
  #calendar_ook_cal_o365 .fc-header-toolbar {
    display: flex;
    order: 2;
    justify-content: space-between;
    width: 100%; }
    #calendar_ook_cal_o365 .fc-header-toolbar .fc-center {
      position: absolute;
      left: 31px;
      top: 30px; }
      #calendar_ook_cal_o365 .fc-header-toolbar .fc-center h2, #calendar_ook_cal_o365 .fc-header-toolbar .fc-center .h2 {
        font-size: 1.2rem;
        line-height: 1.6rem;
        font-weight: 700;
        margin-bottom: 1.1rem; }
    #calendar_ook_cal_o365 .fc-header-toolbar .fc-left,
    #calendar_ook_cal_o365 .fc-header-toolbar .fc-right {
      float: none; }
    #calendar_ook_cal_o365 .fc-header-toolbar .fc-clear,
    #calendar_ook_cal_o365 .fc-header-toolbar .fc-listMonth-button,
    #calendar_ook_cal_o365 .fc-header-toolbar .fc-printButton-button {
      display: none; }
    #calendar_ook_cal_o365 .fc-header-toolbar .fc-button {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 600;
      color: #898fb7;
      padding: 10px 14px 8px;
      margin-right: 10px !important;
      background: #f8f8fb;
      border-radius: 5px;
      border: 2px solid #d0d2e2;
      height: auto;
      box-shadow: none;
      text-shadow: none;
      transition: background .35s,border-color .35s,color .25s; }
      #calendar_ook_cal_o365 .fc-header-toolbar .fc-button:hover, #calendar_ook_cal_o365 .fc-header-toolbar .fc-button:focus, #calendar_ook_cal_o365 .fc-header-toolbar .fc-button.fc-state-active {
        background: #131f70;
        border-color: #131f70;
        color: #fff; }
      #calendar_ook_cal_o365 .fc-header-toolbar .fc-button[disabled] {
        pointer-events: none; }
  #calendar_ook_cal_o365 .fc-head-container .fc-widget-header {
    margin-right: 0px !important;
    border: 0; }
  #calendar_ook_cal_o365 .o365-cal-no-records {
    display: none; }
  #calendar_ook_cal_o365 .fc-scroller.fc-day-grid-container {
    overflow: hidden scroll !important;
    max-height: 500px; }
  #calendar_ook_cal_o365 td.fc-today {
    background: #ee7f010f !important; }
  #calendar_ook_cal_o365 .fc-basic-view .fc-day-number {
    background-color: transparent;
    color: #898FB7;
    font-size: 12px;
    text-align: center; }
  #calendar_ook_cal_o365 .fc-basicWeek-view .fc-row.fc-week.fc-widget-content {
    height: 500px !important; }
  #calendar_ook_cal_o365 table .fc-day-header {
    padding: 6px 10px;
    font-size: 12px;
    color: #fff;
    background-color: #131f70;
    text-transform: uppercase;
    font-weight: 400;
    border: 0; }
  #calendar_ook_cal_o365 table td.fc-day.fc-widget-content.fc-thu.fc-today {
    background: #131f700d; }
  #calendar_ook_cal_o365 table td a.fc-day-grid-event.fc-event {
    background-color: #ee7f01 !important;
    border: 0;
    border-radius: 5px;
    padding: 4px 12px;
    margin: 5px 8px;
    transition: background .2s,color .2s; }
    @media screen and (max-width: 1600px) {
      #calendar_ook_cal_o365 table td a.fc-day-grid-event.fc-event {
        padding: 4px 9px;
        margin: 5px 5px; } }
    #calendar_ook_cal_o365 table td a.fc-day-grid-event.fc-event:hover, #calendar_ook_cal_o365 table td a.fc-day-grid-event.fc-event:focus {
      background: #dd7602 !important;
      color: #fff; }
    #calendar_ook_cal_o365 table td a.fc-day-grid-event.fc-event .fc-content {
      padding: 0;
      text-align: left;
      font-size: 12px;
      font-weight: 500; }
    #calendar_ook_cal_o365 table td a.fc-day-grid-event.fc-event .fc-time {
      font-weight: 500; }
    #calendar_ook_cal_o365 table td a.fc-day-grid-event.fc-event .fc-title {
      text-transform: capitalize;
      font-weight: 500;
      display: none; }
    #calendar_ook_cal_o365 table td a.fc-day-grid-event.fc-event .fc-header-toolbar h2, #calendar_ook_cal_o365 table td a.fc-day-grid-event.fc-event .fc-header-toolbar .h2 {
      font-size: 24px;
      font-weight: 600; }
    #calendar_ook_cal_o365 table td a.fc-day-grid-event.fc-event .ms-Icon {
      display: none; }

select#change_ook_cal_o365 {
  display: none; }

.fc-unthemed .fc-content, .fc-unthemed .fc-divider, .fc-unthemed .fc-list-heading td, .fc-unthemed .fc-list-view, .fc-unthemed .fc-popover, .fc-unthemed .fc-row, .fc-unthemed tbody, .fc-unthemed td, .fc-unthemed th, .fc-unthemed thead {
  border-color: #E7E8F0; }

#calendar_ook_cal_o365 table td a.fc-day-grid-event.fc-event .fc-time:after {
  content: " Uhr"; }

html[lang="en-US"] #calendar_ook_cal_o365 table td a.fc-day-grid-event.fc-event .fc-time:after {
  content: " "; }

.old-event {
  background: #ee7f01;
  padding: 10px 20px 9px;
  color: #fff;
  border-radius: 6px;
  margin: 0 15px;
  display: inline;
  font-size: 16px;
  font-weight: 600;
  vertical-align: bottom; }
  @media (max-width: 575.98px) {
    .old-event {
      padding: 2px 15px 0;
      margin: 8px 0;
      font-size: 15px;
      display: inline-block; } }

.downloadbtn {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px; }
  .downloadbtn a, .downloadbtn .btn {
    margin-right: 15px; }

body .tribe-common header.tribe-events-header {
  z-index: 9;
  box-shadow: none; }

body .tribe-common header.tribe-events-calendar-month__header {
  z-index: 0;
  box-shadow: none; }

body .tribe-common .tribe-events .tribe-events-c-view-selector__list-item-icon {
  width: 20px;
  margin-bottom: 17px; }

body .tribe-common div#tribe-events-view-selector-content {
  min-width: 150px; }

.tribe-events .datepicker .day.active, .tribe-events .datepicker .day.active.focused, .tribe-events .datepicker .day.active:focus, .tribe-events .datepicker .day.active:hover, .tribe-events .datepicker .month.active, .tribe-events .datepicker .month.active.focused, .tribe-events .datepicker .month.active:focus, .tribe-events .datepicker .month.active:hover, .tribe-events .datepicker .year.active, .tribe-events .datepicker .year.active.focused, .tribe-events .datepicker .year.active:focus, .tribe-events .datepicker .year.active:hover {
  background: #131f70 !important; }

.tribe-common .tribe-common-c-btn-border, .tribe-common a.tribe-common-c-btn-border {
  border: 1px solid #131f70 !important;
  color: #131f70 !important; }

.tribe-events .tribe-events-c-subscribe-dropdown .tribe-events-c-subscribe-dropdown__button.tribe-events-c-subscribe-dropdown__button--active, .tribe-events .tribe-events-c-subscribe-dropdown .tribe-events-c-subscribe-dropdown__button:focus, .tribe-events .tribe-events-c-subscribe-dropdown .tribe-events-c-subscribe-dropdown__button:focus-within, .tribe-events .tribe-events-c-subscribe-dropdown .tribe-events-c-subscribe-dropdown__button:hover {
  color: #fff !important;
  background-color: #131f70 !important; }

header.tribe-events-calendar-list__event-header {
  z-index: 0 !important;
  box-shadow: none !important; }

.tribe-events .tribe-events-c-view-selector__list-item-icon {
  height: 36px !important; }

header.tribe-events-calendar-latest-past__event-header {
  background: transparent !important;
  box-shadow: none !important; }

.tribe-events-c-subscribe-dropdown__button-text {
  transition: color 0s; }

button.tribe-common-h3.tribe-common-h--alt.tribe-events-c-top-bar__datepicker-button {
  font-size: 18px !important; }

.tribe-common-l-container.tribe-events-l-container,
#tribe-events-pg-template {
  max-width: 100% !important;
  padding: 0 !important; }

.tribe-events-tooltip-theme {
  display: none !important; }

.tribe-events .tribe-events-calendar-month__day-date-daynum {
  color: #999 !important;
  font-weight: 300 !important;
  font-size: 20px !important; }

.tribe-events .tribe-events-calendar-month__day-date-link {
  color: #131f70 !important;
  font-weight: 600 !important; }

h1.tribe-events-single-event-title, .tribe-events-single-event-title.h1,
.tribe-events-pg-template h1,
.tribe-events-pg-template .h1 {
  font-size: 2.5rem;
  line-height: 3rem;
  font-weight: 500;
  margin-bottom: 0.25rem; }
  @media (max-width: 991.98px) {
    h1.tribe-events-single-event-title, .tribe-events-single-event-title.h1,
    .tribe-events-pg-template h1,
    .tribe-events-pg-template .h1 {
      font-size: 38px;
      line-height: 48px; } }
  @media (max-width: 575.98px) {
    h1.tribe-events-single-event-title, .tribe-events-single-event-title.h1,
    .tribe-events-pg-template h1,
    .tribe-events-pg-template .h1 {
      font-size: 1.4rem;
      line-height: 1.7rem;
      margin-bottom: 1.3rem; } }
  @media screen and (max-width: 360px) {
    h1.tribe-events-single-event-title, .tribe-events-single-event-title.h1,
    .tribe-events-pg-template h1,
    .tribe-events-pg-template .h1 {
      font-size: 1.35rem;
      line-height: 1.6rem;
      width: calc(100% + 20px); } }

.tribe-events-schedule.tribe-clearfix {
  margin: 0 0 40px; }
  .tribe-events-schedule.tribe-clearfix h2, .tribe-events-schedule.tribe-clearfix .h2 {
    color: #131f70;
    font-size: 18px; }


.tribe-events-pg-template h1,
.tribe-events-pg-template .h1,
.tribe-events-pg-template h2,
.tribe-events-pg-template .h2,
.tribe-events-pg-template h3,
.tribe-events-pg-template .h3,
.tribe-events-pg-template h4,
.tribe-events-pg-template .h4 {
  font-family: Roboto !important; }

header.tribe-events-calendar-day__event-header {
  background: transparent;
  box-shadow: none; }

.tribe-events-single-event-description.tribe-events-content {
  max-width: 996px !important; }

.tribe-events-single {
  max-width: 84rem !important;
  margin: 0 auto !important;
  padding: 0 var(--bs-gutter-x, 0.75rem) 0 !important; }

.tribe-events-view {
  position: relative;
  width: 100%;
  max-width: 84rem !important;
  margin: 0 auto !important;
  padding: 0 var(--bs-gutter-x, 0.75rem) 0 !important; }

.tribe-events-back a {
  font-family: "Roboto" !important;
  font-size: 0.9rem !important;
  font-weight: 600 !important;
  color: #fff !important;
  padding: 0.95rem 1.55rem 0.8rem !important;
  border-radius: 0.25rem !important;
  background: #131f70 !important;
  text-decoration: none !important;
  margin-bottom: 1rem !important;
  border: 0 !important;
  transition: background .3s, color .2s, border-color .3s; }
  @media (max-width: 575.98px) {
    .tribe-events-back a {
      padding: 0.95rem 1.1rem 0.85rem !important;
      margin-bottom: 0.75rem !important; } }
  .tribe-events-back a:hover, .tribe-events-back a:focus {
    color: #fff !important;
    background: #131F70CC !important;
    cursor: pointer !important;
    text-decoration: none !important; }

:root {
  --tec-color-accent-primary: #131f70 !important;
  --tec-color-background-events-bar-submit-button: #131f70 !important;
  --tec-color-background-events-bar-submit-button-hover: #131f70 !important;
  --tec-color-background-events-bar-submit-button-active: #131f70 !important;
  --tec-form-color-accent-primary: #131f70 !important;
  --form-color-accent-primary: #131f70 !important;
  --tec-font-family-sans-serif: Lato,sans-serif !important;
  --tec-font-size-2: 16px !important; }

form .form-group {
  width: 100%;
  margin-bottom: 10px; }
  form .form-group input,
  form .form-group select {
    font-size: 16px;
    font-style: italic;
    border: 0; }

.form-control {
  padding: 20px 20px 18px;
  border: 1px solid #E7E8F0;
  font-size: 16px;
  font-style: italic;
  color: #999999;
  background: #fff;
  transition: border .3s; }
  @media (max-width: 575.98px) {
    .form-control {
      padding: 18px 15px 16px; } }
  .form-control:hover {
    border: 1px solid #E7E8F0; }
  .form-control:focus {
    color: #999999;
    border-color: #131f70;
    outline: none;
    box-shadow: none; }

form .form-group input:focus, form .form-group select:focus {
  box-shadow: none; }

#demoRequest,
.wpcf7-form {
  max-width: 880px;
  margin: 0 auto;
  text-align: center; }
  #demoRequest .sr-only,
  .wpcf7-form .sr-only {
    display: none; }
  #demoRequest .col-auto,
  .wpcf7-form .col-auto {
    margin-bottom: 20px;
    max-width: 430px;
    width: 100%; }
    @media (max-width: 1199.98px) {
      #demoRequest .col-auto,
      .wpcf7-form .col-auto {
        width: calc(50% - 5px);
        margin-bottom: 10px; } }
    @media (max-width: 575.98px) {
      #demoRequest .col-auto,
      .wpcf7-form .col-auto {
        width: 100%;
        margin-bottom: 10px; } }
  #demoRequest .form-row,
  .wpcf7-form .form-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  #demoRequest br,
  .wpcf7-form br {
    display: none; }
  #demoRequest .btn,
  .wpcf7-form .btn {
    display: block;
    margin: 0 auto; }

.txt-sm {
  font-size: 16px;
  line-height: 22px;
  font-style: italic;
  color: #999999;
  text-align: left;
  padding: 0;
  margin: 0; }
  .txt-sm a {
    color: inherit;
    text-decoration: underline; }
    .txt-sm a:hover, .txt-sm a:focus {
      color: #131f70; }

.wpcf7-not-valid-tip {
  color: #fff !important;
  font-size: 0px !important;
  background: #ee7f01;
  position: relative;
  z-index: 99;
  height: 3px;
  left: 1px;
  margin-bottom: -4px;
  width: calc(100% - 2px); }

.wpcf7 form .wpcf7-response-output {
  margin: 10px 0 !important;
  border: 0 !important;
  background: #ee7f01 !important;
  color: #fff !important;
  font-size: 18px !important;
  padding: 11px !important;
  border-radius: 3px !important; }
  @media (max-width: 575.98px) {
    .wpcf7 form .wpcf7-response-output {
      font-size: 16px !important;
      line-height: 22px !important; } }

.wpcf7-response-output.wpcf7-validation-errors {
  padding: 10px 20px;
  background: #131f70;
  color: #fff;
  font-size: 15px;
  border-radius: 5px; }

.screen-reader-response {
  display: none; }

.wpcf7-form .wpcf7-acceptance .wpcf7-list-item {
  margin: 0 0 30px; }
  @media (max-width: 575.98px) {
    .wpcf7-form .wpcf7-acceptance .wpcf7-list-item {
      margin: 15px 0 20px; } }
  .wpcf7-form .wpcf7-acceptance .wpcf7-list-item > label {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start; }

.wpcf7-form input[type="checkbox"],
.wpcf7-form input#datenschutz {
  opacity: 1;
  margin-right: 20px;
  height: 25px;
  width: 25px;
  background: #fff;
  display: inline-block !important;
  box-shadow: none;
  transform: translate(1px, -2px); }
  @media (max-width: 575.98px) {
    .wpcf7-form input[type="checkbox"],
    .wpcf7-form input#datenschutz {
      margin-right: 12px; } }
  .wpcf7-form input[type="checkbox"]:before,
  .wpcf7-form input#datenschutz:before {
    content: "" !important;
    cursor: pointer !important;
    background: #fff;
    display: inline-block !important;
    height: 25px !important;
    width: 25px !important;
    border: 1px solid #E7E8F0; }
  .wpcf7-form input[type="checkbox"]:checked:before,
  .wpcf7-form input#datenschutz:checked:before {
    background: url(../../../Resources/Public/Images/check.svg) no-repeat #fff;
    background-size: 13px;
    background-position: center; }

.wpcf7-form input[type="checkbox"] {
  display: none; }

.wpcf7-form input[type="checkbox"] + label {
  width: 19px;
  height: 19px;
  border-radius: 2px;
  border: 2px solid #999999;
  display: inline-block;
  content: "";
  float: left;
  margin-right: 10px;
  transition: background-color .3s ease,border-color .3s ease;
  margin-top: 1px;
  left: -27px;
  position: absolute; }

.wpcf7-form input[type="checkbox"] + label:hover {
  cursor: pointer; }

.wpcf7-form input[type="checkbox"] + label::before {
  width: 20px;
  height: 20px;
  margin: 2px 2px;
  border-radius: 10px;
  background-color: transparent;
  display: block;
  content: "";
  transition: margin .1s linear; }

.wpcf7-form input[type="checkbox"]:checked + label {
  background-color: #ee7f01;
  border-color: #ee7f01; }

.wpcf7-form input[type="checkbox"]:checked + label::before {
  background: #fff;
  background: url(../../../Resources/Public/Images/check.svg) no-repeat -1px -2px;
  background-size: 14px; }

.wpcf7 .ajax-loader {
  visibility: hidden;
  display: inline-block;
  background-color: #23282d;
  opacity: 0.75;
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 100%;
  padding: 0;
  position: absolute;
  left: 0;
  right: 0;
  margin: 15px auto; }

.post-password-form input, .post-password-form .form-control {
  border: 1px solid #e7e8f0;
  margin-bottom: 25px; }

/* change placeholder color */
::placeholder {
  color: #9f9f9f;
  opacity: 1; }

:-ms-input-placeholder {
  color: #9f9f9f; }

::-ms-input-placeholder {
  color: #9f9f9f; }

#expandedNewsletterForm .form-group {
  width: 100%; }
  #expandedNewsletterForm .form-group .form-control {
    color: #131f70;
    background: #fff;
    border: 1px solid #E7E8F0;
    margin-bottom: 12px; }
    #expandedNewsletterForm .form-group .form-control:focus {
      border: 1px solid #131f70; }
    #expandedNewsletterForm .form-group .form-control::placeholder {
      color: #999; }
    #expandedNewsletterForm .form-group .form-control select {
      font-size: 16px;
      font-style: italic;
      border: 0; }
  #expandedNewsletterForm .form-group label {
    font-size: 16px;
    font-style: italic;
    color: #999;
    margin: 0;
    height: auto;
    line-height: 32px; }
    @media (max-width: 575.98px) {
      #expandedNewsletterForm .form-group label {
        font-size: 16px;
        line-height: 24px;
        width: 205px;
        margin-top: 5px; } }

#expandedNewsletterForm .form-check-inline {
  margin: 8px 0 23px -3px; }
  @media (max-width: 575.98px) {
    #expandedNewsletterForm .form-check-inline {
      margin: 8px 0 17px -3px; } }

#expandedNewsletterForm .form-check-input {
  margin-right: 19px; }
  @media (max-width: 575.98px) {
    #expandedNewsletterForm .form-check-input {
      margin-right: 15px; } }
  #expandedNewsletterForm .form-check-input[type="checkbox"] {
    background: transparent; }
    #expandedNewsletterForm .form-check-input[type="checkbox"]:before {
      content: "" !important;
      cursor: pointer !important;
      background: transparent;
      display: inline-block !important;
      height: 25px !important;
      width: 25px !important;
      border: 2px solid #E7E8F0;
      border-radius: 6px; }
      @media (max-width: 575.98px) {
        #expandedNewsletterForm .form-check-input[type="checkbox"]:before {
          height: 22px !important;
          width: 22px !important;
          border-radius: 4px; } }

#expandedNewsletterForm input[type="checkbox"]:checked:before {
  background: url(../../../Resources/Public/Images/check.svg) no-repeat 3px 5px;
  background-size: 14px; }

#expandedNewsletterForm a {
  color: #ee7f01; }
  #expandedNewsletterForm a:hover, #expandedNewsletterForm a:focus {
    color: #131f70; }

.post-password-form p:first-of-type {
  display: none; }

#functionsTabContent {
  margin: 0; }
  @media (max-width: 991.98px) {
    #functionsTabContent {
      margin-bottom: 30px; } }
  @media (max-width: 575.98px) {
    #functionsTabContent {
      margin-bottom: 5px; } }
  #functionsTabContent img {
    width: auto;
    height: auto;
    max-width: 100%;
    padding: 30px 60px 30px 30px;
    margin: 20px 0;
    background: #fff;
    box-shadow: 0px 0px 30px #e7e8f0;
    width: fit-content;
    display: block; }
    @media (max-width: 575.98px) {
      #functionsTabContent img {
        padding: 10px; } }
    #functionsTabContent img.alignright {
      margin: 15px 0 20px 20px; }
      @media (max-width: 575.98px) {
        #functionsTabContent img.alignright {
          float: none; } }
    #functionsTabContent img.alignleft {
      margin: 15px 20px 20px 0px; }
      @media (max-width: 575.98px) {
        #functionsTabContent img.alignleft {
          float: none; } }

@media (max-width: 575.98px) {
  .tab-container {
    padding-left: 0 !important; } }

@media (max-width: 767.98px) {
  .tab-container {
    padding-left: 0.75rem !important; } }

.tab-container #functionsTab {
  width: calc(100% + 10px);
  transform: translateX(-10px); }
  @media (max-width: 1199.98px) {
    .tab-container #functionsTab {
      width: calc(100% + 50px);
      transform: translateX(-50px); } }
  @media (max-width: 991.98px) {
    .tab-container #functionsTab {
      width: 100%;
      transform: none; } }
  @media (max-width: 575.98px) {
    .tab-container #functionsTab {
      margin-bottom: -30px; } }

.tab-container .nav-pills .nav-link {
  font-size: 20px;
  font-weight: 700;
  color: #606060;
  background: #EAEAEA;
  border-radius: 0;
  border-left: 10px solid transparent;
  padding: 22px 40px 20px 63px;
  margin-bottom: 10px;
  transition: background .2s;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }
  @media (max-width: 1199.98px) {
    .tab-container .nav-pills .nav-link {
      font-size: 18px;
      padding: 20px 15px 18px 50px;
      margin-bottom: 16px;
      border-left: 8px solid transparent; } }
  @media (max-width: 575.98px) {
    .tab-container .nav-pills .nav-link {
      padding: 17px 15px 15px 50px; } }
  .tab-container .nav-pills .nav-link:hover, .tab-container .nav-pills .nav-link:focus {
    background: #e1e1e1; }
  .tab-container .nav-pills .nav-link.active {
    color: #2A2A2A;
    background: #fff;
    box-shadow: 0px 0px 30px #e7e8f0;
    border-left: 10px solid #ee7f01; }
    @media (max-width: 1199.98px) {
      .tab-container .nav-pills .nav-link.active {
        border-left: 8px solid #ee7f01; } }
    .tab-container .nav-pills .nav-link.active:before {
      font-family: 'buw';
      content: "\e900";
      font-size: 13px;
      font-weight: 500;
      color: #ee7f01;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      transition: transform .25s;
      position: absolute;
      top: 27px;
      left: 30px; }
      @media (max-width: 1199.98px) {
        .tab-container .nav-pills .nav-link.active:before {
          top: 24px;
          left: 20px; } }
      @media (max-width: 575.98px) {
        .tab-container .nav-pills .nav-link.active:before {
          top: 21px; } }
    .tab-container .nav-pills .nav-link.active:hover {
      color: #2A2A2A;
      background: #fff;
      box-shadow: 0px 0px 30px #e7e8f0;
      border-left: 10px solid #ee7f01; }
      @media (max-width: 1199.98px) {
        .tab-container .nav-pills .nav-link.active:hover {
          border-left: 8px solid #ee7f01; } }

.tab-container .show > .nav-link {
  color: #fff;
  background: linear-gradient(180deg, #c1c1c1, #797979); }

@keyframes splide-loading {
  0% {
    transform: rotate(0); }
  to {
    transform: rotate(1turn); } }

.splide__container {
  position: relative;
  box-sizing: border-box; }

.splide__list {
  margin: 0 !important;
  padding: 0 !important;
  width: -webkit-max-content;
  width: max-content;
  will-change: transform; }

.splide.is-active .splide__list {
  display: flex; }

.splide__pagination {
  display: inline-flex;
  align-items: center;
  width: 95%;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0; }

.splide__pagination li {
  list-style-type: none;
  display: inline-block;
  line-height: 1;
  margin: 0; }

.splide {
  visibility: hidden; }

.splide, .splide__slide {
  position: relative;
  outline: none; }

.splide__slide {
  box-sizing: border-box;
  list-style-type: none !important;
  margin: 0;
  flex-shrink: 0; }

.splide__slide img {
  vertical-align: bottom; }

.splide__slider {
  position: relative; }

.splide__spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #999;
  border-left-color: transparent;
  animation: splide-loading 1s linear infinite; }

.splide__track {
  position: relative;
  z-index: 0;
  overflow: hidden; }

.splide--draggable > .splide__track > .splide__list > .splide__slide {
  -webkit-user-select: none;
  user-select: none; }

.splide--fade > .splide__track > .splide__list {
  display: block; }

.splide--fade > .splide__track > .splide__list > .splide__slide {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0; }

.splide--fade > .splide__track > .splide__list > .splide__slide.is-active {
  position: relative;
  z-index: 1;
  opacity: 1; }

.splide--rtl {
  direction: rtl; }

.splide--ttb > .splide__track > .splide__list {
  display: block; }

.splide--ttb > .splide__pagination {
  width: auto; }

.splide__arrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  width: 2em;
  height: 2em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0;
  opacity: .7;
  background: #ccc; }

.splide__arrow svg {
  width: 1.2em;
  height: 1.2em; }

.splide__arrow:hover {
  cursor: pointer;
  opacity: .9; }

.splide__arrow:focus {
  outline: none; }

.splide__arrow--prev {
  left: 1em; }

.splide__arrow--prev svg {
  transform: scaleX(-1); }

.splide__arrow--next {
  right: 1em; }

.splide__pagination {
  position: absolute;
  z-index: 1;
  bottom: .5em;
  left: 50%;
  transform: translateX(-50%);
  padding: 0; }

.splide__pagination__page {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #ccc;
  border-radius: 50%;
  margin: 3px;
  padding: 0;
  transition: transform .2s linear;
  border: none;
  opacity: .7; }

.splide__pagination__page.is-active {
  transform: scale(1.4);
  background: #fff; }

.splide__pagination__page:hover {
  cursor: pointer;
  opacity: .9; }

.splide__pagination__page:focus {
  outline: none; }

.splide__progress__bar {
  width: 0;
  height: 3px;
  background: #ccc; }

.splide--nav > .splide__track > .splide__list > .splide__slide {
  border: 3px solid transparent; }

.splide--nav > .splide__track > .splide__list > .splide__slide.is-active {
  border-color: #000; }

.splide--nav > .splide__track > .splide__list > .splide__slide:focus {
  outline: none; }

.splide--rtl > .splide__arrows .splide__arrow--prev, .splide--rtl > .splide__track > .splide__arrows .splide__arrow--prev {
  right: 1em;
  left: auto; }

.splide--rtl > .splide__arrows .splide__arrow--prev svg, .splide--rtl > .splide__track > .splide__arrows .splide__arrow--prev svg {
  transform: scaleX(1); }

.splide--rtl > .splide__arrows .splide__arrow--next, .splide--rtl > .splide__track > .splide__arrows .splide__arrow--next {
  left: 1em;
  right: auto; }

.splide--rtl > .splide__arrows .splide__arrow--next svg, .splide--rtl > .splide__track > .splide__arrows .splide__arrow--next svg {
  transform: scaleX(-1); }

.splide--ttb > .splide__arrows .splide__arrow, .splide--ttb > .splide__track > .splide__arrows .splide__arrow {
  left: 50%;
  transform: translate(-50%); }

.splide--ttb > .splide__arrows .splide__arrow--prev, .splide--ttb > .splide__track > .splide__arrows .splide__arrow--prev {
  top: 1em; }

.splide--ttb > .splide__arrows .splide__arrow--prev svg, .splide--ttb > .splide__track > .splide__arrows .splide__arrow--prev svg {
  transform: rotate(-90deg); }

.splide--ttb > .splide__arrows .splide__arrow--next, .splide--ttb > .splide__track > .splide__arrows .splide__arrow--next {
  top: auto;
  bottom: 1em; }

.splide--ttb > .splide__arrows .splide__arrow--next svg, .splide--ttb > .splide__track > .splide__arrows .splide__arrow--next svg {
  transform: rotate(90deg); }

.splide--ttb > .splide__pagination {
  display: flex;
  flex-direction: column;
  bottom: 50%;
  left: auto;
  right: .5em;
  transform: translateY(50%); }

#videoCarousel {
  position: relative;
  perspective: 41.5rem;
  margin: 12px auto 55px;
  width: 41.5rem;
  height: 24rem;
  display: flex; }
  @media (max-width: 991.98px) {
    #videoCarousel {
      height: 17.5rem;
      margin: 0 auto;
      width: 100%; } }
  @media (max-width: 767.98px) {
    #videoCarousel {
      height: 14.5rem; } }
  @media (max-width: 575.98px) {
    #videoCarousel {
      margin: 0px auto -20px;
      height: 12rem; } }
  #videoCarousel .item {
    overflow: hidden;
    cursor: pointer;
    width: 100%;
    height: 462px;
    border-radius: 6px !important; }
    @media (max-width: 991.98px) {
      #videoCarousel .item {
        width: 24rem;
        height: 15rem; } }
    @media (max-width: 767.98px) {
      #videoCarousel .item {
        width: 100%;
        height: 13.5rem; } }
    @media (max-width: 575.98px) {
      #videoCarousel .item {
        height: 10rem; } }
    #videoCarousel .item:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      background: rgba(255, 255, 255, 0.6);
      width: 100%;
      height: 100%;
      transition: background .5s; }
    #videoCarousel .item iframe {
      pointer-events: none;
      max-width: 41.5rem;
      height: 23.1rem;
      border-radius: 0.4rem;
      margin: 0; }
      @media (max-width: 991.98px) {
        #videoCarousel .item iframe {
          height: 290px; } }
      @media (max-width: 767.98px) {
        #videoCarousel .item iframe {
          height: 13.5rem;
          min-height: 13.5rem; } }
      @media (max-width: 575.98px) {
        #videoCarousel .item iframe {
          height: 10rem;
          min-height: 10rem; } }
    #videoCarousel .item.carousel_center {
      box-shadow: 0 5px 40px rgba(0, 0, 0, 0.3); }
      #videoCarousel .item.carousel_center:before {
        background: rgba(255, 255, 255, 0);
        z-index: -1; }
      #videoCarousel .item.carousel_center iframe {
        pointer-events: all; }
  #videoCarousel .dots {
    margin: 0 auto;
    text-align: center;
    position: relative;
    align-self: flex-end;
    transform: translateY(45px); }
    @media (max-width: 991.98px) {
      #videoCarousel .dots {
        transform: translateY(35px); } }
    @media (max-width: 575.98px) {
      #videoCarousel .dots {
        transform: translateY(20px); } }
    #videoCarousel .dots .dot {
      font-size: 0;
      color: transparent;
      width: 14px;
      height: 14px;
      background: #E7E8F0;
      display: inline-block;
      border-radius: 60%;
      margin-right: 14px;
      transition: background .3s; }
      #videoCarousel .dots .dot:hover {
        background: #dedfe8; }
      #videoCarousel .dots .dot.active {
        background: #ee7f01; }

.single-video {
  max-width: 41.5rem;
  height: 23.1rem;
  margin: 30px auto 10px;
  border-radius: 0.4rem;
  display: block;
  box-shadow: 0px 0px 30px #E7E8F0; }
  @media (max-width: 575.98px) {
    .single-video {
      max-height: 260px; } }
  @media screen and (max-width: 460px) {
    .single-video {
      max-height: 200px; } }
  @media screen and (max-width: 390px) {
    .single-video {
      max-height: 190px; } }

img {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  user-drag: none;
  -webkit-touch-callout: none; }

@media (max-width: 575.98px) {
  p img {
    width: 100%;
    height: auto; } }

.img-responsive {
  display: block;
  width: 100%;
  height: auto; }

.img-center {
  display: block;
  margin: 0 auto; }

.title-icon {
  display: block;
  margin: 0 auto 30px;
  max-width: 200px;
  max-height: 70px;
  width: auto;
  height: auto; }

.img-sm {
  max-width: 520px !important; }
  .img-sm ~ figcaption {
    max-width: 520px !important; }

.img-block {
  position: relative; }
  .img-block_narrow img {
    max-height: 420px;
    object-fit: cover;
    object-position: center;
    box-shadow: 0px 0px 30px #4d4d4f33;
    margin: 20px 0 100px; }
  @media (max-width: 1199.98px) {
    .img-block_full {
      margin-bottom: -90px; } }
  @media (max-width: 575.98px) {
    .img-block_full {
      margin-bottom: -50px; } }
  .img-block_full img {
    width: 100%;
    height: auto; }
    @media (max-width: 1199.98px) {
      .img-block_full img {
        margin-left: -80px;
        width: calc(100% + 160px) !important; } }
    @media (max-width: 767.98px) {
      .img-block_full img {
        margin-left: -36px;
        width: calc(100% + 72px) !important; } }
  .img-block_xs-margin img {
    margin-bottom: 5px;
    margin-bottom: 25px; }
    @media (max-width: 991.98px) {
      .img-block_xs-margin img {
        margin-bottom: 35px !important; } }
  .img-block_sm img {
    width: 100%;
    max-width: 1230px;
    height: auto; }
  .img-block_xl img {
    max-height: 510px; }
  .img-block_no-shadow img {
    box-shadow: none; }
  .img-block picture {
    display: block;
    box-shadow: 0px 0px 40px #4d4d4f33; }
  @media screen and (max-width: 1600px) {
    .img-block img {
      width: 100%;
      height: auto; } }
  .img-block figcaption {
    background: #131f70;
    color: #fff;
    border-radius: 6px;
    border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;
    max-width: 625px;
    padding: 13px 80px 17px;
    transform: translate(-65px, -40px);
    box-shadow: 20px 20px 40px #131F7033; }
    @media (max-width: 575.98px) {
      .img-block figcaption:after {
        content: "";
        position: absolute;
        left: -100%;
        top: 0;
        width: 100vh;
        height: 100%;
        background: #131f70;
        z-index: -1; } }
    @media (max-width: 1199.98px) {
      .img-block figcaption {
        transform: translateY(-55px);
        margin: 0 auto; } }
    @media (max-width: 575.98px) {
      .img-block figcaption {
        transform: translateY(-15px);
        margin: 0 auto;
        padding: 15px 0 20px;
        border-radius: 0; } }
    .img-block figcaption ul {
      color: #fff;
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      justify-content: space-between; }
      .img-block figcaption ul li {
        display: inline-block;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px; }
        @media (max-width: 575.98px) {
          .img-block figcaption ul li {
            width: 33%;
            line-height: 18px; } }
        .img-block figcaption ul li strong {
          font-family: "Roboto";
          font-size: 36px;
          line-height: 44px;
          display: block; }
          @media (max-width: 575.98px) {
            .img-block figcaption ul li strong {
              font-size: 28px;
              line-height: 41px; } }

@media (min-width: 992px) {
  .img-md {
    width: 740px !important;
    max-width: 740px !important; } }

.img-grid {
  max-width: 791px !important;
  width: 100vh !important;
  margin-top: -40px; }
  @media (max-width: 1199.98px) {
    .img-grid {
      max-width: 100% !important;
      width: 100% !important;
      margin: 20px 0 0; } }

.img-title-block {
  padding: 30px 60px 30px 30px;
  margin-bottom: 20px;
  background: #fff;
  box-shadow: 0px 0px 30px #e7e8f0;
  width: fit-content;
  display: block; }
  @media (max-width: 991.98px) {
    .img-title-block {
      padding: 25px 20px; } }
  @media (max-width: 575.98px) {
    .img-title-block {
      padding: 10px; } }
  .img-title-block img {
    padding: 0 !important;
    box-shadow: none !important;
    margin: 0 !important; }
  .img-title-block strong, .img-title-block .h5 {
    margin-bottom: 15px; }
  .img-title-block p {
    margin-bottom: 0; }

@font-face {
  font-family: "buw";
  src: url("../webfonts/buw.ttf?c65ghw") format("truetype"), url("../webfonts/buw.woff?c65ghw") format("woff"), url("../webfonts/buw.svg?c65ghw#buw") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "buw" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-close:before {
  content: ""; }

.icon-checked:before {
  content: "";
  color: #131f70; }

.icon-arroe_right_s2:before {
  content: "";
  color: #ee7f01; }

.icon-arrow_down:before {
  content: "";
  color: #898fb7; }

.icon-arrow_left:before {
  content: "";
  color: #131f70; }

.icon-arrow_left_s1:before {
  content: "";
  color: #ccc; }

.icon-arrow_right:before {
  content: "";
  color: #131f70; }

.icon-arrow_right_s1:before {
  content: "";
  color: #ccc; }

.icon-download:before {
  content: "";
  color: #ee7f01; }

.icon-email:before {
  content: "";
  color: #898fb7; }

.icon-lamp:before {
  content: "";
  color: #ee7f01; }

.icon-language:before {
  content: "";
  color: #898fb7; }

.icon-linkedin:before {
  content: "";
  color: #fff; }

.icon-phone:before {
  content: "";
  color: #898fb7; }

.icon-pin:before {
  content: "";
  color: #ee7f01; }

.icon-search:before {
  content: "";
  color: #898fb7; }

.icon-xing:before {
  content: "";
  color: #fff; }

.icon-youtube:before {
  content: "";
  color: #fff; }

.BorlabsCookie * {
  box-sizing: border-box; }

.BorlabsCookie *::before, .BorlabsCookie *::after {
  box-sizing: inherit; }

.BorlabsCookie * div, .BorlabsCookie * span, .BorlabsCookie * h1, .BorlabsCookie * .h1, .BorlabsCookie * h2, .BorlabsCookie * .h2, .BorlabsCookie * h3, .BorlabsCookie * .h3, .BorlabsCookie * h4, .BorlabsCookie * .h4, .BorlabsCookie * h5, .BorlabsCookie * .h5, .BorlabsCookie * h6, .BorlabsCookie * .h6, .BorlabsCookie * p, .BorlabsCookie * a, .BorlabsCookie * em, .BorlabsCookie * img, .BorlabsCookie * small, .BorlabsCookie * .small, .BorlabsCookie * strike, .BorlabsCookie * strong, .BorlabsCookie * sub, .BorlabsCookie * sup, .BorlabsCookie * b, .BorlabsCookie * u, .BorlabsCookie * i, .BorlabsCookie * center, .BorlabsCookie * ol, .BorlabsCookie * ul, .BorlabsCookie * li, .BorlabsCookie * fieldset, .BorlabsCookie * form, .BorlabsCookie * label, .BorlabsCookie * legend {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

.borlabs-hide {
  display: none; }

.borlabs-position-fix {
  position: initial !important;
  transform: unset !important; }

._brlbs-btn-cookie-preference {
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  padding: 0.5em 1em;
  text-decoration: none;
  transition: background-color 125ms linear; }

.BorlabsCookie ._brlbs-cursor {
  cursor: pointer; }

.BorlabsCookie .text-left {
  text-align: left !important; }

.BorlabsCookie .text-center {
  text-align: center !important; }

.BorlabsCookie .text-right {
  text-align: right !important; }

.BorlabsCookie table._brlbs th {
  text-align: left; }

.BorlabsCookie table._brlbs td, .BorlabsCookie table._brlbs th {
  padding: 0.3rem; }

.BorlabsCookie ._brlbs-responsive-table {
  min-height: 0.01%;
  overflow: auto; }

.BorlabsCookie label._brlbs-focused {
  outline: Highlight;
  outline-color: -webkit-focus-ring-color;
  outline-style: solid;
  outline-width: 2px; }

.BorlabsCookie .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.BorlabsCookie .sr-only-focusable:active, .BorlabsCookie .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.BorlabsCookie .container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .BorlabsCookie .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .BorlabsCookie .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .BorlabsCookie .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .BorlabsCookie .container {
    max-width: 1140px; } }

.BorlabsCookie .container-fluid, .BorlabsCookie .container-sm, .BorlabsCookie .container-md, .BorlabsCookie .container-lg, .BorlabsCookie .container-xl, .BorlabsCookie .container-xxl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.BorlabsCookie .row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.BorlabsCookie .no-gutters {
  margin-right: 0;
  margin-left: 0; }

.BorlabsCookie .no-gutters > .col, .BorlabsCookie .no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0; }

.BorlabsCookie .col-xl, .BorlabsCookie .col-xl-12, .BorlabsCookie .col-xl-11, .BorlabsCookie .col-xl-10, .BorlabsCookie .col-xl-9, .BorlabsCookie .col-xl-8, .BorlabsCookie .col-xl-7, .BorlabsCookie .col-xl-6, .BorlabsCookie .col-xl-5, .BorlabsCookie .col-xl-4, .BorlabsCookie .col-xl-3, .BorlabsCookie .col-xl-2, .BorlabsCookie .col-xl-1, .BorlabsCookie .col-lg, .BorlabsCookie .col-lg-12, .BorlabsCookie .col-lg-11, .BorlabsCookie .col-lg-10, .BorlabsCookie .col-lg-9, .BorlabsCookie .col-lg-8, .BorlabsCookie .col-lg-7, .BorlabsCookie .col-lg-6, .BorlabsCookie .col-lg-5, .BorlabsCookie .col-lg-4, .BorlabsCookie .col-lg-3, .BorlabsCookie .col-lg-2, .BorlabsCookie .col-lg-1, .BorlabsCookie .col-md, .BorlabsCookie .col-md-12, .BorlabsCookie .col-md-11, .BorlabsCookie .col-md-10, .BorlabsCookie .col-md-9, .BorlabsCookie .col-md-8, .BorlabsCookie .col-md-7, .BorlabsCookie .col-md-6, .BorlabsCookie .col-md-5, .BorlabsCookie .col-md-4, .BorlabsCookie .col-md-3, .BorlabsCookie .col-md-2, .BorlabsCookie .col-md-1, .BorlabsCookie .col-sm, .BorlabsCookie .col-sm-12, .BorlabsCookie .col-sm-11, .BorlabsCookie .col-sm-10, .BorlabsCookie .col-sm-9, .BorlabsCookie .col-sm-8, .BorlabsCookie .col-sm-7, .BorlabsCookie .col-sm-6, .BorlabsCookie .col-sm-5, .BorlabsCookie .col-sm-4, .BorlabsCookie .col-sm-3, .BorlabsCookie .col-sm-2, .BorlabsCookie .col-sm-1, .BorlabsCookie .col, .BorlabsCookie .col-12, .BorlabsCookie .col-11, .BorlabsCookie .col-10, .BorlabsCookie .col-9, .BorlabsCookie .col-8, .BorlabsCookie .col-7, .BorlabsCookie .col-6, .BorlabsCookie .col-5, .BorlabsCookie .col-4, .BorlabsCookie .col-3, .BorlabsCookie .col-2, .BorlabsCookie .col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.BorlabsCookie .col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.BorlabsCookie .col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%; }

.BorlabsCookie .col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.BorlabsCookie .col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.BorlabsCookie .col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.BorlabsCookie .col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%; }

.BorlabsCookie .col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.BorlabsCookie .col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%; }

.BorlabsCookie .col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%; }

.BorlabsCookie .col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.BorlabsCookie .col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%; }

.BorlabsCookie .col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%; }

.BorlabsCookie .col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

@media (min-width: 576px) {
  .BorlabsCookie .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .BorlabsCookie .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .BorlabsCookie .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .BorlabsCookie .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .BorlabsCookie .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .BorlabsCookie .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .BorlabsCookie .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .BorlabsCookie .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .BorlabsCookie .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .BorlabsCookie .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .BorlabsCookie .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .BorlabsCookie .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .BorlabsCookie .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 768px) {
  .BorlabsCookie .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .BorlabsCookie .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .BorlabsCookie .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .BorlabsCookie .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .BorlabsCookie .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .BorlabsCookie .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .BorlabsCookie .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .BorlabsCookie .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .BorlabsCookie .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .BorlabsCookie .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .BorlabsCookie .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .BorlabsCookie .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .BorlabsCookie .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 992px) {
  .BorlabsCookie .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .BorlabsCookie .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .BorlabsCookie .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .BorlabsCookie .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .BorlabsCookie .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .BorlabsCookie .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .BorlabsCookie .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .BorlabsCookie .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .BorlabsCookie .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .BorlabsCookie .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .BorlabsCookie .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .BorlabsCookie .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .BorlabsCookie .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 1200px) {
  .BorlabsCookie .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .BorlabsCookie .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .BorlabsCookie .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .BorlabsCookie .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .BorlabsCookie .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .BorlabsCookie .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .BorlabsCookie .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .BorlabsCookie .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .BorlabsCookie .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .BorlabsCookie .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .BorlabsCookie .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .BorlabsCookie .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .BorlabsCookie .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; } }

.BorlabsCookie .d-block {
  display: block !important; }

.BorlabsCookie .d-flex {
  display: flex !important; }

@media (min-width: 576px) {
  .BorlabsCookie .d-sm-block {
    display: block !important; }
  .BorlabsCookie .d-sm-flex {
    display: flex !important; } }

@media (min-width: 768px) {
  .BorlabsCookie .d-md-block {
    display: block !important; }
  .BorlabsCookie .d-md-flex {
    display: flex !important; } }

@media (min-width: 992px) {
  .BorlabsCookie .d-lg-block {
    display: block !important; }
  .BorlabsCookie .d-lg-flex {
    display: flex !important; } }

@media (min-width: 1200px) {
  .BorlabsCookie .d-xl-block {
    display: block !important; }
  .BorlabsCookie .d-xl-flex {
    display: flex !important; } }

.BorlabsCookie .flex-row {
  flex-direction: row !important; }

.BorlabsCookie .flex-fill {
  flex: 1 1 auto !important; }

.BorlabsCookie .justify-content-between {
  justify-content: space-between !important; }

.BorlabsCookie .align-items-start {
  align-items: flex-start !important; }

.BorlabsCookie .align-items-end {
  align-items: flex-end !important; }

.BorlabsCookie .align-items-center {
  align-items: center !important; }

.BorlabsCookie .align-items-baseline {
  align-items: baseline !important; }

.BorlabsCookie .align-items-stretch {
  align-items: stretch !important; }

@media (min-width: 576px) {
  .BorlabsCookie .flex-sm-row {
    flex-direction: row !important; }
  .BorlabsCookie .flex-sm-fill {
    flex: 1 1 auto !important; }
  .BorlabsCookie .justify-content-sm-between {
    justify-content: space-between !important; }
  .BorlabsCookie .align-items-sm-start {
    align-items: flex-start !important; }
  .BorlabsCookie .align-items-sm-end {
    align-items: flex-end !important; }
  .BorlabsCookie .align-items-sm-center {
    align-items: center !important; }
  .BorlabsCookie .align-items-sm-baseline {
    align-items: baseline !important; }
  .BorlabsCookie .align-items-sm-stretch {
    align-items: stretch !important; } }

@media (min-width: 768px) {
  .BorlabsCookie .flex-md-row {
    flex-direction: row !important; }
  .BorlabsCookie .flex-md-fill {
    flex: 1 1 auto !important; }
  .BorlabsCookie .justify-content-md-between {
    justify-content: space-between !important; }
  .BorlabsCookie .align-items-md-start {
    align-items: flex-start !important; }
  .BorlabsCookie .align-items-md-end {
    align-items: flex-end !important; }
  .BorlabsCookie .align-items-md-center {
    align-items: center !important; }
  .BorlabsCookie .align-items-md-baseline {
    align-items: baseline !important; }
  .BorlabsCookie .align-items-md-stretch {
    align-items: stretch !important; } }

@media (min-width: 992px) {
  .BorlabsCookie .flex-lg-row {
    flex-direction: row !important; }
  .BorlabsCookie .flex-lg-fill {
    flex: 1 1 auto !important; }
  .BorlabsCookie .justify-content-lg-between {
    justify-content: space-between !important; }
  .BorlabsCookie .align-items-lg-start {
    align-items: flex-start !important; }
  .BorlabsCookie .align-items-lg-end {
    align-items: flex-end !important; }
  .BorlabsCookie .align-items-lg-center {
    align-items: center !important; }
  .BorlabsCookie .align-items-lg-baseline {
    align-items: baseline !important; }
  .BorlabsCookie .align-items-lg-stretch {
    align-items: stretch !important; } }

@media (min-width: 1200px) {
  .BorlabsCookie .flex-xl-row {
    flex-direction: row !important; }
  .BorlabsCookie .flex-xl-fill {
    flex: 1 1 auto !important; }
  .BorlabsCookie .justify-content-xl-between {
    justify-content: space-between !important; }
  .BorlabsCookie .align-items-xl-start {
    align-items: flex-start !important; }
  .BorlabsCookie .align-items-xl-end {
    align-items: flex-end !important; }
  .BorlabsCookie .align-items-xl-center {
    align-items: center !important; }
  .BorlabsCookie .align-items-xl-baseline {
    align-items: baseline !important; }
  .BorlabsCookie .align-items-xl-stretch {
    align-items: stretch !important; } }

.BorlabsCookie .w-25 {
  width: 25% !important; }

.BorlabsCookie .w-50 {
  width: 50% !important; }

.BorlabsCookie .w-75 {
  width: 75% !important; }

.BorlabsCookie .w-100 {
  width: 100% !important; }

.BorlabsCookie .w-auto {
  width: auto !important; }

.BorlabsCookie .h-25 {
  height: 25% !important; }

.BorlabsCookie .h-50 {
  height: 50% !important; }

.BorlabsCookie .h-75 {
  height: 75% !important; }

.BorlabsCookie .h-100 {
  height: 100% !important; }

.BorlabsCookie .h-auto {
  height: auto !important; }

.BorlabsCookie .mw-100 {
  max-width: 100% !important; }

.BorlabsCookie .mh-100 {
  max-height: 100% !important; }

.BorlabsCookie .min-vw-100 {
  min-width: 100vw !important; }

.BorlabsCookie .min-vh-100 {
  min-height: 100vh !important; }

.BorlabsCookie .vw-100 {
  width: 100vw !important; }

.BorlabsCookie .vh-100 {
  height: 100vh !important; }

.BorlabsCookie .animated {
  animation-duration: 1s;
  animation-fill-mode: both; }

.BorlabsCookie .animated.infinite {
  animation-iteration-count: infinite; }

.BorlabsCookie .animated.delay-1s {
  animation-delay: 1s; }

.BorlabsCookie .animated.delay-2s {
  animation-delay: 2s; }

.BorlabsCookie .animated.delay-3s {
  animation-delay: 3s; }

.BorlabsCookie .animated.delay-4s {
  animation-delay: 4s; }

.BorlabsCookie .animated.delay-5s {
  animation-delay: 5s; }

.BorlabsCookie .animated.fast {
  animation-duration: 800ms; }

.BorlabsCookie .animated.faster {
  animation-duration: 500ms; }

.BorlabsCookie .animated.slow {
  animation-duration: 2s; }

.BorlabsCookie .animated.slower {
  animation-duration: 3s; }

@media (print), (prefers-reduced-motion) {
  .BorlabsCookie .animated {
    animation: unset !important;
    transition: none !important; } }

.BorlabsCookie._brlbs-switch-consent {
  align-items: center;
  display: flex;
  font-size: inherit; }

.BorlabsCookie._brlbs-switch-consent ._brlbs-btn-switch--textRight {
  margin-right: 2em; }

.BorlabsCookie ._brlbs-title {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0; }

.BorlabsCookie ._brlbs-title::before {
  content: "|";
  display: inline-block;
  margin: 0 0.25rem 0 0; }

.BorlabsCookie ._brlbs-checkbox {
  color: inherit;
  cursor: pointer;
  display: block;
  padding-left: 2em;
  position: relative; }

.BorlabsCookie ._brlbs-checkbox input {
  position: absolute;
  opacity: 0 !important;
  width: auto;
  z-index: -1; }

.BorlabsCookie ._brlbs-checkbox input:checked ~ ._brlbs-checkbox-indicator::after {
  opacity: 1; }

.BorlabsCookie ._brlbs-checkbox input:disabled ~ ._brlbs-checkbox-indicator::after {
  opacity: 1; }

.BorlabsCookie ._brlbs-checkbox ._brlbs-checkbox-indicator {
  border-style: solid;
  border-width: 2px;
  padding: 0 !important;
  position: absolute;
  left: 0;
  top: 0;
  height: 20px;
  width: 20px;
  transition: 0.25s; }

.BorlabsCookie ._brlbs-checkbox ._brlbs-checkbox-indicator::after {
  content: "";
  border-style: solid;
  border-width: 0 2px 2px 0;
  border-radius: 1px;
  position: absolute;
  left: 5px;
  top: 1px;
  height: 11px;
  width: 6px;
  transform: rotate(45deg);
  opacity: 0; }

.BorlabsCookie ._brlbs-btn-switch {
  position: relative;
  display: inline-block;
  margin: 0;
  width: 50px;
  height: 26px;
  vertical-align: middle; }

.BorlabsCookie ._brlbs-btn-switch input {
  opacity: 0;
  width: 0;
  height: 0; }

.BorlabsCookie ._brlbs-btn-switch input:checked + ._brlbs-slider {
  background-color: #2295f3; }

.BorlabsCookie ._brlbs-btn-switch input:checked + ._brlbs-slider:before {
  transform: translateX(24px); }

.BorlabsCookie ._brlbs-btn-switch ._brlbs-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #bbc0c7;
  -webkit-transition: 0.4s;
  transition: 0.4s; }

.BorlabsCookie ._brlbs-btn-switch ._brlbs-slider::before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s; }

.BorlabsCookie ._brlbs-btn-switch-status {
  position: absolute;
  top: 50%;
  right: calc(100% + 6px);
  transform: translateY(-50%); }

.BorlabsCookie ._brlbs-btn-switch--textRight ._brlbs-btn-switch-status {
  right: auto;
  left: calc(100% + 6px); }

.BorlabsCookie ._brlbs-btn-switch-status::before {
  content: attr(data-inactive);
  white-space: nowrap; }

.BorlabsCookie ._brlbs-btn-switch > input:checked ~ ._brlbs-btn-switch-status::before {
  content: attr(data-active); }

#BorlabsCookieBox a:focus {
  outline: Highlight !important;
  outline-color: -webkit-focus-ring-color !important;
  outline-style: solid !important;
  outline-width: 2px !important; }

#BorlabsCookieBox:not(._brlbs-keyboard) a:focus {
  outline: 0 !important; }

#BorlabsCookieBox .animated {
  visibility: visible; }

#BorlabsCookieBox .container {
  background: inherit;
  display: block;
  float: none;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  min-height: auto;
  position: inherit; }

#BorlabsCookieBox ._brlbs-bar .container {
  max-height: 100vh;
  overflow: auto !important;
  width: auto !important; }

#BorlabsCookieBox ._brlbs-box .container {
  max-height: 100vh;
  overflow: auto !important;
  width: auto !important; }

#BorlabsCookieBox .container::before {
  display: initial; }

#BorlabsCookieBox .container::after {
  content: " ";
  display: block;
  height: 2px; }

#BorlabsCookieBox .container .row {
  background: inherit;
  color: inherit;
  display: flex;
  min-width: 1px;
  pointer-events: inherit;
  width: auto; }

#BorlabsCookieBox .container .row.no-gutters {
  margin-left: 0;
  margin-right: 0; }

#BorlabsCookieBox .container .row::before, #BorlabsCookieBox .container .row::after {
  display: initial; }

#BorlabsCookieBox .bcac-item {
  margin: 0.5em 0;
  padding: 0.5em; }

#BorlabsCookieBox .bcac-item ._brlbs-h4 {
  font-size: 1.25em;
  margin: 0 0 0.25em 0; }

#BorlabsCookieBox .bcac-item p {
  font-size: 0.875em; }

#BorlabsCookieBox .bcac-item p, #BorlabsCookieBox .bcac-item span {
  color: inherit; }

#BorlabsCookieBox > div {
  display: none; }

#BorlabsCookieBox > div.show-cookie-box {
  display: flex; }

#BorlabsCookieBox > div:not(._brlbs-block-content) {
  position: absolute;
  width: 100%;
  z-index: 999999; }

#BorlabsCookieBox > div.top-left:not(._brlbs-block-content) {
  top: 0; }

#BorlabsCookieBox > div.top-center:not(._brlbs-block-content) {
  top: 0; }

#BorlabsCookieBox > div.top-right:not(._brlbs-block-content) {
  top: 0; }

#BorlabsCookieBox > div.middle-left:not(._brlbs-block-content) {
  top: 50%; }

#BorlabsCookieBox > div.middle-center:not(._brlbs-block-content) {
  top: 50%; }

#BorlabsCookieBox > div.middle-right:not(._brlbs-block-content) {
  top: 50%; }

#BorlabsCookieBox > div.bottom-left:not(._brlbs-block-content) {
  bottom: 0; }

#BorlabsCookieBox > div.bottom-center:not(._brlbs-block-content) {
  bottom: 0; }

#BorlabsCookieBox > div.bottom-right:not(._brlbs-block-content) {
  bottom: 0; }

#BorlabsCookieBox h3, #BorlabsCookieBox .h3, #BorlabsCookieBox ._brlbs-h3 {
  background: inherit;
  color: inherit;
  font-family: inherit;
  font-size: 1.625em;
  line-height: 1.125em;
  word-break: break-all; }

#BorlabsCookieBox ul {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  list-style: none;
  margin: 0.9375em 0;
  padding: 0; }

#BorlabsCookieBox ul li {
  color: inherit;
  font-family: inherit;
  font-size: 1em;
  line-height: 1.25em;
  list-style: none;
  margin: 0 0.9375em 0.375em 0; }

#BorlabsCookieBox ul li:last-child {
  margin: 0; }

#BorlabsCookieBox ul li::before {
  content: "\25cf";
  margin: 0 4px 0 0; }

#BorlabsCookieBox p {
  color: inherit;
  font-family: inherit;
  font-size: 1em;
  line-height: 1.25em;
  margin: 0.9375em 0; }

#BorlabsCookieBox p:last-child {
  margin: 0; }

#BorlabsCookieBox ._brlbs-flex-center {
  display: flex;
  align-items: center;
  justify-content: center; }

#BorlabsCookieBox ._brlbs-flex-center p {
  margin-bottom: 0; }

#BorlabsCookieBox ._brlbs-actions {
  display: inline; }

#BorlabsCookieBox ._brlbs-accept {
  font-family: inherit;
  text-align: center; }

#BorlabsCookieBox ._brlbs-block-content {
  background: rgba(0, 0, 0, 0);
  left: 0;
  position: fixed;
  height: 100%;
  top: 0;
  width: 100%;
  z-index: 999999; }

#BorlabsCookieBox ._brlbs-block-content._brlbs-bg-animation {
  transition: background-color 250ms linear; }

#BorlabsCookieBox ._brlbs-block-content._brlbs-bg-dark {
  background: rgba(0, 0, 0, 0.6); }

#BorlabsCookieBox ._brlbs-branding {
  font-family: inherit;
  font-size: 0.75em;
  margin: 0; }

#BorlabsCookieBox ._brlbs-branding a {
  text-decoration: none; }

#BorlabsCookieBox ._brlbs-branding img {
  display: inline-block;
  max-height: 16px;
  max-width: 16px;
  vertical-align: middle; }

#BorlabsCookieBox ._brlbs-btn {
  display: inline-block;
  font-family: inherit;
  font-size: 1.25em;
  padding: 0.75em 2em;
  text-decoration: none;
  transition: background-color 125ms linear; }

#BorlabsCookieBox .cookie-logo {
  height: auto;
  max-width: 48px;
  padding: 0 8px 0 0;
  vertical-align: middle;
  width: 100%; }

#BorlabsCookieBox ._brlbs-legal {
  font-family: inherit;
  font-size: 0.75em;
  text-align: center; }

#BorlabsCookieBox ._brlbs-manage {
  font-family: inherit;
  font-size: 0.75em;
  text-align: center; }

#BorlabsCookieBox ._brlbs-refuse {
  font-family: inherit;
  font-size: 0.75em;
  text-align: center; }

#BorlabsCookieBox ._brlbs-refuse a {
  color: inherit; }

#BorlabsCookieBox ._brlbs-refuse-btn {
  font-family: inherit;
  font-size: 0.875em;
  text-align: center; }

#BorlabsCookieBox ._brlbs-refuse-btn a {
  font-size: inherit;
  color: inherit; }

#BorlabsCookieBox ._brlbs-refuse-btn a:hover {
  color: inherit; }

#BorlabsCookieBox .top-left {
  align-items: flex-start;
  justify-content: flex-start; }

#BorlabsCookieBox .top-center {
  align-items: flex-start;
  justify-content: center; }

#BorlabsCookieBox .top-right {
  align-items: flex-start;
  justify-content: flex-end; }

#BorlabsCookieBox .middle-left {
  align-items: center;
  justify-content: flex-start; }

#BorlabsCookieBox .middle-center {
  align-items: center;
  justify-content: center; }

#BorlabsCookieBox .middle-right {
  align-items: center;
  justify-content: flex-end; }

#BorlabsCookieBox .bottom-left {
  align-items: flex-end;
  justify-content: flex-start; }

#BorlabsCookieBox .bottom-center {
  align-items: flex-end;
  justify-content: center; }

#BorlabsCookieBox .bottom-right {
  align-items: flex-end;
  justify-content: flex-end; }

#BorlabsCookieBox ._brlbs-box, #BorlabsCookieBox ._brlbs-bar {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 15px 0; }

#BorlabsCookieBox ._brlbs-bar-wrap {
  display: block;
  max-width: 100%;
  width: 100%;
  z-index: 1000000; }

#BorlabsCookieBox ._brlbs-bar-wrap._brlbs-position-fixed {
  position: fixed; }

#BorlabsCookieBox ._brlbs-bar._brlbs-bar-slim .cookie-box p {
  margin: 0; }

#BorlabsCookieBox ._brlbs-bar._brlbs-bar-slim ._brlbs-actions-mobile {
  display: none; }

#BorlabsCookieBox ._brlbs-bar h3, #BorlabsCookieBox ._brlbs-bar .h3, #BorlabsCookieBox ._brlbs-bar ._brlbs-h3 {
  margin: 0 0 0.5em 0; }

#BorlabsCookieBox ._brlbs-bar ul {
  justify-content: center;
  margin: 0.5em 0; }

#BorlabsCookieBox ._brlbs-bar ._brlbs-no-padding-right {
  padding-right: 0; }

#BorlabsCookieBox ._brlbs-bar ._brlbs-separator::before {
  content: "|";
  display: inline-block;
  margin: 0 2px; }

#BorlabsCookieBox ._brlbs-bar .cookie-logo {
  max-width: 100px;
  padding: 0 8px 0 0;
  vertical-align: middle;
  width: 100%; }

#BorlabsCookieBox ._brlbs-bar ._brlbs-legal {
  text-align: center; }

#BorlabsCookieBox ._brlbs-box-wrap {
  display: block;
  max-width: 375px;
  width: 100%;
  z-index: 1000000; }

#BorlabsCookieBox ._brlbs-box-wrap._brlbs-position-fixed {
  position: fixed; }

#BorlabsCookieBox ._brlbs-box._brlbs-box-slim > .container ._brlbs-manage {
  margin: 0; }

#BorlabsCookieBox ._brlbs-box._brlbs-box-slim > .container ._brlbs-refuse {
  margin: 0.5em 0 0 0; }

#BorlabsCookieBox ._brlbs-box ._brlbs-separator::before {
  content: "|";
  display: inline-block;
  margin: 0 2px; }

#BorlabsCookieBox .cookie-preference .container.not-visible {
  height: 0; }

#BorlabsCookieBox .cookie-preference .cookie-logo {
  max-width: 100%; }

#BorlabsCookieBox .cookie-preference table {
  margin: 1em 0 0 0;
  width: 100%; }

#BorlabsCookieBox .cookie-preference table tr td, #BorlabsCookieBox .cookie-preference table tr th {
  border-bottom: 1px solid; }

#BorlabsCookieBox .cookie-preference table tr:last-child td, #BorlabsCookieBox .cookie-preference table tr:last-child th {
  border-bottom: 0; }

#BorlabsCookieBox .cookie-preference table th {
  text-align: left;
  width: 40%; }

#BorlabsCookieBox .cookie-preference table td {
  text-align: left; }

#BorlabsCookieBox .cookie-preference table td, #BorlabsCookieBox .cookie-preference table th {
  font-size: 0.875em;
  padding: 0.25em 0.5em; }

#BorlabsCookieBox .cookie-preference ._brlbs-accept {
  text-align: left; }

#BorlabsCookieBox .cookie-preference ._brlbs-btn {
  font-size: 1em;
  margin: 0 0 0.9375em 0;
  padding: 0.75em 1.5em; }

#BorlabsCookieBox .cookie-preference ._brlbs-legal {
  text-align: right; }

#BorlabsCookieBox .cookie-preference ._brlbs-pp-url {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 0; }

#BorlabsCookieBox .cookie-preference ._brlbs-refuse {
  margin: 0 0 0.9375em 0;
  text-align: right; }

#BorlabsCookieBox .cookie-preference ._brlbs-separator::before {
  content: "|";
  display: inline-block;
  margin: 0 2px; }

#BorlabsCookieBox ._brlbs-bar-advanced ul, #BorlabsCookieBox ._brlbs-box-advanced ul {
  justify-content: space-between; }

#BorlabsCookieBox ._brlbs-bar-advanced ul li, #BorlabsCookieBox ._brlbs-box-advanced ul li {
  line-height: 1.375em;
  margin: 0 0.9375em 0.875em 0; }

#BorlabsCookieBox ._brlbs-bar-advanced ul li::before, #BorlabsCookieBox ._brlbs-box-advanced ul li::before {
  content: "";
  margin: 0; }

.BorlabsCookie ._brlbs-content-blocker {
  line-height: 1.25em; }

.BorlabsCookie ._brlbs-content-blocker ._brlbs-caption {
  padding: 1em; }

.BorlabsCookie ._brlbs-content-blocker ._brlbs-caption label {
  color: inherit; }

.BorlabsCookie ._brlbs-content-blocker ._brlbs-caption label input {
  background: none;
  border: 0;
  display: inline-block;
  width: auto;
  height: auto;
  position: static;
  vertical-align: middle;
  -webkit-appearance: auto; }

.BorlabsCookie ._brlbs-content-blocker ._brlbs-caption label small, .BorlabsCookie ._brlbs-content-blocker ._brlbs-caption label .small {
  vertical-align: middle; }

.BorlabsCookie ._brlbs-content-blocker ._brlbs-caption p {
  margin-bottom: 1em; }

.BorlabsCookie ._brlbs-content-blocker ._brlbs-caption p:last-child {
  margin: 0; }

.BorlabsCookie ._brlbs-content-blocker ._brlbs-caption small, .BorlabsCookie ._brlbs-content-blocker ._brlbs-caption .small {
  font-size: 0.875em; }

.BorlabsCookie ._brlbs-content-blocker ._brlbs-default {
  background: #efefef;
  margin-bottom: 1em;
  padding: 1em;
  text-align: center; }

.BorlabsCookie ._brlbs-content-blocker ._brlbs-default label input {
  vertical-align: middle;
  width: auto; }

.BorlabsCookie ._brlbs-content-blocker ._brlbs-default p {
  margin-bottom: 1em; }

.BorlabsCookie ._brlbs-content-blocker ._brlbs-default p:last-child {
  margin: 0; }

.BorlabsCookie ._brlbs-content-blocker ._brlbs-default small, .BorlabsCookie ._brlbs-content-blocker ._brlbs-default .small {
  font-size: 0.875em; }

.BorlabsCookie ._brlbs-content-blocker ._brlbs-embed {
  margin-bottom: 1em;
  position: relative;
  display: inline-block;
  overflow: hidden; }

.BorlabsCookie ._brlbs-content-blocker ._brlbs-embed ._brlbs-thumbnail {
  display: block;
  max-width: 100%; }

.BorlabsCookie ._brlbs-content-blocker ._brlbs-embed ._brlbs-caption {
  top: 50%;
  left: 0;
  position: absolute;
  width: 100%;
  text-align: center;
  transform: translateY(-50%); }

.BorlabsCookie ._brlbs-content-blocker ._brlbs-embed ._brlbs-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center; }

.BorlabsCookie ._brlbs-content-blocker ._brlbs-embed ._brlbs-btn._brlbs-icon-play-black::before {
  background: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0' y='0' width='78' height='78' viewBox='0, 0, 78, 78'%3E%3Cg id='Layer_1'%3E%3Cg%3E%3Cpath d='M7.5,71.5 L7.5,7.5 L55.5,37.828 L7.5,71.5' fill='%23000000'/%3E%3Cpath d='M7.5,71.5 L7.5,7.5 L55.5,37.828 L7.5,71.5' fill-opacity='0' stroke='%23000000' stroke-width='12' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat center;
  background-size: contain;
  content: " ";
  display: inline-block;
  margin-right: 4px;
  height: 1.142857143em;
  width: 0.857142857em; }

.BorlabsCookie ._brlbs-content-blocker ._brlbs-embed ._brlbs-btn._brlbs-icon-play-white::before {
  background: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0' y='0' width='78' height='78' viewBox='0, 0, 78, 78'%3E%3Cg id='Layer_1'%3E%3Cg%3E%3Cpath d='M7.5,71.5 L7.5,7.5 L55.5,37.828 L7.5,71.5' fill='%23ffffff'/%3E%3Cpath d='M7.5,71.5 L7.5,7.5 L55.5,37.828 L7.5,71.5' fill-opacity='0' stroke='%23ffffff' stroke-width='12' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat center;
  background-size: contain;
  content: " ";
  display: inline-block;
  margin-right: 4px;
  height: 1.142857143em;
  width: 0.857142857em; }

.BorlabsCookie ._brlbs-content-blocker ._brlbs-btn {
  display: inline-block;
  font-family: inherit;
  padding: 0.5em 1em;
  text-decoration: none;
  transition: background-color 125ms linear; }

._brlbs-fluid-width-video-wrapper {
  padding: 56.2963% 0 0 0;
  position: relative;
  width: 100%; }

._brlbs-fluid-width-video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.wp-embed-responsive .wp-embed-aspect-16-9 .wp-block-embed__wrapper .BorlabsCookie {
  top: 0;
  left: 0;
  position: absolute; }

.wpb_video_widget .wpb_video_wrapper .BorlabsCookie {
  display: block;
  height: 100%;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  width: 100%; }

.bricks-video-wrapper .BorlabsCookie {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0; }

.bricks-video-wrapper .BorlabsCookie ._brlbs-embed {
  width: 100%; }

.bricks-video-wrapper .BorlabsCookie ._brlbs-embed ._brlbs-thumbnail {
  width: 100%; }

.wpb_wrapper .fb_type_standard {
  height: auto; }

@media (max-width: 424px) {
  #BorlabsCookieBox ._brlbs-bar._brlbs-bar-slim ._brlbs-separator::before {
    content: "";
    display: block;
    margin: 0; } }

@media (min-width: 576px) {
  #BorlabsCookieBox ._brlbs-bar ul.show-cookie-logo {
    margin: 0.5em 0 0 16.6666666667%; } }

@media (max-width: 767px) {
  #BorlabsCookieBox ._brlbs-bar._brlbs-bar-slim p {
    margin: 0 0 0.5em 0; }
  #BorlabsCookieBox ._brlbs-bar._brlbs-bar-slim ._brlbs-actions {
    display: none; }
  #BorlabsCookieBox ._brlbs-bar._brlbs-bar-slim p._brlbs-actions-mobile {
    display: block;
    margin: 0.9375em 0;
    text-align: center; }
  #BorlabsCookieBox ._brlbs-bar .align-items-center {
    align-items: flex-start !important; }
  #BorlabsCookieBox .cookie-preference ._brlbs-accept, #BorlabsCookieBox .cookie-preference ._brlbs-refuse {
    text-align: center; } }

#BorlabsCookieBox * {
  font-family: inherit; }

#BorlabsCookieBox ._brlbs-bar-advanced ul, #BorlabsCookieBox ._brlbs-box-advanced ul {
  justify-content: space-between; }

#BorlabsCookieBox ._brlbs-bar, #BorlabsCookieBox ._brlbs-box {
  background: #fff;
  border-radius: 4px;
  color: #555;
  font-size: 14px; }

#BorlabsCookieBox a {
  color: #131f70; }

#BorlabsCookieBox a:hover {
  color: #ee7f01; }

#BorlabsCookieBox ._brlbs-btn {
  background: #f7f7f7;
  border-radius: 4px;
  color: #555; }

#BorlabsCookieBox ._brlbs-btn:hover {
  background: #e6e6e6;
  border-radius: 4px;
  color: #555; }

#BorlabsCookieBox ._brlbs-refuse-btn a {
  background: #f7f7f7;
  border-radius: 4px;
  color: #555; }

#BorlabsCookieBox ._brlbs-refuse-btn a:hover {
  background: #e6e6e6;
  border-radius: 4px;
  color: #555; }

#BorlabsCookieBox ._brlbs-btn-accept-all {
  background: #131f70;
  border-radius: 4px;
  color: #fff; }

#BorlabsCookieBox ._brlbs-btn-accept-all:hover {
  background: #ee7f01;
  border-radius: 4px;
  color: #fff; }

#BorlabsCookieBox ._brlbs-btn-accept-all {
  background: #131f70;
  border-radius: 4px;
  color: #fff; }

#BorlabsCookieBox ._brlbs-btn-accept-all:hover {
  background: #ee7f01;
  border-radius: 4px;
  color: #fff; }

#BorlabsCookieBox ._brlbs-legal {
  color: #aaa; }

#BorlabsCookieBox ._brlbs-legal a {
  color: inherit; }

#BorlabsCookieBox ._brlbs-legal a:hover {
  color: #aaa; }

#BorlabsCookieBox ._brlbs-branding {
  color: #000; }

#BorlabsCookieBox ._brlbs-branding a {
  color: inherit; }

#BorlabsCookieBox ._brlbs-branding a:hover {
  color: inherit; }

#BorlabsCookieBox ._brlbs-manage a {
  color: #131f70; }

#BorlabsCookieBox ._brlbs-manage a:hover {
  color: #ee7f01; }

#BorlabsCookieBox ._brlbs-refuse {
  color: #888; }

#BorlabsCookieBox ._brlbs-refuse a:hover {
  color: #888; }

#BorlabsCookieBox ul li::before {
  color: #131f70; }

#BorlabsCookieBox .cookie-box ._brlbs-btn {
  width: 100%; }

.BorlabsCookie ._brlbs-btn-switch ._brlbs-slider {
  background-color: #bdc1c8; }

.BorlabsCookie ._brlbs-btn-switch input:checked + ._brlbs-slider {
  background-color: #131f70; }

.BorlabsCookie ._brlbs-btn-switch ._brlbs-slider::before {
  background-color: #fff; }

.BorlabsCookie ._brlbs-btn-switch input:checked + ._brlbs-slider:before {
  background-color: #fff; }

.BorlabsCookie ._brlbs-checkbox ._brlbs-checkbox-indicator {
  background-color: #fff;
  border-color: #ee7f01;
  border-radius: 4px; }

.BorlabsCookie ._brlbs-checkbox input:checked ~ ._brlbs-checkbox-indicator {
  background-color: #131f70;
  border-color: #131f70; }

.BorlabsCookie ._brlbs-checkbox input:checked ~ ._brlbs-checkbox-indicator::after {
  border-color: #fff; }

.BorlabsCookie ._brlbs-checkbox input:disabled ~ ._brlbs-checkbox-indicator {
  background-color: #e6e6e6;
  border-color: #e6e6e6; }

.BorlabsCookie ._brlbs-checkbox input:disabled ~ ._brlbs-checkbox-indicator::after {
  border-color: #999; }

#BorlabsCookieBox .bcac-item {
  background-color: #f7f7f7;
  border-radius: 0px;
  color: #555; }

#BorlabsCookieBox .cookie-preference table {
  background-color: #fff;
  border-radius: 0px;
  color: #555; }

#BorlabsCookieBox .cookie-preference table {
  background-color: #fff;
  border-radius: 0px;
  color: #555; }

#BorlabsCookieBox .cookie-preference table tr td, #BorlabsCookieBox .cookie-preference table tr th {
  background-color: #fff;
  border-color: #eee; }

.BorlabsCookie ._brlbs-btn-switch ._brlbs-slider {
  border-radius: 34px; }

.BorlabsCookie ._brlbs-btn-switch ._brlbs-slider::before {
  border-radius: 50%; }

.BorlabsCookie ._brlbs-content-blocker {
  font-family: inherit;
  font-size: 14px; }

.BorlabsCookie ._brlbs-content-blocker ._brlbs-caption {
  background: rgba(0, 0, 0, 0.8);
  color: #fff; }

.BorlabsCookie ._brlbs-content-blocker ._brlbs-caption a {
  color: #28a745; }

.BorlabsCookie ._brlbs-content-blocker ._brlbs-caption a:hover {
  color: #30c553; }

.BorlabsCookie ._brlbs-content-blocker a._brlbs-btn {
  background: #28a745;
  border-radius: 4px;
  color: #fff; }

.BorlabsCookie ._brlbs-content-blocker a._brlbs-btn:hover {
  background: #30c553;
  color: #fff; }

a._brlbs-btn-cookie-preference {
  background: #131f70 !important;
  border-radius: 4px !important;
  color: #fff !important; }

a._brlbs-btn-cookie-preference:hover {
  background: #ee7f01 !important;
  color: #fff !important; }

#BorlabsCookieBox ._brlbs-bar, #BorlabsCookieBox ._brlbs-box {
  margin: 10px;
  padding: 20px 0; }

.BorlabsCookie ._brlbs-facebook {
  border: 1px solid #e1e8ed;
  border-radius: 6px;
  max-width: 516px;
  padding: 3px 0; }

.BorlabsCookie ._brlbs-facebook a._brlbs-btn {
  background: #4267b2;
  border-radius: 2px; }

.BorlabsCookie ._brlbs-facebook a._brlbs-btn:hover {
  background: #3b5998; }

.BorlabsCookie ._brlbs-google-maps a._brlbs-btn {
  background: #4285f4;
  border-radius: 3px; }

.BorlabsCookie ._brlbs-google-maps a._brlbs-btn:hover {
  background: #fff;
  color: #4285f4; }

.BorlabsCookie ._brlbs-instagram {
  border: 1px solid #e1e8ed;
  border-radius: 6px;
  max-width: 516px;
  padding: 3px 0; }

.BorlabsCookie ._brlbs-instagram a._brlbs-btn {
  background: #3897f0;
  border-radius: 4px; }

.BorlabsCookie ._brlbs-instagram a._brlbs-btn:hover {
  background: #117ee4; }

.BorlabsCookie ._brlbs-twitter {
  border: 1px solid #e1e8ed;
  border-radius: 3px;
  max-width: 516px; }

.BorlabsCookie ._brlbs-twitter a._brlbs-btn {
  background: #1da1f2;
  border-radius: 0; }

.BorlabsCookie ._brlbs-twitter a._brlbs-btn:hover {
  background: #fff;
  color: #1da1f2; }

.BorlabsCookie ._brlbs-video-vimeo a._brlbs-btn {
  background: #00adef;
  border-radius: 20px; }

.BorlabsCookie ._brlbs-video-vimeo a._brlbs-btn:hover {
  background: #fff;
  color: #00adef; }

.BorlabsCookie ._brlbs-video-vimeo a._brlbs-btn._brlbs-icon-play-white:hover::before {
  background: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0' y='0' width='78' height='78' viewBox='0, 0, 78, 78'%3E%3Cg id='Layer_1'%3E%3Cg%3E%3Cpath d='M7.5,71.5 L7.5,7.5 L55.5,37.828 L7.5,71.5' fill='%2300adef'/%3E%3Cpath d='M7.5,71.5 L7.5,7.5 L55.5,37.828 L7.5,71.5' fill-opacity='0' stroke='%2300adef' stroke-width='12' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat center;
  background-size: contain;
  content: " "; }

.BorlabsCookie ._brlbs-video-youtube a._brlbs-btn {
  background: #ff0000;
  border-radius: 20px; }

.BorlabsCookie ._brlbs-video-youtube a._brlbs-btn:hover {
  background: #fff;
  color: red; }

.BorlabsCookie ._brlbs-video-youtube a._brlbs-btn._brlbs-icon-play-white:hover::before {
  background: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0' y='0' width='78' height='78' viewBox='0, 0, 78, 78'%3E%3Cg id='Layer_1'%3E%3Cg%3E%3Cpath d='M7.5,71.5 L7.5,7.5 L55.5,37.828 L7.5,71.5' fill='%23ff0000'/%3E%3Cpath d='M7.5,71.5 L7.5,7.5 L55.5,37.828 L7.5,71.5' fill-opacity='0' stroke='%23ff0000' stroke-width='12' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat center;
  background-size: contain;
  content: " "; }

footer {
  position: relative;
  background: #fff;
  padding: 4rem 0 0.5rem;
  text-align: center;
  display: flex !important;
  justify-content: center;
  flex-direction: column; }
  @media (max-width: 575.98px) {
    footer {
      padding: 2rem 0 0; } }
  @media (max-width: 991.98px) {
    footer .container {
      padding: 0 30px; } }
  @media (max-width: 575.98px) {
    footer .container {
      padding: 0 20px; } }
  footer .footer-nav_main ul {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-bottom: 26px;
    padding-bottom: 72px;
    border-bottom: 1px solid #E7E8F0;
    /* Landscape */ }
    @media (max-width: 575.98px) {
      footer .footer-nav_main ul {
        margin-bottom: 25px;
        padding-bottom: 20px;
        flex-wrap: wrap; } }
    @media only screen and (min-device-width: 385px) and (max-device-height: 840px) and (orientation: landscape) {
      footer .footer-nav_main ul {
        padding-bottom: 30px; } }
    footer .footer-nav_main ul li {
      width: auto;
      margin-right: 70px;
      /* Landscape */ }
      @media (max-width: 991.98px) {
        footer .footer-nav_main ul li {
          margin-right: 35px; } }
      @media (max-width: 575.98px) {
        footer .footer-nav_main ul li {
          margin-right: 19px;
          margin-bottom: 8px;
          letter-spacing: -.1px; } }
      @media only screen and (min-device-width: 385px) and (max-device-height: 840px) and (orientation: landscape) {
        footer .footer-nav_main ul li {
          margin-right: 12px; } }
      @media (max-width: 575.98px) {
        footer .footer-nav_main ul li:nth-child(3) {
          margin-right: 0; } }
      footer .footer-nav_main ul li:last-child {
        margin-right: 0; }
      footer .footer-nav_main ul li.current_page_item a {
        color: #ee7f01;
        font-weight: 600; }
  footer .footer-nav_bottom ul {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    flex-wrap: wrap; }
    @media (max-width: 575.98px) {
      footer .footer-nav_bottom ul {
        margin-bottom: 10px; } }
    footer .footer-nav_bottom ul li {
      margin-right: 1.1rem; }
      footer .footer-nav_bottom ul li:first-child {
        margin-bottom: 3px;
        width: 100%;
        pointer-events: none; }
        @media (max-width: 575.98px) {
          footer .footer-nav_bottom ul li:first-child {
            margin: 0 0 11px; } }
      footer .footer-nav_bottom ul li:last-child {
        margin-right: 0; }
      footer .footer-nav_bottom ul li.current_page_item a {
        color: #ee7f01; }
    footer .footer-nav_bottom ul a {
      color: #999999; }
      @media (max-width: 575.98px) {
        footer .footer-nav_bottom ul a {
          line-height: 26px;
          display: block; } }
      footer .footer-nav_bottom ul a:hover, footer .footer-nav_bottom ul a:focus {
        color: #ee7f01; }
  footer .footer-nav_social {
    list-style: none;
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
    padding: 0; }
    footer .footer-nav_social li {
      margin-right: 0.5rem; }
      footer .footer-nav_social li:last-child {
        margin-right: 0; }
        footer .footer-nav_social li:last-child .social-icon {
          margin-right: 0; }
    footer .footer-nav_social .social-icon {
      width: 2.3rem;
      height: 2.3rem;
      background: #131f70;
      border-radius: 60%;
      margin-right: 0.6rem;
      display: block;
      line-height: 2.3rem;
      text-align: center;
      color: #fff;
      text-decoration: none;
      transition: background .3s, color .2s; }
      footer .footer-nav_social .social-icon:before {
        color: #fff;
        font-size: 0.75rem;
        font-weight: 300;
        display: block; }
      footer .footer-nav_social .social-icon:hover, footer .footer-nav_social .social-icon:focus {
        background: #ee7f01;
        color: #fff; }
      footer .footer-nav_social .social-icon i {
        margin: 0; }
    footer .footer-nav_social .icon-xl:before {
      font-size: 18px !important; }
  footer .footer-brand img {
    width: 100%;
    max-width: 143px;
    height: auto;
    margin-bottom: 55px; }
    @media (max-width: 575.98px) {
      footer .footer-brand img {
        max-width: 90px;
        margin-bottom: 33px; } }
  footer footer a {
    text-decoration: none;
    letter-spacing: 0.01rem; }

header {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 101;
  background: #fff;
  box-shadow: 0px 10px 30px #0000001a;
  transition: box-shadow .3s; }
  @media (max-width: 575.98px) {
    header {
      position: fixed; } }
  header .navbar-brand {
    padding: 0.8rem 0 0.7rem; }
    @media (max-width: 575.98px) {
      header .navbar-brand {
        transform: none;
        padding: 0 0 0.2rem; } }
    header .navbar-brand img {
      width: 100%;
      max-width: 6.05rem;
      height: auto;
      transition: width .3s, width .3s; }
      @media (max-width: 991.98px) {
        header .navbar-brand img {
          max-width: 5rem; } }
      @media (max-width: 575.98px) {
        header .navbar-brand img {
          max-width: 3.5rem; } }
  header #nav-toggle {
    position: relative;
    margin: 0;
    transform: rotate(0deg);
    cursor: pointer;
    z-index: 997;
    border: 0;
    padding: 0;
    width: 24px;
    height: 13px; }
    @media (max-width: 575.98px) {
      header #nav-toggle {
        width: 27px; } }
    header #nav-toggle:focus {
      box-shadow: none; }
    header #nav-toggle.open {
      z-index: 1006 !important; }
    header #nav-toggle span {
      display: block;
      position: absolute;
      height: 2px;
      width: 23px;
      background: #898FB7;
      opacity: 1;
      left: 0;
      border-radius: 0px;
      transform: rotate(0deg);
      transition: .25s ease-in-out; }
      header #nav-toggle span:nth-child(1) {
        top: 0px; }
      header #nav-toggle span:nth-child(2), header #nav-toggle span:nth-child(3) {
        top: 0.3rem; }
      header #nav-toggle span:nth-child(4) {
        top: 0.6rem; }
    header #nav-toggle.open span:nth-child(1) {
      top: 0.6rem;
      width: 0%;
      left: 50%; }
    header #nav-toggle.open span:nth-child(2) {
      transform: rotate(45deg); }
    header #nav-toggle.open span:nth-child(3) {
      transform: rotate(-45deg); }
    header #nav-toggle.open span:nth-child(4) {
      top: 0.9rem;
      width: 0%;
      left: 50%; }
  header .navbar {
    height: 110px;
    padding: 0;
    transition: padding .2s ease;
    flex-wrap: wrap; }
    @media (max-width: 991.98px) {
      header .navbar {
        height: 88px; } }
    @media (max-width: 575.98px) {
      header .navbar {
        height: 72px; } }
  header .navbar-collapse {
    position: static;
    z-index: 100;
    padding: 0;
    margin-bottom: 0;
    align-self: flex-end;
    /* Landscape */ }
    @media (max-width: 1199.98px) {
      header .navbar-collapse {
        position: absolute;
        z-index: 998;
        padding: 0;
        margin-bottom: 0;
        right: 0;
        width: 100%;
        max-width: 340px;
        height: 100vh;
        padding: 90px 0 50px 32px;
        top: 0px;
        background: #fff;
        border-left: 10px solid #ee7f01;
        box-shadow: -20px -5px 30px #0000001a, 320px -5px 30px #0000001a;
        box-shadow: 210px 0 0 200px #fff, -20px -5px 30px #0000001a, 320px -5px 30px #0000001a;
        display: none;
        overflow-y: scroll; } }
    @media (max-width: 575.98px) {
      header .navbar-collapse {
        top: 72px;
        height: calc(100vh - 72px);
        box-shadow: 0 1000px 0 1000px #fff;
        left: 0;
        right: initial;
        max-width: 100%;
        border-left: 0;
        padding: 20px 0; } }
    @media only screen and (min-device-width: 385px) and (max-device-height: 840px) and (orientation: landscape) {
      header .navbar-collapse {
        top: 0;
        overflow: hidden;
        overflow-y: auto;
        padding: 90px 35px 120px 30px;
        right: -35px; } }
    @media only screen and (max-device-width: 1200px) and (max-device-height: 1120px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: landscape) {
      header .navbar-collapse {
        top: 0;
        padding: 115px 35px 120px 30px; } }
    @media (max-width: 1199.98px) {
      header .navbar-collapse:before {
        content: "";
        width: 100vh;
        background: #ffffff;
        height: 100vh;
        right: -100vh;
        top: 44px;
        position: fixed;
        z-index: -1;
        width: 200px;
        right: 0; } }
    @media (max-width: 575.98px) {
      header .navbar-collapse:before {
        top: 72px;
        position: fixed;
        right: initial;
        left: 0;
        border-left: 8px solid #ee7f01;
        width: 100vh; } }
    header .navbar-collapse.show {
      display: block; }
    header .navbar-collapse::-webkit-scrollbar {
      /* Landscape */ }
      @media only screen and (min-device-width: 385px) and (max-device-height: 840px) and (orientation: landscape) {
        header .navbar-collapse::-webkit-scrollbar {
          display: none; } }
    @media (max-width: 767.98px) {
      header .navbar-collapse .container {
        padding: 0; } }
    header .navbar-collapse .navbar-nav {
      transition: max-width .5s;
      margin-left: auto;
      margin-right: 0;
      align-items: center;
      opacity: 1 !important; }
      @media (max-width: 991.98px) {
        header .navbar-collapse .navbar-nav {
          margin: 0;
          padding: 0;
          align-items: flex-start; } }
      header .navbar-collapse .navbar-nav a {
        text-decoration: none; }
      header .navbar-collapse .navbar-nav .nav-item {
        padding: 0;
        cursor: pointer;
        position: relative; }
        @media (max-width: 767.98px) {
          header .navbar-collapse .navbar-nav .nav-item {
            padding: 0;
            width: 100%; } }
        header .navbar-collapse .navbar-nav .nav-item:hover .nav-link, header .navbar-collapse .navbar-nav .nav-item:focus .nav-link {
          color: #ee7f01; }
        @media (min-width: 1200px) {
          header .navbar-collapse .navbar-nav .nav-item:hover > ul.sub-menu, header .navbar-collapse .navbar-nav .nav-item:focus > ul.sub-menu {
            position: absolute;
            display: block; } }
        header .navbar-collapse .navbar-nav .nav-item.menu-item-has-children {
          margin-right: 25px; }
          @media (max-width: 1199.98px) {
            header .navbar-collapse .navbar-nav .nav-item.menu-item-has-children {
              width: 100%; } }
          @media (min-width: 1400px) {
            header .navbar-collapse .navbar-nav .nav-item.menu-item-has-children > a {
              pointer-events: none; } }
          @media (max-width: 1199.98px) {
            header .navbar-collapse .navbar-nav .nav-item.menu-item-has-children > a {
              width: 100%; } }
          header .navbar-collapse .navbar-nav .nav-item.menu-item-has-children a:after {
            content: "\e901";
            position: absolute;
            font-size: 7px;
            font-family: "buw" !important;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            right: 1px;
            top: 21px;
            transition: color .1s, transform .3s; }
            @media (max-width: 1199.98px) {
              header .navbar-collapse .navbar-nav .nav-item.menu-item-has-children a:after {
                top: 26px; } }
          @media (max-width: 1199.98px) {
            header .navbar-collapse .navbar-nav .nav-item.menu-item-has-children a.submenu-open:after {
              transform: rotate(180deg); } }
          header .navbar-collapse .navbar-nav .nav-item.menu-item-has-children:hover a:after, header .navbar-collapse .navbar-nav .nav-item.menu-item-has-children:focus a:after {
            color: #ee7f01; }
        header .navbar-collapse .navbar-nav .nav-item.active .nav-link,
        header .navbar-collapse .navbar-nav .nav-item.current-page-ancestor a,
        header .navbar-collapse .navbar-nav .nav-item.current_page_parent a,
        header .navbar-collapse .navbar-nav .nav-item.current-menu-ancestor > a {
          color: #ee7f01; }
          header .navbar-collapse .navbar-nav .nav-item.active .nav-link:after,
          header .navbar-collapse .navbar-nav .nav-item.current-page-ancestor a:after,
          header .navbar-collapse .navbar-nav .nav-item.current_page_parent a:after,
          header .navbar-collapse .navbar-nav .nav-item.current-menu-ancestor > a:after {
            color: #ee7f01; }
        header .navbar-collapse .navbar-nav .nav-item > .sub-menu {
          position: absolute;
          display: none;
          left: 0;
          transition: opacity .1s ease, transform .3s;
          width: 100%;
          min-width: 365px;
          list-style: none;
          padding: 25px 30px;
          margin: 0 0 0 15px;
          background: #fff;
          border-left: 10px solid #ee7f01;
          box-shadow: 0px 10px 30px #0000001a; }
          @media (max-width: 1199.98px) {
            header .navbar-collapse .navbar-nav .nav-item > .sub-menu {
              position: relative;
              min-width: auto;
              margin: 0;
              padding: 18px 0 18px 18px;
              border: 0;
              box-shadow: none;
              border-bottom: 1px solid #E7E8F0; } }
          @media (max-width: 1199.98px) {
            header .navbar-collapse .navbar-nav .nav-item > .sub-menu.open {
              display: block; } }
          header .navbar-collapse .navbar-nav .nav-item > .sub-menu > li:first-child > a {
            font-family: "Roboto";
            color: #222;
            font-size: 18px;
            font-weight: 700;
            letter-spacing: 0;
            border-bottom: 2px solid #f3f3f7;
            padding: 3px 0 0;
            margin-bottom: 15px; }
            @media (max-width: 1199.98px) {
              header .navbar-collapse .navbar-nav .nav-item > .sub-menu > li:first-child > a {
                border-bottom: 0;
                padding: 0;
                margin-bottom: 10px; } }
          header .navbar-collapse .navbar-nav .nav-item > .sub-menu > li a {
            padding: 0px 30px;
            height: auto;
            color: #999999;
            font-size: 18px;
            line-height: 31px;
            width: 100%;
            display: block;
            background: #fff; }
            @media (max-width: 1199.98px) {
              header .navbar-collapse .navbar-nav .nav-item > .sub-menu > li a {
                line-height: 27px;
                margin-bottom: 8px;
                padding: 0; } }
            @media (max-width: 767.98px) {
              header .navbar-collapse .navbar-nav .nav-item > .sub-menu > li a {
                margin-bottom: 8px; } }
            header .navbar-collapse .navbar-nav .nav-item > .sub-menu > li a:after {
              content: initial; }
            header .navbar-collapse .navbar-nav .nav-item > .sub-menu > li a:hover, header .navbar-collapse .navbar-nav .nav-item > .sub-menu > li a:focus {
              color: #ee7f01; }
          header .navbar-collapse .navbar-nav .nav-item > .sub-menu > li.current_page_item a, header .navbar-collapse .navbar-nav .nav-item > .sub-menu > li.current-page-ancestor a, header .navbar-collapse .navbar-nav .nav-item > .sub-menu > li.current_page_parent a {
            color: #ee7f01;
            font-weight: 600; }
            @media (max-width: 1199.98px) {
              header .navbar-collapse .navbar-nav .nav-item > .sub-menu > li.current_page_item a, header .navbar-collapse .navbar-nav .nav-item > .sub-menu > li.current-page-ancestor a, header .navbar-collapse .navbar-nav .nav-item > .sub-menu > li.current_page_parent a {
                font-weight: 500; } }
          header .navbar-collapse .navbar-nav .nav-item > .sub-menu > li.current_page_item:after, header .navbar-collapse .navbar-nav .nav-item > .sub-menu > li.current-page-ancestor:after, header .navbar-collapse .navbar-nav .nav-item > .sub-menu > li.current_page_parent:after {
            content: initial; }
          header .navbar-collapse .navbar-nav .nav-item > .sub-menu .menu-item .sub-menu {
            -webkit-columns: 2;
            columns: 2;
            -webkit-column-gap: 30px;
            column-gap: 30px;
            min-width: auto !important;
            position: relative;
            opacity: 1;
            z-index: 9;
            pointer-events: all;
            transition: opacity .1s ease, transform .3s;
            width: 100%;
            list-style: none;
            padding: 20px 0 5px 30px;
            margin: 0;
            border-left: 0;
            box-shadow: none;
            background: transparent; }
        header .navbar-collapse .navbar-nav .nav-item.menu-item.dropdown_multicol .sub-menu {
          min-width: 680px;
          justify-content: space-between;
          padding: 25px 20px 25px 65px; }
          @media (max-width: 1199.98px) {
            header .navbar-collapse .navbar-nav .nav-item.menu-item.dropdown_multicol .sub-menu {
              min-width: auto;
              padding: 18px 0 18px 18px; } }
          header .navbar-collapse .navbar-nav .nav-item.menu-item.dropdown_multicol .sub-menu li {
            width: calc(50% - 10px);
            display: inline-flex; }
            @media (max-width: 1199.98px) {
              header .navbar-collapse .navbar-nav .nav-item.menu-item.dropdown_multicol .sub-menu li {
                width: 100%; } }
            header .navbar-collapse .navbar-nav .nav-item.menu-item.dropdown_multicol .sub-menu li:first-child {
              width: calc(100% + 50px);
              transform: translateX(-30px); }
              @media (max-width: 1199.98px) {
                header .navbar-collapse .navbar-nav .nav-item.menu-item.dropdown_multicol .sub-menu li:first-child {
                  width: 100%;
                  transform: none; } }
            @media (min-width: 1200px) {
              header .navbar-collapse .navbar-nav .nav-item.menu-item.dropdown_multicol .sub-menu li a {
                padding: 2px 0; } }
        header .navbar-collapse .navbar-nav .nav-item .nav-link {
          font-family: 'Roboto';
          font-size: 18px;
          font-weight: 700;
          color: #4d4d4f;
          transition: color .4s;
          padding: 10px 20px 23px;
          cursor: pointer;
          position: relative; }
          @media (max-width: 1199.98px) {
            header .navbar-collapse .navbar-nav .nav-item .nav-link {
              padding: 16px 0 14px;
              width: 100%;
              border-bottom: 1px solid #E7E8F0; } }
          header .navbar-collapse .navbar-nav .nav-item .nav-link:hover, header .navbar-collapse .navbar-nav .nav-item .nav-link:focus {
            color: #ee7f01; }
          header .navbar-collapse .navbar-nav .nav-item .nav-link.dropdown-toggle {
            padding-right: 32px;
            pointer-events: none; }
        @media (max-width: 1199.98px) {
          header .navbar-collapse .navbar-nav .nav-item:nth-last-child(2) {
            width: 100%;
            margin-right: 25px; } }
        header .navbar-collapse .navbar-nav .nav-item:last-child {
          padding-right: 0; }
          @media (max-width: 1199.98px) {
            header .navbar-collapse .navbar-nav .nav-item:last-child {
              width: 100%;
              margin-right: 25px; } }
          header .navbar-collapse .navbar-nav .nav-item:last-child .nav-link {
            padding-right: 0; }
  header.navbar-open {
    box-shadow: 0 25px 50px 9999px rgba(0, 0, 0, 0.3); }
  header .topmenu {
    display: flex;
    color: #a9a9a9;
    align-items: center;
    height: 44px;
    padding: 0 0 5px;
    background: #fff;
    border-bottom: 1px solid #ececec;
    z-index: 999;
    position: relative;
    transition: margin .4s ease; }
    @media (max-width: 1199.98px) {
      header .topmenu {
        padding: 0; } }
    header .topmenu_nav {
      display: flex;
      list-style: none;
      margin: 0; }
      @media (max-width: 1199.98px) {
        header .topmenu_nav {
          padding: 0; } }
      @media (max-width: 767.98px) {
        header .topmenu_nav {
          margin: 0;
          padding: 20px;
          flex-wrap: wrap; } }
      header .topmenu_nav li {
        margin-right: 15px; }
        @media (max-width: 767.98px) {
          header .topmenu_nav li {
            margin: 0 0 8px;
            width: 100%; } }
        header .topmenu_nav li:last-child {
          margin-right: 0; }
        header .topmenu_nav li:hover > ul.dropdown-menu, header .topmenu_nav li:focus > ul.dropdown-menu {
          display: block; }
        header .topmenu_nav li a {
          padding: 0;
          color: #a9a9a9; }
          header .topmenu_nav li a:hover, header .topmenu_nav li a:focus {
            color: #131f70; }
        header .topmenu_nav li .dropdown-submenu {
          position: relative; }
        header .topmenu_nav li .dropdown-submenu > .dropdown-menu {
          top: 0;
          left: 100%;
          margin-top: -6px; }
    @media (max-width: 991.98px) {
      header .topmenu .col-auto:last-of-type {
        width: 100%;
        display: flex;
        align-items: baseline;
        justify-content: space-between; } }
    header .topmenu .slogan {
      font-family: 'Roboto';
      color: #131f70;
      font-size: 0.9rem;
      padding: 0.4rem 0 0 0.1rem; }
    header .topmenu a {
      font-family: 'Roboto' !important;
      font-size: 0.9rem;
      position: relative;
      margin-right: 3.7rem;
      color: #898FB7;
      text-decoration: none; }
      @media (max-width: 991.98px) {
        header .topmenu a {
          margin: 0 -10px 0 0; } }
      header .topmenu a:hover, header .topmenu a:focus {
        color: #131f70; }
        header .topmenu a:hover:before, header .topmenu a:hover:after, header .topmenu a:focus:before, header .topmenu a:focus:after {
          color: #131f70; }
      header .topmenu a:last-of-type {
        margin-right: 3rem; }
        @media (max-width: 991.98px) {
          header .topmenu a:last-of-type {
            margin-right: 0; } }
  header .topmenu-link {
    font-family: 'Roboto' !important;
    font-size: 0.9rem;
    position: relative;
    margin-right: 3.7rem;
    color: #898FB7;
    text-decoration: none; }
    @media (max-width: 991.98px) {
      header .topmenu-link {
        margin: 0 0 18px; } }
    header .topmenu-link:hover, header .topmenu-link:focus {
      color: #131f70; }
      header .topmenu-link:hover:before, header .topmenu-link:hover:after, header .topmenu-link:focus:before, header .topmenu-link:focus:after {
        color: #131f70; }
    header .topmenu-link:last-of-type {
      margin-right: 3rem; }
      @media (max-width: 991.98px) {
        header .topmenu-link:last-of-type {
          margin-right: 0; } }
  header .icon-mail:before {
    font-family: 'buw';
    content: "\e907";
    font-size: 0.9rem;
    color: #898FB7;
    font-weight: 500;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    transition: color .1s,transform .4s;
    position: relative;
    left: -0.85rem;
    top: 0.05rem; }
    @media (max-width: 991.98px) {
      header .icon-mail:before {
        left: 0;
        margin-right: 15px; } }
  header .icon-phone:before {
    font-family: 'buw';
    content: "\e90b";
    font-size: 1.15rem;
    color: #898FB7;
    font-weight: 500;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    transition: color .1s,transform .4s;
    position: relative;
    left: -0.55rem;
    top: 0.1rem; }
    @media (max-width: 991.98px) {
      header .icon-phone:before {
        font-size: 25px;
        left: 0;
        top: 3px;
        margin-right: 15px; } }
  header .icon-search {
    position: relative;
    display: inline-flex;
    font-size: 1.05rem;
    z-index: 9;
    cursor: pointer;
    width: 1.05rem;
    /* Landscape */ }
    @media (max-width: 991.98px) {
      header .icon-search {
        transform: translateY(1px); } }
    @media (max-width: 767.98px) {
      header .icon-search {
        margin: 0 30px 0 auto;
        font-size: 22px;
        transform: translateY(2px); } }
    @media only screen and (min-device-width: 385px) and (max-device-height: 840px) and (orientation: landscape) {
      header .icon-search {
        margin: 0 30px 0 auto; } }
    @media only screen and (min-device-width: 840px) and (max-device-height: 1120px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: landscape) {
      header .icon-search {
        margin: 0 0 0 30px; } }
    header .icon-search:hover, header .icon-search:focus {
      color: #131f70; }
      header .icon-search:hover:before, header .icon-search:focus:before {
        color: #131f70; }
  header #searchform {
    position: fixed;
    left: 0;
    top: 50px;
    width: 100%;
    height: 131px;
    z-index: 999;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: opacity .25s;
    transition-delay: .5s; }
    @media (max-width: 991.98px) {
      header #searchform {
        padding: 0 80px; } }
    @media (max-width: 767.98px) {
      header #searchform {
        margin: 1rem 1.25rem 0 1.25rem; } }
    @media (max-width: 575.98px) {
      header #searchform {
        margin: 0;
        padding: 0 35px;
        width: calc(100% + 30px);
        height: 100px; } }
    header #searchform:after {
      content: "";
      position: fixed;
      top: 0;
      width: 100%;
      height: 250px;
      background: #131f70;
      transform: translateY(-250px);
      transition: transform .4s ease;
      z-index: -1;
      /* Landscape */ }
      @media (max-width: 1199.98px) {
        header #searchform:after {
          top: -10px;
          width: 100vh; } }
      @media (max-width: 575.98px) {
        header #searchform:after {
          height: 210px; } }
      @media only screen and (min-device-width: 385px) and (max-device-height: 840px) and (orientation: landscape) {
        header #searchform:after {
          width: 200vw; } }
    header #searchform input {
      border: 0;
      border-bottom: 2px solid #ffffff;
      border-radius: 0;
      max-width: 700px;
      height: 75px;
      width: 100%;
      padding: 10px;
      color: #fff;
      position: relative;
      margin: 0;
      transition: border-color .3s, opacity .4s;
      background: transparent;
      font-size: 24px;
      opacity: 0; }
      @media (max-width: 575.98px) {
        header #searchform input {
          font-size: 20px;
          height: 60px; } }
      header #searchform input:focus {
        color: #fff;
        border-color: #ee7f01;
        outline: 0; }
      header #searchform input::placeholder {
        color: #ffffff7a; }
    header #searchform #search_submit {
      -webkit-appearance: none;
      border: 0;
      background: transparent;
      font-size: 0;
      position: relative;
      display: inline-block;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translateX(-50px);
      cursor: pointer;
      z-index: 999;
      transition: opacity .4s;
      opacity: 0; }
      header #searchform #search_submit:before {
        font-family: 'buw';
        content: "\e90d";
        font-size: 1.15rem;
        font-weight: 300;
        color: #ffffff7a;
        position: absolute;
        z-index: 9;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
        transition: color .2s; }
        @media (max-width: 575.98px) {
          header #searchform #search_submit:before {
            font-size: 1.05rem; } }
      header #searchform #search_submit:hover:before, header #searchform #search_submit:focus:before {
        color: #fff; }
  header .dropdown_language {
    font-family: 'Roboto';
    font-size: 0.9rem;
    position: relative;
    color: #999999;
    text-decoration: none;
    width: 2rem;
    margin-right: 2.4rem;
    display: inline-flex; }
    @media (max-width: 991.98px) {
      header .dropdown_language {
        margin: 0 0 9px; } }
    header .dropdown_language #languageDropdown {
      color: #898FB7;
      font-size: 18px;
      transform: translateY(-1px); }
      header .dropdown_language #languageDropdown:hover, header .dropdown_language #languageDropdown:focus {
        color: #131f70; }
        header .dropdown_language #languageDropdown:hover:before, header .dropdown_language #languageDropdown:hover:after, header .dropdown_language #languageDropdown:focus:before, header .dropdown_language #languageDropdown:focus:after {
          color: #131f70; }
      header .dropdown_language #languageDropdown:before {
        font-family: 'buw';
        content: "\e909";
        font-size: 20px;
        color: #898FB7;
        font-weight: 500;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        transition: color .1s,transform .4s;
        position: relative;
        left: -5px;
        top: 2px; }
        @media (max-width: 1199.98px) {
          header .dropdown_language #languageDropdown:before {
            left: 0;
            margin-right: 10px; } }
      header .dropdown_language #languageDropdown:after {
        font-family: 'buw';
        content: "\e901";
        font-size: 6px;
        color: #898FB7;
        font-weight: 500;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        transition: color .1s,transform .4s;
        position: relative;
        right: -1px;
        top: -2px;
        border: 0;
        transform: rotate(-360deg);
        transform-origin: center; }
    header .dropdown_language .dropdown-menu {
      border: 1px solid #e2e2e2;
      border-top: 0;
      border-radius: 0;
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;
      margin: 5px -7px;
      width: 78px;
      min-width: auto;
      text-align: center;
      top: 6px !important;
      left: -10px !important;
      box-shadow: 0px 15px 20px #0000000d; }
      @media (max-width: 575.98px) {
        header .dropdown_language .dropdown-menu {
          margin: 30px 0 0 10px; } }
      header .dropdown_language .dropdown-menu .dropdown-item {
        padding: 3px 11px;
        margin: 0; }
        @media (max-width: 575.98px) {
          header .dropdown_language .dropdown-menu .dropdown-item {
            font-size: 18px;
            color: #999999; } }
        header .dropdown_language .dropdown-menu .dropdown-item:hover, header .dropdown_language .dropdown-menu .dropdown-item:focus {
          color: #131f70;
          background: transparent; }
        header .dropdown_language .dropdown-menu .dropdown-item.active, header .dropdown_language .dropdown-menu .dropdown-item.wpml-ls-current-language {
          color: #131f70;
          font-weight: 800;
          background: #fff;
          pointer-events: none; }
  header .nav-elements {
    margin: 60px 0 0;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: column; }
  header #menu-hauptmenue {
    opacity: 0; }
  header.slim .topmenu {
    margin-top: -1.75rem; }
  header .collapsing {
    transition: none !important; }

@media (max-width: 767.98px) {
  .navbar-open header {
    box-shadow: 0 25px 50px 9999px rgba(255, 255, 255, 0.35); } }

@media (max-width: 1199.98px) {
  .no-scroll:after {
    content: "";
    background: #ffffffc4;
    position: fixed;
    z-index: 99;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; } }

.search-open {
  overflow: hidden; }
  .search-open:after {
    content: "";
    background: #ffffffc4;
    position: fixed;
    z-index: 99;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }
  @media (min-width: 1200px) {
    .search-open body {
      padding-right: 6px; } }
  .search-open header .icon-search {
    z-index: 1000; }
    @media (max-width: 575.98px) {
      .search-open header .icon-search {
        position: absolute;
        right: 4px;
        top: 20px;
        margin: 0; } }
    .search-open header .icon-search:hover:before, .search-open header .icon-search:focus:before {
      color: #fff; }
    .search-open header .icon-search:before {
      content: "✕";
      font-size: 25px;
      color: #ffffff87;
      transition: color .3s; }
      @media (max-width: 575.98px) {
        .search-open header .icon-search:before {
          font-size: 23px; } }
  .search-open header #searchform {
    opacity: 1;
    pointer-events: all;
    transition: opacity 0s;
    transition-delay: 0s; }
    @media (min-width: 1400px) {
      .search-open header #searchform {
        padding-right: 6px; } }
    .search-open header #searchform:after {
      transform: translateY(0); }
    .search-open header #searchform input,
    .search-open header #searchform #search_submit {
      opacity: 1;
      transition-delay: 0.35s; }
  .search-open header .navbar-collapse.show,
  .search-open header #nav-toggle.open {
    z-index: 982 !important; }

.pagedetail-head {
  display: flex;
  padding: 0;
  margin: -20px 0 35px;
  justify-content: space-between;
  color: #666;
  font-style: italic;
  flex-wrap: wrap;
  margin: -10px 0 35px; }
  .pagedetail-head a {
    color: #666; }
    .pagedetail-head a:hover, .pagedetail-head a:focus {
      color: #ee7f01; }
  .pagedetail-head .pagedetail_location {
    display: flex;
    align-items: center;
    margin-bottom: 10px; }
    .pagedetail-head .pagedetail_location img {
      margin-right: 10px; }
  .pagedetail-head .pagedetail_addlocation .icon {
    font-weight: 900;
    font-size: 19px;
    color: #ee7f01;
    margin-right: 7px;
    vertical-align: text-top;
    transition: color .3s; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.section {
  position: relative;
  padding: 6.25rem 0;
  width: 100%; }
  .section--blog img {
    width: auto;
    height: auto;
    max-width: 100%; }
    @media (max-width: 767.98px) {
      .section--blog img {
        margin: 20px 0; } }
  .section--blog .col {
    max-width: 996px; }
  .section--gray {
    background: #F8F8F8; }
  .section--title {
    padding: 0; }
    @media (max-width: 991.98px) {
      .section--title .img-block img {
        margin: 15px 0 90px -80px;
        width: calc(100% + 160px);
        height: 420px; } }
    @media (max-width: 575.98px) {
      .section--title .img-block img {
        margin: 15px 0 80px -35px;
        width: calc(100% + 70px);
        height: 260px; } }
    @media (max-width: 991.98px) {
      .section--title .img-block_mobile img {
        margin: 15px 0 90px -80px;
        width: calc(100% + 160px);
        height: 420px;
        object-fit: cover; } }
    @media (max-width: 575.98px) {
      .section--title .img-block_mobile img {
        margin: 15px 0 80px -35px;
        width: calc(100% + 70px);
        height: 260px; } }
  .section--content {
    padding: 100px 0; }
    @media (max-width: 991.98px) {
      .section--content {
        padding: 60px 0; } }
    .section--content figure {
      max-width: 668px; }
      @media (max-width: 991.98px) {
        .section--content figure {
          max-width: 100%; } }
      .section--content figure img {
        max-width: 668px;
        width: 100%;
        height: auto; }
      .section--content figure figcaption {
        font-size: 18px;
        line-height: 30px;
        font-style: italic;
        color: #ccc;
        margin-top: 15px; }
        @media (max-width: 991.98px) {
          .section--content figure figcaption {
            margin-top: 12px; } }
        @media (max-width: 575.98px) {
          .section--content figure figcaption {
            font-size: 16px;
            line-height: 24px; } }
    .section--content img {
      width: 100%;
      height: auto; }
  .section--narrow {
    padding: 65px 0; }
  .section--sm {
    padding: 85px 0; }
    @media (max-width: 575.98px) {
      .section--sm {
        padding: 65px 0; } }
  .section--md {
    padding: 105px 0 87px; }
    @media (max-width: 575.98px) {
      .section--md {
        padding: 70px 0 100px; } }
  .section--product_header {
    background: url(../Images/Assets/products_bg.png) no-repeat;
    background-position: bottom right;
    background-size: auto;
    margin-top: -110px;
    padding: 127px 0 90px;
    position: relative; }
    @media (max-width: 1199.98px) {
      .section--product_header {
        margin-top: -100px;
        padding: 120px 0 30px;
        background-position: top right;
        background-color: #f8f8f8; } }
    @media (max-width: 575.98px) {
      .section--product_header {
        padding: 120px 0 15px;
        background-size: 180%; } }
    @media (max-width: 1199.98px) {
      .section--product_header:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(0deg, #f8f8f8 15%, rgba(252, 252, 252, 0));
        z-index: 0; } }
    @media (max-width: 575.98px) {
      .section--product_header:after {
        background: linear-gradient(0deg, #f8f8f8 45%, rgba(252, 252, 252, 0)); } }
    @media screen and (max-width: 400px) {
      .section--product_header:after {
        top: 280px;
        height: 200px; } }
    .section--product_header .row {
      z-index: 9;
      position: relative; }
    .section--product_header picture {
      display: flex;
      align-items: center; }
    .section--product_header img {
      width: auto;
      height: auto;
      max-width: 600px;
      transform: translate(-100px, -30px);
      /* Landscape Tablet */ }
      @media (max-width: 1399.98px) {
        .section--product_header img {
          max-width: 480px;
          transform: translate(50px, -30px); } }
      @media (max-width: 1199.98px) {
        .section--product_header img {
          max-width: calc(100% + 70px);
          transform: translate(-20px, -60px); } }
      @media (max-width: 767.98px) {
        .section--product_header img {
          width: 350px;
          margin-bottom: 20px;
          transform: none; } }
      @media (max-width: 575.98px) {
        .section--product_header img {
          max-height: 220px;
          max-width: 100%;
          width: auto;
          margin-bottom: 35px; } }
      @media only screen and (min-device-width: 680px) and (max-device-height: 840px) and (orientation: landscape) {
        .section--product_header img {
          width: calc(100% + 100px);
          max-height: 340px;
          transform: translate(-50px, -90px); } }
      .section--product_header img.teaser-pulled {
        transform: translate(40px, -60px);
        max-height: 540px;
        margin-bottom: -50px; }
        @media (max-width: 1399.98px) {
          .section--product_header img.teaser-pulled {
            transform: translate(100px, -60px); } }
        @media (max-width: 1199.98px) {
          .section--product_header img.teaser-pulled {
            transform: none;
            margin-bottom: 30px; } }
        @media (max-width: 767.98px) {
          .section--product_header img.teaser-pulled {
            max-height: 420px; } }
        @media (max-width: 575.98px) {
          .section--product_header img.teaser-pulled {
            max-height: 310px; } }
      @media (min-width: 1200px) {
        .section--product_header img.teaser-xl {
          min-width: calc(100% + 70px); } }
    @media (max-width: 991.98px) {
      .section--product_header .btn ~ .btn {
        margin-left: 0; } }
  .section--img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }
    @media (max-width: 575.98px) {
      .section--img {
        padding: 50px 0 30px; } }
  .section--benefits {
    padding: 100px 0; }
    @media (max-width: 991.98px) {
      .section--benefits {
        padding: 100px 0 60px; } }
    @media (max-width: 575.98px) {
      .section--benefits {
        padding: 75px 0 40px; } }
    .section--benefits_block {
      position: absolute;
      right: 0; }
      @media (max-width: 1199.98px) {
        .section--benefits_block {
          position: relative; } }
      @media (max-width: 575.98px) {
        .section--benefits_block {
          right: initial;
          max-width: 100%;
          padding-right: calc(var(--bs-gutter-x) / 2);
          padding-left: calc(var(--bs-gutter-x) / 2); } }
    .section--benefits_content {
      max-width: 860px;
      margin: 0 100px 0 auto; }
      @media screen and (max-width: 1600px) {
        .section--benefits_content {
          max-width: 770px;
          margin: 0 0 0 auto; } }
      @media (max-width: 1199.98px) {
        .section--benefits_content {
          margin: 0; } }
    .section--benefits .img-block {
      margin: -200px 0 0 -140px;
      display: block;
      width: auto; }
      @media (min-width: 992px) {
        .section--benefits .img-block {
          min-width: 750px;
          min-height: 1110px; } }
      @media screen and (max-width: 1600px) {
        .section--benefits .img-block {
          margin: -200px 0 0 -180px; } }
      @media screen and (max-width: 1400px) {
        .section--benefits .img-block {
          margin: -200px 0 0 -320px; } }
      @media (max-width: 1199.98px) {
        .section--benefits .img-block {
          margin: -105px 0 80px -80px;
          min-height: auto;
          min-width: auto; } }
      @media (max-width: 575.98px) {
        .section--benefits .img-block {
          margin: -105px 0 45px -50px; } }
      .section--benefits .img-block img {
        width: auto;
        height: auto;
        box-shadow: 0px 0px 40px #4d4d4f33; }
        @media (max-width: 1199.98px) {
          .section--benefits .img-block img {
            width: calc(100% + 200px) !important; } }
        @media (max-width: 575.98px) {
          .section--benefits .img-block img {
            width: calc(100% + 105px) !important; } }
  @media (max-width: 575.98px) {
    .section--tags {
      padding: 50px 0 40px;
      margin: 30px 0 70px !important; } }
  .section--search {
    padding: 60px 0;
    counter-reset: section; }
    .section--search article {
      padding: 40px 0;
      border-bottom: 1px solid #ececec;
      max-width: 990px; }
      .section--search article:first-of-type {
        padding-top: 0; }
      .section--search article h2, .section--search article .h2 {
        margin-bottom: 15px; }
        .section--search article h2:before, .section--search article .h2:before {
          counter-increment: section;
          content: "" counter(section) ") ";
          display: inline-block;
          margin-right: 15px;
          color: #c7c7c7; }
  .section-illustration {
    background-size: cover !important;
    background-position: top right !important;
    background-repeat: no-repeat !important; }
    @media (max-width: 1199.98px) {
      .section-illustration {
        background: #fff !important; } }
    .section-illustration_v2 {
      background-position: top left !important; }
      @media (max-width: 1199.98px) {
        .section-illustration_v2 {
          background: #fff !important; } }
  .section#home {
    background: #fff;
    padding: 110px 0 265px;
    background-size: auto !important;
    background-repeat: no-repeat !important;
    background-position: right bottom !important; }
    @media screen and (min-width: 2100px) {
      .section#home {
        background-position: 80% bottom !important; } }
    @media screen and (max-width: 1660px) {
      .section#home {
        background-position: 160% bottom !important; } }
    @media screen and (max-width: 1500px) {
      .section#home {
        background-position: 200% bottom !important; } }
    @media screen and (max-width: 1400px) {
      .section#home {
        background-position: 160% 90% !important;
        background-size: 70% !important; } }
    @media (max-width: 1199.98px) {
      .section#home {
        padding-top: 470px;
        background-position: center -30px !important;
        background-size: auto !important; } }
    @media (max-width: 575.98px) {
      .section#home {
        padding: 270px 0 250px;
        background-position: center top !important;
        background-size: 140% !important; } }
    @media screen and (min-width: 2100px) {
      .section#home:after {
        content: "";
        position: absolute;
        right: -50px;
        top: 0;
        width: 19%;
        height: 920px;
        background: linear-gradient(270deg, #ffffff 78%, #fcfcfc00 100%); } }

@media (min-width: 768px) {
  #sectionVideos iframe[src*="youtube"] {
    height: 460px; } }
