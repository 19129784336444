img {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    user-drag: none;
    -webkit-touch-callout: none;
}

p img {

    @include media-breakpoint-down(sm) {
        width: 100%;
        height: auto;
    }
}
  
.img-responsive {
    display: block;
    width: 100%;
    height: auto;
}


.img-center {
    display: block;
    margin: 0 auto;
}

.title-icon {
    display: block;
    margin: 0 auto 30px;
    max-width: 200px;
    max-height: 70px;
    width: auto;
    height: auto;
}


.img-sm {
    max-width: 520px !important;

    ~ figcaption {
        max-width: 520px !important;
    }
}


.img-block {
    position: relative;

    &_narrow {
        
        img {
            max-height: 420px;
            object-fit: cover;
            object-position: center;
            box-shadow: 0px 0px 30px #4d4d4f33;
            margin: 20px 0 100px;
        }
    }

    &_full {
        
        @include media-breakpoint-down(xl) {
            margin-bottom: -90px;
        }
        
        @include media-breakpoint-down(sm) {
            margin-bottom: -50px;
        }

        img {
            width: 100%;
            height: auto;

            @include media-breakpoint-down(xl) {
                margin-left: -80px;
                width: calc(100% + 160px)!important;
            }

            @include media-breakpoint-down(md) {
                margin-left: -36px;
                width: calc(100% + 72px)!important;
            }
        }
    }

    &_xs-margin {

        img {
            margin-bottom: 5px;
            margin-bottom: 25px;

            @include media-breakpoint-down(lg) {
                margin-bottom: 35px !important;
            }
        }
    }


    &_sm {

        img {
            width: 100%;
            max-width: 1230px;
            height: auto;
        }
    }

    &_xl {

        img {
            max-height: 510px;
        }
    }


    &_no-shadow {

        img {
            box-shadow: none;
        }
    }


    &_mobile {

    }

    picture {
        display: block;
        box-shadow: 0px 0px 40px #4d4d4f33;
    }

    img {

        @media screen and (max-width: 1600px) {
            width: 100%;
            height: auto;
        }
    }

    figcaption {
        background: $brand-primary;
        color: #fff;
        border-radius: 6px;
        border-top-left-radius: 50px;
        border-bottom-right-radius: 50px;
        max-width: 625px;
        padding: 13px 80px 17px;
        transform: translate(-65px, -40px);
        box-shadow: 20px 20px 40px #131F7033;
        
        &:after {

            @include media-breakpoint-down(sm) {
                content: "";
                position: absolute;
                left: -100%;
                top: 0;
                width: 100vh;
                height: 100%;
                background: $brand-primary;
                z-index: -1;
            }
        }

        @include media-breakpoint-down(xl) {
            transform: translateY(-55px);
            margin: 0 auto;
        }

        @include media-breakpoint-down(sm) {
            transform: translateY(-15px);
            margin: 0 auto;
            padding: 15px 0 20px;
            border-radius: 0;
        }


        ul {
            color: #fff;
            padding: 0;
            margin: 0;
            list-style: none;
            display: flex;
            justify-content: space-between;

            li {
                display: inline-block;
                font-size: 14px;
                font-weight: 300;
                line-height: 20px;

                @include media-breakpoint-down(sm) {
                    width: 33%;
                    line-height: 18px;
                }

                strong {
                    font-family: "Roboto";
                    font-size: 36px;
                    line-height: 44px;
                    display: block;

                    @include media-breakpoint-down(sm) {
                        font-size: 28px;
                        line-height: 41px;
                    }
                }
            }

        }
    }

}



.img-md {

    @include media-breakpoint-up(lg) {
        width: 740px !important;
        max-width: 740px !important;
    }
}



.img-grid {
    max-width: 791px !important;
    width: 100vh !important;
    margin-top: -40px;

    @include media-breakpoint-down(xl) {
        max-width: 100% !important;
        width: 100% !important;
        margin: 20px 0 0;
    }
}

.img-title-block {
    padding: 30px 60px 30px 30px;
    margin-bottom: 20px;
    background: #fff;
    box-shadow: 0px 0px 30px #e7e8f0;
    width: fit-content;
    display: block;
            
    @include media-breakpoint-down(lg) {
        padding: 25px 20px;
    }
            
    @include media-breakpoint-down(sm) {
        padding: 10px;
    }
    
    img {
        padding: 0 !important;
        box-shadow: none !important;
        margin: 0 !important;
    }

    strong, .h5 {
        margin-bottom: 15px;
    }

    p {
        margin-bottom: 0;
    }
}