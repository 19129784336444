// Colors
$gray-darker:             #2A2A2A;
$gray-dark:               #4d4d4f;
$gray:                    #999999;
$gray-light:              #cccccc;
$gray-lighter:            #f3f3f7;
 
$brand-primary:            #131f70;
$brand-primary-hover:      #131F70CC;
$brand-primary-light:      #898FB7;

$brand-secondary:          #ee7f01;

$text-color:            $gray-dark;

$link-color:            $text-color;
$link-hover-color:      $brand-primary;
$link-hover-decoration: underline;

$scrollbar-width: 6px;

$font-family-sans-serif:  'Lato', Helvetica, Arial, sans-serif;




///// WEBFONTS /////

/* lato-regular - latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''),
         url('../webfonts/lato-v17-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../webfonts/lato-v17-latin-regular.woff') format('woff'), /* Modern Browsers */
  }
  /* lato-700 - latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local(''),
         url('../webfonts/lato-v17-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../webfonts/lato-v17-latin-700.woff') format('woff'), /* Modern Browsers */
  }
  /* lato-900 - latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: local(''),
         url('../webfonts/lato-v17-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
         url('../webfonts/lato-v17-latin-900.woff') format('woff'), /* Modern Browsers */
  }


/* roboto-regular - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''),
         url('../webfonts/roboto-v27-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../webfonts/roboto-v27-latin-regular.woff') format('woff'), /* Modern Browsers */
  }
  /* roboto-500 - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local(''),
         url('../webfonts/roboto-v27-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('../webfonts/roboto-v27-latin-500.woff') format('woff'), /* Modern Browsers */
  }
  /* roboto-700 - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local(''),
         url('../webfonts/roboto-v27-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../webfonts/roboto-v27-latin-700.woff') format('woff'), /* Modern Browsers */
  }




//convert px to rem
$rem-baseline: 20px !default;
$rem-fallback: false !default;
$rem-px-only: false !default;

@function rem-separator($list, $separator: false) {
  @if $separator == "comma" or $separator == "space" {
    @return append($list, null, $separator);
  } 
  
  @if function-exists("list-separator") == true {
    @return list-separator($list);
  }

  // list-separator polyfill by Hugo Giraudel (https://sass-compatibility.github.io/#list_separator_function)
  $test-list: ();
  @each $item in $list {
    $test-list: append($test-list, $item, space);
  }

  @return if($test-list == $list, space, comma);
}

@mixin rem-baseline($zoom: 100%) {
  font-size: $zoom / 20px * $rem-baseline;
}

@function rem-convert($to, $values...) {
  $result: ();
  $separator: rem-separator($values);
  
  @each $value in $values {
    @if type-of($value) == "number" and unit($value) == "rem" and $to == "px" {
      $result: append($result, $value / 1rem * $rem-baseline, $separator);
    } @else if type-of($value) == "number" and unit($value) == "px" and $to == "rem" {
      $result: append($result, $value / $rem-baseline * 1rem, $separator);
    } @else if type-of($value) == "list" {
      $value-separator: rem-separator($value);
      $value: rem-convert($to, $value...);
      $value: rem-separator($value, $value-separator);
      $result: append($result, $value, $separator);
    } @else {
      $result: append($result, $value, $separator);
    }
  }

  @return if(length($result) == 1, nth($result, 1), $result);
}

@function rem($values...) {
  @if $rem-px-only {
    @return rem-convert(px, $values...);
  } @else {
    @return rem-convert(rem, $values...);
  }
}

@mixin rem($properties, $values...) {
  @if type-of($properties) == "map" {
    @each $property in map-keys($properties) {
      @include rem($property, map-get($properties, $property));
    }
  } @else {
    @each $property in $properties {
      @if $rem-fallback or $rem-px-only {
        #{$property}: rem-convert(px, $values...);
      }
      @if not $rem-px-only {
        #{$property}: rem-convert(rem, $values...);
      }
    }
  }
}