h1, .h1 {
    font-size: rem(50px);
    line-height: rem(60px);
    font-weight: 500;
    margin-bottom: rem(26px);

    @include media-breakpoint-down(lg) {
        font-size: 38px;
        line-height: 48px;
    }

    @include media-breakpoint-down(sm) {
        font-size: rem(28px);
        line-height: rem(34px);
        margin-bottom: rem(26px);
    }

    @media screen and (max-width: 360px) {
        font-size: rem(27px);
        line-height: rem(32px);
        width: calc(100% + 20px);
    }

    //Subline
    &_subline,
    em {
        font-size: 20px;
        font-weight: 700;
        line-height: 42px;
        font-style: normal;
        display: block;

        @include media-breakpoint-down(lg) {
            font-size: 18px;
            line-height: 26px;
            margin-bottom: 5px;
        }

    }
}

h2, .h2 {
    font-size: rem(38px);
    line-height: rem(48px);
    font-weight: 500;
    margin-bottom: rem(38px);

    @include media-breakpoint-down(sm) {
        font-size: rem(28px);
        line-height: rem(34px);
        margin-bottom: rem(20px);
    }
}

h3, .h3 {
    font-size: rem(30px);
    line-height: rem(40px);
    font-weight: 700;
    margin-bottom: rem(32px);

    @include media-breakpoint-down(sm) {
        font-size: rem(24px);
        line-height: rem(30px);
        margin-bottom: rem(28px);
    }

    &_sm {
        font-size: rem(26px);
        line-height: rem(30px);
        font-weight: 700;
        margin-bottom: rem(17px);
    }
}


h4, .h4 {
    font-size: rem(24px);
    line-height: rem(32px);
    font-weight: 700;
    margin-bottom: rem(22px);
    
    @include media-breakpoint-down(sm) {
        margin-bottom: 12px;
    }
}

h5, .h5 {
    font-size: rem(20px);
    line-height: rem(28px);
    font-weight: 700;
    margin-bottom: rem(14px);
    
    @include media-breakpoint-down(xl) {
        font-size: rem(18px);
    }
}


.h1_mobile {

    @include media-breakpoint-down(sm) {
        font-size: rem(28px);
        line-height: rem(34px);
        font-weight: 500;
        margin-bottom: rem(20px);
    }
}





h1,.h1, h2,.h2,
h3,.h3, h4,.h4,
h5,.h5, h6,.h6 {
    font-family: "Roboto";
    color: $gray-darker;
    position: relative;
    transition: color .3s;
}


p, ul, ol,
ol ol, ol ul, ul ol, ul ul,
.tribe-events-content p {
    font-weight: 400;
    color: $gray;
    line-height: rem(32px);
    margin-bottom: rem(33px);

    @include media-breakpoint-down(sm) {
        font-size: 18px;
        margin-bottom: 20px;
        line-height: rem(28px);
    }
}

p a {
    color: $brand-secondary;

    &:hover,
    &:focus {
        color: $brand-primary;
    }
}

.br-desktop {
    
    @include media-breakpoint-down(xl) {
        display: none;
    }
}

.br-mobile {
    
    @include media-breakpoint-up(sm) {
        display: none;
    }
}


blockquote {
    font-size: 24px;
    font-weight: 500;
    font-style: italic;
    padding: 20px 25px;
    margin: 0 30px 60px;
    color: $brand-primary;
    border-left: 4px solid $brand-primary;
    display: inline-block;
    text-transform: uppercase;

    p {
        margin: 0;
        line-height: 31px;
    }
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-internal-autofill-selected {
    -webkit-text-fill-color: $gray-dark !important;
    background-color: #fff !important;
}


b, strong, .strong {
    font-weight: 700;
}



.content-block {
    margin-bottom: 45px;

    &:after {
        clear: both;
        content: "";
        display: table;
    }
}


.meta {
    color: $brand-primary;
    font-size: 18px;

    @include media-breakpoint-down(sm) {
        font-size: 17px;
    }


    .divider {
        display: inline;
        margin: 0 6px;
        position: relative;
        top: -1px;
    }
}




.table-wrapper {
    
    @include media-breakpoint-down(lg) {
        overflow-x: auto;
    }
}

table {
    width: 100%;
    margin-bottom: 30px;
    white-space: nowrap;
    
    @include media-breakpoint-down(sm) {
        font-size: 18px;
    }

    th {
        padding: 11px 10px;
        background: #f8f8f8;
        font-weight: 600;
    }

    td {
        padding: 10px;
        border: 1px solid #f8f8f8;
    }

    a {
        color: $brand-secondary;

        &:hover,
        &:focus {
            color: $brand-primary;
        }
    }
}