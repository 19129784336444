a,
button {
    cursor: pointer;
    color: $brand-primary;
    text-decoration: none;
    transition: color .3s;

    &:hover,
    &:focus {
        color: $brand-secondary;
    }
}

.btn.active.focus-visible, .btn.active:focus-visible, .btn.focus-visible,
.btn:active.focus-visible, .btn:active:focus-visible, .btn:focus-visible,
:focus-visible, :focus-visible:active, a:focus-visible, button:focus-visible,
button:focus-visible:active {
    outline: 1px solid $brand-primary;
    box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: $brand-primary;
    border-color: transparent;
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: $brand-primary;
    border-color: $brand-primary;
}


button {
    border: none;
    padding: 0;
    background: none;
}


.btn,
.button {
    font-family: "Roboto";
    font-size: rem(18px);
    font-weight: 600;
    color: #fff;
    padding: rem(19px 31px 16px);
    border-radius: rem(5px);
    background: $brand-primary;
    text-decoration: none;
    margin-bottom: rem(20px);
    border: 0;
    transition: background .3s, color .2s, border-color .3s;

    @include media-breakpoint-down(sm) {
        padding: rem(19px 22px 17px);
        margin-bottom: rem(15px);
    }


    &:hover,
    &:focus {
        color: #fff;
        background: $brand-primary-hover;
        cursor: pointer;
        text-decoration: none;
    }

    &--primary {
        background: $brand-primary;

        &:hover,
        &:focus {
            color: #fff;
            background: $brand-secondary;
        }
    }

    &--ghost {
        background: transparent;
        color: $brand-primary;
        border: 2px solid $brand-primary;
        padding: rem(17px 31px 14px);

        @include media-breakpoint-down(sm) {
            padding: rem(18px 22px 14px);
        }


        &:hover,
        &:focus {
            color: #fff;
            background: $brand-primary;
        }
    }

    &--narrow {
        padding: rem(14px 39px 12px);
    }


    //Transform other links to btn style on mobile
    &_mobile {

        @include media-breakpoint-down(sm) {
            font-family: "Roboto";
            font-size: rem(18px);
            font-weight: 600;
            color: #fff !important;
            padding: rem(21px 31px 18px);
            border-radius: rem(5px);
            background: $brand-primary;
            text-decoration: none;
            margin-bottom: rem(20px);
            border: 0;
            transition: background .3s, color .2s, border-color .3s;
        }
        
        &:after {
            @include media-breakpoint-down(sm) {
                content: initial !important;
            }
        }

        &:hover,
        &:focus {

            @include media-breakpoint-down(sm) {
                color: #fff;
                background: $brand-primary-hover;
                cursor: pointer;
                text-decoration: none;
            }
        }
    } //mobile-btn
 
}






//automatic space between two buttons
.btn ~ .btn {
    margin-left: 15px;

    @include media-breakpoint-down(xl) {
        margin-left: 0;
    }

    @include media-breakpoint-down(md) {
        margin-left: 0;
    }
}



.link-arrow {
    position: relative;
    font-weight: 600;
    color: $brand-secondary;

    &:hover,
    &:focus {
        color: $brand-primary;

        &:after {
            transform: translateX(3px);
        }
    }

    &:after {
        font-family: 'buw';
        content: "\e900";
        font-size: 13px;
        font-weight: 500;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        transition: transform .25s;
        position: relative;
        top: -2px;
        margin-left: 8px;

        @include media-breakpoint-down(lg) {
            margin-left: 5px;
        }

        @include media-breakpoint-down(sm) {
            top: -1px;
        }
    }


    // Icon first
    &_reverse {
        display: inline-block;
        margin: 0 0 12px 12px;

        &:hover,
        &:focus {
    
            &:before {
                transform: translateX(3px);
            }
        }


        &:before {
            font-family: 'buw';
            content: "\e900";
            font-size: 14px;
            font-weight: 500;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            -webkit-transition: -webkit-transform .25s;
            transition: -webkit-transform .25s;
            transition: transform .25s;
            transition: transform .25s, -webkit-transform .25s;
            position: relative;
            top: -2px;
            margin-right: 18px;

            @include media-breakpoint-down(sm) {
                font-size: 13px;
                top: -2px;
                margin-right: 15px;
            }
        }

        &:after {
            content: initial;
        }
    }



    // Back-Link
    &_back {

        &:hover,
        &:focus {
    
            &:before {
                transform: translateX(-3px) rotate(180deg);
            }
        }

        &:before {
            font-family: 'buw';
            content: "\e900";
            font-size: 13.5px;
            font-weight: 500;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            transition: transform .25s;
            position: relative;
            top: -2px;
            margin-right: 20px;
            transform: rotate(180deg);
        }

        &:after {
            content: initial;
        }
    }
}


.link-download {
    position: relative;
    font-weight: 600;
    color: $brand-secondary;
    letter-spacing: -.1px;
    line-height: 31px;
    margin-left: 42px;

    @include media-breakpoint-down(xxl) {
        //font-size: 18px;
        //line-height: 26px;
        margin-left: 40px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 18px;
        line-height: 24px;
        margin-left: 30px;
    }

    &:hover,
    &:focus {
        color: $brand-primary;
    }

    &:before {
        font-family: 'buw';
        content: "\e906";
        font-size: 25px;
        font-weight: 500;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        transition: transform .25s;
        position: absolute;
        top: 0px;
        left: -42px;

        @include media-breakpoint-down(lg) {
            font-size: 24px;
        }

        @include media-breakpoint-down(sm) {
            font-size: 20px;
            top: -2px;
            left: -30px;
        }
    }
}


.hide-link {
    display: none;
}

.no-link {
    text-decoration: none;
    pointer-events: none;
}