footer {
    position: relative;
    background: #fff;
    padding: rem(80px 0 10px);
    text-align: center;
    display: flex !important;
    justify-content: center;
    flex-direction: column;

    @include media-breakpoint-down(sm) {
        padding: rem(40px 0 0);
    }


    .container {

        @include media-breakpoint-down(lg) {
            padding: 0 30px;
        }

        @include media-breakpoint-down(sm) {
            padding: 0 20px;
        }
    }


    .footer-nav_main ul {
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin-bottom: 26px;
        padding-bottom: 72px;
        border-bottom: 1px solid #E7E8F0;

        @include media-breakpoint-down(sm) {
            margin-bottom: 25px;
            padding-bottom: 20px;
            flex-wrap: wrap;
        }

        /* Landscape */
        @media only screen 
        and (min-device-width : 385px)
        and (max-device-height : 840px)
        and (orientation : landscape) {
            padding-bottom: 30px;
        }

        li {
            width: auto;
            margin-right: 70px;
            
            @include media-breakpoint-down(lg) {
                margin-right: 35px;
            }

            @include media-breakpoint-down(sm) {
                margin-right: 19px;
                margin-bottom: 8px;
                letter-spacing: -.1px;
            }

            /* Landscape */
            @media only screen 
            and (min-device-width : 385px)
            and (max-device-height : 840px)
            and (orientation : landscape) {
                margin-right: 12px;
            }

            &:nth-child(3) {

                @include media-breakpoint-down(sm) {
                    margin-right: 0;
                }
            }

            &:last-child {
                margin-right: 0;
            }

            &.current_page_item a {
                color: $brand-secondary;
                font-weight: 600;
            }
        }
    }


    .footer-nav_bottom ul {
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        flex-wrap: wrap;

        @include media-breakpoint-down(sm) {
            margin-bottom: 10px;
        }

        li {
            margin-right: rem(22px);

            &:first-child {
                margin-bottom: 3px;
                width: 100%;
                pointer-events: none;

                @include media-breakpoint-down(sm) {
                    margin: 0 0 11px;
                }
            }

            &:last-child {
                margin-right: 0;
            }

            &.current_page_item a {
                color: $brand-secondary;
            }
        }

        a {
            color: $gray;

            @include media-breakpoint-down(sm) {
                line-height: 26px;
                display: block;
            }

            &:hover,
            &:focus {
                color: $brand-secondary;
            }
        }
    }



    .footer-nav_social {
        list-style: none;
        display: flex;
        justify-content: center;
        margin-bottom: rem(30px);
        padding: 0;

        li {
            margin-right: rem(10px);

            &:last-child {
                margin-right: 0;

                .social-icon {
                    margin-right: 0;
                }
            }
        }

        .social-icon {
            width: rem(46px);
            height: rem(46px);
            background: $brand-primary;
            border-radius: 60%;
            margin-right: rem(12px);
            display: block;
            line-height: rem(46px);
            text-align: center;
            color: #fff;
            text-decoration: none;
            transition: background .3s, color .2s;

            &:before {
                color: #fff;
                font-size: rem(15px);
                font-weight: 300;
                display: block;
            }

            &:hover,
            &:focus {
                background: $brand-secondary;
                color: #fff;
            }

            i {
                margin: 0;
            }
        }

        .icon-xl:before {
            font-size: 18px !important;
        }

    }

    



    .footer-brand img {
        width: 100%;
        max-width: 143px;
        height: auto;
        margin-bottom: 55px;

        @include media-breakpoint-down(sm) {
            max-width: 90px;
            margin-bottom: 33px;
        }
    }

    footer a {
        text-decoration: none;
        letter-spacing: rem(.2px);
    }

}
