header {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 101;
    background: #fff;
    box-shadow: 0px 10px 30px #0000001a;
    transition: box-shadow .3s;

    @include media-breakpoint-down(sm) {
        position: fixed;
    }

    @include media-breakpoint-down(xs) {
    }

    .navbar-brand {
        padding: rem(16px 0 14px);

        @include media-breakpoint-down(sm) {
            transform: none;
            padding: rem(0 0 4px);
        }

        img {
            width: 100%;
            max-width: rem(121px);
            height: auto;
            transition: width .3s, width .3s;

            @include media-breakpoint-down(lg) {
                max-width: rem(100px);
            }

            @include media-breakpoint-down(sm) {
                max-width: rem(70px);
            }
        }

    }

    #nav-toggle {
        position: relative;
        margin: 0;
        transform: rotate(0deg);
        cursor: pointer;
        z-index: 997;
        border: 0;
        padding: 0;
        width: 24px;
        height: 13px;
        
        @include media-breakpoint-down(sm) {
            width: 27px;
        }

        &:focus {
            box-shadow: none;
        }

        &.open {
            z-index: 1006 !important;
        }

        span {
            display: block;
            position: absolute;
            height: 2px;
            width: 23px;
            background: $brand-primary-light;
            opacity: 1;
            left: 0;
            border-radius: 0px;
            transform: rotate(0deg);
            transition: .25s ease-in-out;

            &:nth-child(1) {
                top: 0px;
            }

            &:nth-child(2),
            &:nth-child(3) {
                top: rem(6px);
            }

            &:nth-child(4) {
                top: rem(12px);
            }
        }

        &.open {

            span:nth-child(1) {
                top: rem(12px);
                width: 0%;
                left: 50%;
            }

            span:nth-child(2) {
                transform: rotate(45deg);
            }

            span:nth-child(3) {
                transform: rotate(-45deg);
            }

            span:nth-child(4) {
                top: rem(18px);
                width: 0%;
                left: 50%;
            }

        }
    }

    .navbar {
        height: 110px;
        padding: 0;
        transition: padding .2s ease;
        flex-wrap: wrap;

        @include media-breakpoint-down(lg) {
            height: 88px;
        }

        @include media-breakpoint-down(sm) {
            height: 72px;
        }
    }


    .navbar-collapse {
        position: static;
        z-index: 100;
        padding: 0;
        margin-bottom: 0;
        align-self: flex-end;

        @include media-breakpoint-down(xl) {
            position: absolute;
            z-index: 998;
            padding: 0;
            margin-bottom: 0;
            right: 0;
            width: 100%;
            max-width: 340px;
            height: 100vh;
            padding: 90px 0 50px 32px;
            top: 0px;
            background: #fff;
            border-left: 10px solid $brand-secondary;
            box-shadow: -20px -5px 30px #0000001a, 320px -5px 30px #0000001a;
            box-shadow: 210px 0 0 200px #fff, -20px -5px 30px #0000001a, 320px -5px 30px #0000001a;
            display: none;
            overflow-y: scroll;
        }

        @include media-breakpoint-down(sm) {
            top: 72px;
            height: calc(100vh - 72px);
            box-shadow: 0 1000px 0 1000px #fff;
            left: 0;
            right: initial;
            max-width: 100%;
            border-left: 0;
            padding: 20px 0;
        }


        /* Landscape */
        @media only screen 
        and (min-device-width : 385px)
        and (max-device-height : 840px)
        and (orientation : landscape) {
            top: 0;
            overflow: hidden;
            overflow-y: auto;
            padding: 90px 35px 120px 30px;
            right: -35px;
        }

        //Tablet Landscape
        @media only screen 
        and (max-device-width : 1200px)
        and (max-device-height : 1120px)
        and (-webkit-min-device-pixel-ratio: 1.5)
        and (orientation : landscape) {
            top: 0;
            padding: 115px 35px 120px 30px;
        }


        &:before {

            @include media-breakpoint-down(xl) {
                content: "";
                width: 100vh;
                background: #ffffff;
                height: 100vh;
                right: -100vh;
                top: 44px;
                position: fixed;
                z-index: -1;
                width: 200px;
                right: 0;
            }

            @include media-breakpoint-down(sm) {
                top: 72px;
                position: fixed;
                right: initial;
                left: 0;
                border-left: 8px solid $brand-secondary;
                width: 100vh;
            }
        }

        &.show {
            display: block;
        }

        &::-webkit-scrollbar {

            /* Landscape */
            @media only screen 
            and (min-device-width : 385px)
            and (max-device-height : 840px)
            and (orientation : landscape) {
                display: none;
            }
        }

        .container {

            @include media-breakpoint-down(md) {
                padding: 0;
            }
        }

        .navbar-nav {
            transition: max-width .5s;
            margin-left: auto;
            margin-right: 0;
            align-items: center;
            opacity: 1 !important;

            @include media-breakpoint-down(lg) {
                margin: 0;
                padding: 0;
                align-items: flex-start;
            }

        

            a {
                text-decoration: none;
            }

            .nav-item {
                padding: 0;
                cursor: pointer;
                position: relative;

                @include media-breakpoint-down(md) {
                    padding: 0;
                    width: 100%;
                }

                &:hover,
                &:focus {

                    .nav-link {
                        color: $brand-secondary;
                    }

                    > ul.sub-menu {

                        @include media-breakpoint-up(xl) {
                            position: absolute;
                            display: block;
                        }
                    }
                }

                // Dropdown Icon
                &.menu-item-has-children {
                    margin-right: 25px;

                    @include media-breakpoint-down(xl) {
                        width: 100%;
                    }

                    //no anchor click for dropdown menu items
                    & > a {

                        @include media-breakpoint-up(xxl) {
                            pointer-events: none;
                        }

                        @include media-breakpoint-down(xl) {
                            width: 100%;
                        }
                    }


                    a {

                        &:after {
                            content: "\e901";
                            position: absolute;
                            font-size: 7px;
                            font-family: "buw" !important;
                            font-weight: normal;
                            font-variant: normal;
                            text-transform: none;
                            line-height: 1;
                            right: 1px;
                            top: 21px;
                            transition: color .1s, transform .3s;

                            @include media-breakpoint-down(xl) {
                                top: 26px;
                            }
                        }

                        &.submenu-open {

                            &:after {
                                @include media-breakpoint-down(xl) {
                                    transform: rotate(180deg);
                                }
                            }
                        }

                    } //a

                    &:hover,
                    &:focus {

                        a:after {
                           color: $brand-secondary;
                        }
                    }

                }


                &.active .nav-link,
                &.current-page-ancestor a,
                &.current_page_parent a,
                &.current-menu-ancestor > a {
                    color: $brand-secondary;
                    
                    &:after {
                        color: $brand-secondary;
                    }
                }


                &:first-child a {
                    //padding-left: 0;
                }


                // Dropdown Menu
                > .sub-menu {
                    position: absolute;
                    display: none;
                    left: 0;
                    transition: opacity .1s ease, transform .3s;
                    width: 100%;
                    min-width: 365px;
                    list-style: none;
                    padding: 25px 30px;
                    margin: 0 0 0 15px;
                    background: #fff;
                    border-left: 10px solid $brand-secondary;
                    box-shadow: 0px 10px 30px #0000001a;

                    @include media-breakpoint-down(xl) {
                        position: relative;
                        min-width: auto;
                        margin: 0;
                        padding: 18px 0 18px 18px;
                        border: 0;
                        box-shadow: none;
                        border-bottom: 1px solid #E7E8F0;
                    }

                    &.open {

                        @include media-breakpoint-down(xl) {
                            display: block;
                        }
                    }


                    > li {

                        // Dropdown Menu Heading
                        &:first-child {

                            & > a {
                                font-family: "Roboto";
                                color: #222;
                                font-size: 18px;
                                font-weight: 700;
                                letter-spacing: 0;
                                border-bottom: 2px solid #f3f3f7;
                                padding: 3px 0 0;
                                margin-bottom: 15px;

                                @include media-breakpoint-down(xl) {
                                    border-bottom: 0;
                                    padding: 0;
                                    margin-bottom: 10px;
                                }
                            }
                        }

                        a {
                            padding: 0px 30px;
                            height: auto;
                            color: $gray;
                            font-size: 18px;
                            line-height: 31px;
                            width: 100%;
                            display: block;
                            background: #fff;

                            @include media-breakpoint-down(xl) {
                                line-height: 27px;
                                margin-bottom: 8px;
                                padding: 0;
                            }

                            @include media-breakpoint-down(md) {
                                margin-bottom: 8px;
                            }

                            &:after {
                                content: initial;
                            }

                            &:hover,
                            &:focus {
                                color: $brand-secondary;
                            }
                        }


                        &.current_page_item,
                        &.current-page-ancestor,
                        &.current_page_parent {

                            a {
                                color: $brand-secondary;
                                font-weight: 600;

                                @include media-breakpoint-down(xl) {
                                    font-weight: 500;
                                }
                            }
        
                            &:after {
                                content: initial;
                            }
                        }
                    }


                    // Second Level Dropdown
                    .menu-item .sub-menu {
                        -webkit-columns: 2;
                        columns: 2;
                        -webkit-column-gap: 30px;
                        column-gap: 30px;
                        min-width: auto !important;
                        position: relative;
                        opacity: 1;
                        z-index: 9;
                        pointer-events: all;
                        transition: opacity .1s ease, transform .3s;
                        width: 100%;
                        list-style: none;
                        padding: 20px 0 5px 30px;
                        margin: 0;
                        border-left: 0;
                        box-shadow: none;
                        background: transparent;
                    }


                } //dropdown-menu


                // Multicol Dropdown Menu
                &.menu-item.dropdown_multicol {

                    .sub-menu {
                        min-width: 680px;
                        justify-content: space-between;
                        padding: 25px 20px 25px 65px;

                        @include media-breakpoint-down(xl) {
                            min-width: auto;
                            padding: 18px 0 18px 18px;
                        }

                        li {
                            width: calc(50% - 10px);
                            display: inline-flex;

                            @include media-breakpoint-down(xl) {
                                width: 100%;
                            }

                            &:first-child {
                                width: calc(100% + 50px);
                                transform: translateX(-30px);

                                @include media-breakpoint-down(xl) {
                                    width: 100%;
                                    transform: none;
                                }
                            }

                            a {

                                @include media-breakpoint-up(xl) {
                                    padding: 2px 0;
                                }
                            }

                        }
                    }

                } // Multicol Dropdown Menu





                .nav-link {
                    font-family: 'Roboto';
                    font-size: 18px;
                    font-weight: 700;
                    color: $gray-dark;
                    transition: color .4s;
                    padding: 10px 20px 23px;
                    cursor: pointer;
                    position: relative;

                    @include media-breakpoint-down(xl) {
                        padding: 16px 0 14px;
                        width: 100%;
                        border-bottom: 1px solid #E7E8F0;
                    }


                    &:hover,
                    &:focus {
                        color: $brand-secondary;
                    }

                    //more right padding for dropdown icon
                    &.dropdown-toggle {
                        padding-right: 32px;
                        pointer-events: none;
                    }
                }

                &:nth-last-child(2) {

                    @include media-breakpoint-down(xl) {
                        width: 100%;
                        margin-right: 25px;
                    }
                }

                &:last-child {
                    padding-right: 0;

                    @include media-breakpoint-down(xl) {
                        width: 100%;
                        margin-right: 25px;
                    }

                    .nav-link {
                        padding-right: 0;
                    }
                }

            } // close nav-item



        } // navbar-nav

    } // navbar-collapse


    // mobile nav shadow also on mobile on third level
    &.navbar-open {
        box-shadow: 0 25px 50px 9999px rgba(0,0,0,.3);
    }


    .topmenu {
        display: flex;
        color: #a9a9a9;
        align-items: center;
        height: 44px;
        padding: 0 0 5px;
        background: #fff;
        border-bottom: 1px solid #ececec;
        z-index: 999;
        position: relative;
        transition: margin .4s ease;

        @include media-breakpoint-down(xl) {
            padding: 0;
        }


        &_nav {
            display: flex;
            list-style: none;
            margin: 0;
            
            @include media-breakpoint-down(xl) {
                padding: 0;
            }

            @include media-breakpoint-down(md) {
                margin: 0;
                padding: 20px;
                flex-wrap: wrap;
            }

            li {
                margin-right: 15px;

                @include media-breakpoint-down(md) {
                    margin: 0 0 8px;
                    width: 100%;
                }

                &:last-child {
                    margin-right: 0;
                }


                &:hover,
                &:focus {

                    & > ul.dropdown-menu {
                        display: block;
                    }
                }


                a {
                    padding: 0;
                    color: #a9a9a9;

                    &:hover,
                    &:focus {
                        color: $brand-primary;
                    }
                }



                .dropdown-submenu {
                    position:relative;
                }

                .dropdown-submenu > .dropdown-menu {
                    top:0;
                    left:100%;
                    margin-top:-6px;
                }

            }
        }

        .col-auto:last-of-type {

            @include media-breakpoint-down(lg) {
                width: 100%;
                display: flex;
                align-items: baseline;
                justify-content: space-between;
            }
        }


        .slogan {
            font-family: 'Roboto';
            color: $brand-primary;
            font-size: rem(18px);
            padding: rem(8px 0 0 2px);
        }

        
        a {
            font-family: 'Roboto' !important;
            font-size: rem(18px);
            position: relative;
            margin-right: rem(74px);
            color: $brand-primary-light;
            text-decoration: none;

            @include media-breakpoint-down(lg) {
                margin: 0 -10px 0 0;
            }


            &:hover,
            &:focus {
                color: $brand-primary;

                &:before,
                &:after {
                    color: $brand-primary;
                }
            }

            &:last-of-type {
                margin-right: rem(60px);

                @include media-breakpoint-down(lg) {
                    margin-right: 0;
                }
            }

        }
    }

    .topmenu-link {
        font-family: 'Roboto' !important;
        font-size: rem(18px);
        position: relative;
        margin-right: rem(74px);
        color: $brand-primary-light;
        text-decoration: none;

        @include media-breakpoint-down(lg) {
            margin: 0 0 18px;
        }


        &:hover,
        &:focus {
            color: $brand-primary;

            &:before,
            &:after {
                color: $brand-primary;
            }
        }

        &:last-of-type {
            margin-right: rem(60px);

            @include media-breakpoint-down(lg) {
                margin-right: 0;
            }
        }

    }


    .icon-mail {

        &:before {
            font-family: 'buw';
            content: "\e907";
            font-size: rem(18px);
            color: $brand-primary-light;
            font-weight: 500;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            transition: color .1s,transform .4s;
            position: relative;
            left: rem(-17px);
            top: rem(1px);

            @include media-breakpoint-down(lg) {
                left: 0;
                margin-right: 15px;
            }
        }
    }


    .icon-phone {

        &:before {
            font-family: 'buw';
            content: "\e90b";
            font-size: rem(23px);
            color: $brand-primary-light;
            font-weight: 500;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            transition: color .1s,transform .4s;
            position: relative;
            left: rem(-11px);
            top: rem(2px);

            @include media-breakpoint-down(lg) {
                font-size: 25px;
                left: 0;
                top: 3px;
                margin-right: 15px;
            }
        }
    }


    .icon-search {
        position: relative;
        display: inline-flex;
        font-size: rem(21px);
        z-index: 9;
        cursor: pointer;
        width: rem(21px);

        @include media-breakpoint-down(lg) {
            transform: translateY(1px);
        }

        @include media-breakpoint-down(md) {
            margin: 0 30px 0 auto;
            font-size: 22px;
            transform: translateY(2px);
        }

        /* Landscape */
        @media only screen 
        and (min-device-width : 385px)
        and (max-device-height : 840px)
        and (orientation : landscape) {
            margin: 0 30px 0 auto;
        }

        //Tablet Landscape
        @media only screen 
        and (min-device-width : 840px)
        and (max-device-height : 1120px)
        and (-webkit-min-device-pixel-ratio: 1.5)
        and (orientation : landscape) {
            margin: 0 0 0 30px;
        }



        &:hover,
        &:focus {
            color: $brand-primary;

            &:before {
                color: $brand-primary;
            }
        }
    }


            
    // search form
    #searchform {
        position: fixed;
        left: 0;
        top: 50px;
        width: 100%;
        height: 131px;
        z-index: 999;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        opacity: 0;
        pointer-events: none;
        transition: opacity .25s;
        transition-delay: .5s;
        
        @include media-breakpoint-down(lg) {
            padding: 0 80px;
        }
        
        @include media-breakpoint-down(md) {
            margin: rem(20px 25px 0 25px);
        }

        @include media-breakpoint-down(sm) {
            margin: 0;
            padding: 0 35px;
            width: calc(100% + 30px);
            height: 100px;
        }

        &:after {
            content: "";
            position: fixed;
            top: 0;
            width: 100%;
            height: 250px;
            background: $brand-primary;
            transform: translateY(-250px);
            transition: transform .4s ease;
            z-index: -1;

            @include media-breakpoint-down(xl) {
                top: -10px;
                width: 100vh;
            }

            @include media-breakpoint-down(sm) {
                height: 210px;
            }

            /* Landscape */
            @media only screen 
            and (min-device-width : 385px)
            and (max-device-height : 840px)
            and (orientation : landscape) {
                width: 200vw;
            }
        }

        input {
            border: 0;
            border-bottom: 2px solid #ffffff;
            border-radius: 0;
            max-width: 700px;
            height: 75px;
            width: 100%;
            padding: 10px;
            color: #fff;
            position: relative;
            margin: 0;
            transition: border-color .3s, opacity .4s;
            background: transparent;
            font-size: 24px;
            opacity: 0;

            @include media-breakpoint-down(sm) {
                font-size: 20px;
                height: 60px;
            }

            
            &:focus {
                color: #fff;
                border-color: $brand-secondary;
                outline: 0;
            }

            &::placeholder {
                color: #ffffff7a;
            }
        }


        #search_submit {
            -webkit-appearance: none;
            border: 0;
            background: transparent;
            font-size: 0;
            position: relative;
            display: inline-block;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: translateX(-50px);
            cursor: pointer;
            z-index: 999;
            transition: opacity .4s;
            opacity: 0;

            &:before {
                font-family: 'buw';
                content: "\e90d";
                font-size: rem(23px);
                font-weight: 300;
                color: #ffffff7a;
                position: absolute;
                z-index: 9;
                left: 0;
                right: 0;
                margin: 0 auto;
                text-align: center;
                transition: color .2s;

                @include media-breakpoint-down(sm) {
                    font-size: rem(21px);
                }
            }

            &:hover:before,
            &:focus:before {
                color: #fff;
            }
        }

    }





    // language select
    .dropdown_language {
        font-family: 'Roboto';
        font-size: rem(18px);
        position: relative;
        color: $gray;
        text-decoration: none;
        width: rem(40px);
        margin-right: rem(48px);
        display: inline-flex;

        @include media-breakpoint-down(lg) {
            margin: 0 0 9px;
        }

        #languageDropdown {
            color: $brand-primary-light;
            font-size: 18px;
            transform: translateY(-1px);

            &:hover,
            &:focus {
                color: $brand-primary;

                &:before,
                &:after {
                    color: $brand-primary;
                }
            }

            &:before {
                font-family: 'buw';
                content: "\e909";
                font-size: 20px;
                color: $brand-primary-light;
                font-weight: 500;
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                transition: color .1s,transform .4s;
                position: relative;
                left: -5px;
                top: 2px;

                @include media-breakpoint-down(xl) {
                    left: 0;
                    margin-right: 10px;
                }
            }

            &:after {
                font-family: 'buw';
                content: "\e901";
                font-size: 6px;
                color: $brand-primary-light;
                font-weight: 500;
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                transition: color .1s,transform .4s;
                position: relative;
                right: -1px;
                top: -2px;
                border: 0;
                transform: rotate(-360deg);
                transform-origin: center;
            }
        }

        .dropdown-menu {
            border: 1px solid #e2e2e2;
            border-top: 0;
            border-radius: 0;
            border-bottom-left-radius: 7px;
            border-bottom-right-radius: 7px;
            margin: 5px -7px;
            width: 78px;
            min-width: auto;
            text-align: center;
            top: 6px !important;
            left: -10px !important;
            box-shadow: 0px 15px 20px #0000000d;

            @include media-breakpoint-down(sm) {
                margin: 30px 0 0 10px;
            }

            .dropdown-item {
                padding: 3px 11px;
                margin: 0;
                
                @include media-breakpoint-down(sm) {
                    font-size: 18px;
                    color: $gray;
                }

                &:hover,
                &:focus {
                    color: $brand-primary;
                    background: transparent;
                }

                &.active,
                &.wpml-ls-current-language {
                    color: $brand-primary;
                    font-weight: 800;
                    background: #fff;
                    pointer-events: none;
                }
            }

        }

    }


    .nav-elements {
        margin: 60px 0 0;
        flex-wrap: wrap;
        justify-content: flex-start;
        flex-direction: column;
    }



    // hide nav with opacity 0 while nav classes are added by js
    #menu-hauptmenue {
        opacity: 0;
    }


    &.slim {

        .topmenu {
            margin-top: rem(-35px);
        }
    }




    .collapsing {
        transition: none !important;
    }

} //header



// 'navbar-open' class added to html if opened
.navbar-open {

    header {

        @include media-breakpoint-down(md) {
            box-shadow: 0 25px 50px 9999px rgba(255, 255, 255, 0.35);
        }
    }
}


    

//same transparency if nav is opened on mobile (as the search-open)
.no-scroll {

    &:after {

        @include media-breakpoint-down(xl) {
            content: "";
            background: #ffffffc4;
            position: fixed;
            z-index: 99;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }
}



// 'search-open' class added to html if opened
.search-open {
    overflow: hidden;

    &:after {
        content: "";
        background: #ffffffc4;
        position: fixed;
        z-index: 99;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    body {

        @include media-breakpoint-up(xl) {
            padding-right: $scrollbar-width;
        }
    }


    header {

        .icon-search {
            z-index: 1000;

            @include media-breakpoint-down(sm) {
                position: absolute;
                right: 4px;
                top: 20px;
                margin: 0;
            }


            &:hover,
            &:focus {

                &:before {
                    color: #fff;
                }
            }
        
            &:before {
                content: "✕";
                font-size: 25px;
                color: #ffffff87;
                transition: color .3s;

                @include media-breakpoint-down(sm) {
                    font-size: 23px;
                }
            }
        }

        #searchform {
            opacity: 1;
            pointer-events: all;
            transition: opacity 0s;
            transition-delay: 0s;

            @include media-breakpoint-up(xxl) {
                padding-right: 6px;
            }

            &:after {
                transform: translateY(0);
            }

            input,
            #search_submit {
                opacity: 1;
                transition-delay: 0.35s;
            }
        }



        .navbar-collapse.show,
        #nav-toggle.open {
            z-index: 982 !important;
        }


    } //header
} //html search open






// Page quick infos below h1 title
.pagedetail-head {
    display: flex;
    padding: 0;
    margin: -20px 0 35px;
    justify-content: space-between;
    color: #666;
    font-style: italic;

    @include media-breakpoint-down(xs) {
        flex-wrap: wrap;
        margin: -10px 0 35px;
    }


    a {
        color: #666;

        &:hover,
        &:focus {
            color: $brand-secondary;
        }
    }

        
    .pagedetail_location {
        display: flex;
        align-items: center;

        @include media-breakpoint-down(xs) {
            margin-bottom: 10px;
        }

        img {
            margin-right: 10px;
        }
    }

    .pagedetail_addlocation {

        .icon {
            font-weight: 900;
            font-size: 19px;
            color: $brand-secondary;
            margin-right: 7px;
            vertical-align: text-top;
            transition: color .3s;
        }
    }


}


//fade in main nav
#navbarNavDropdown {
    
    @include media-breakpoint-up(md) {
        //animation: fadeIn 1.7s ease forwards;
    }

    @keyframes fadeIn {
        0% {opacity:0;}
        50% {opacity:0;}
        100% {opacity:1;}
    }
}

