.list,
.list ul,
.infobox ul {
    padding-left: 24px;
    padding-left: 0;
    margin-bottom: 7px;
    list-style: none;
    
    // Individual list columns
    &_col2 {
        column-count: 2;

        @include media-breakpoint-down(sm) {
            column-count: 1;
        }
    }

    &_col3 {
        column-count: 3;

        @include media-breakpoint-down(lg) {
            column-count: 2;
        }

        @include media-breakpoint-down(sm) {
            column-count: 1;
        }
    }

    &_col4 {
        column-count: 4;

        @include media-breakpoint-down(lg) {
            column-count: 2;
        }

        @include media-breakpoint-down(sm) {
            column-count: 1;
        }
    }

    // Individual list width
    &_sm {
        max-width: 780px;
    }

    &_md {
        max-width: 950px;
    }

    &_xl {
        max-width: 1200px;
    }


    li {
        color: $gray;
        line-height: 30px;
        margin-bottom: 7px;
        padding-left: 21px;
        position: relative;

        &:before {
            content: "•";
            color: $brand-secondary;
            display: inline-block;
            margin-right: rem(8px);
            transform: translateY(-1px);
            position: absolute;
            left: 0;
        }
    } //li

}

.list {
    margin-bottom: 30px;

    &_md {

        li {
            margin-bottom: 15px;
        }
    }
}

.list--checked {
    padding: 0;
    margin-bottom: 53px;

    @include media-breakpoint-down(lg) {
        margin-bottom: 45px;
    }

    @include media-breakpoint-down(sm) {
        margin-bottom: 35px;
    }


    li {
        padding-left: 30px;
        margin-bottom: 3px;
        list-style: none;
        position: relative;

        &:before {
            font-family: 'buw';
            content: "\e911";
            font-size: 16px;
            color: $brand-primary;
            font-weight: 500;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            transition: color .1s,transform .4s;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}


.list--arrows {
    padding: 0;
    list-style: none;
    margin: 0;
    position: relative;
    font-family: 'Roboto';
    font-weight: 700;
    color: $gray-darker;

    li {

        &:before {
            font-family: 'buw';
            content: "\e900";
            font-size: 15px;
            font-weight: 500;
            color: $gray-darker;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            transition: transform .25s;
            position: relative;
            top: -1px;
            left: 0;
            margin-right: 9px;
        }
    }
}


.download-list {
    position: relative;
    max-width: 860px;

    li:first-child {
        position: absolute;
        top: calc(50% - 40px);
        left: calc(100% - 170px);

        @include media-breakpoint-down(lg) {
            position: relative;
            top: 0;
            left: 0;
            margin: 20px 0 25px;
            opacity: .5;
        }


    
        img {
            width: auto;
            max-width: 170px;
            height: auto;
            max-height: 60px;

            @include media-breakpoint-down(lg) {
                max-height: 50px;
            }

            @include media-breakpoint-down(sm) {
                width: auto;
                max-width: initial;
                max-height: 42px;
            }
        }

    }
}


.no-list {
    padding: 0;
    list-style: none;

    li {
        margin-bottom: rem(12px);
    }
}


main .quicklinks {
    font-family: 'Roboto';
    font-weight: 600;
    list-style: none;
    padding: 0;
    columns: 2;
    column-gap: 30px;
    //overflow: hidden;
    max-width: 600px;
    align-self: flex-start;
    margin: 147px auto 33px 0;

    @include media-breakpoint-down(xl) {
        margin: 15px auto 33px 0 !important;
    }

    @include media-breakpoint-down(sm) {
        columns: 1;
    }

    &_list {
        columns: 1;
        margin: 0 auto 33px 170px;
        max-width: 450px;
        align-self: flex-end;

        @include media-breakpoint-down(xl) {
            margin: 20px 0 40px !important;
        }

        @include media-breakpoint-down(sm) {
            margin: 15px 0 25px !important;
        }
    }

    &_pulled {
        margin: 147px -70px 33px auto;
    }

    li {
        min-width: 285px;
        
        //NEW
        margin: 0 0 2px 27px;
    }

    a {
        position: relative;

        &:hover {

            &:before {
                transform: translateX(2px);
            }
        }

        &:before {
            font-family: 'buw';
            content: "\e900";
            font-size: 13px;
            font-weight: 500;
            color: $brand-secondary;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            -webkit-transition: -webkit-transform .25s;
            transition: -webkit-transform .25s;
            transition: transform .25s;
            transition: transform .25s, -webkit-transform .25s;
            position: relative;
            top: -2px;
            margin-right: 10px;
            
            //NEW
            position: absolute;
            top: -4px;
            left: -25px;
            margin-right: 0;

        }
    }
}